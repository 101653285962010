<template>
    <div>
    <div class="kt-portlet__head">
        <form class="isprint-filter" autocomplete="off">                
            <div class="form-group ">
                <label>ID</label><br/>
                <input class="form-control form-control-sm"  autocomplete="off"  placeholder="ID Movimento" type="text" style="width:180px" v-model="search.ID_MOVIMENTO.VALUE">
            </div> 
            <div class="form-group ">
                <label>Denominazione</label><br/>
                <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Denominazione" type="text" style="width:180px" v-model="search.DENOMINAZIONE.VALUE">
            </div> 
            <div class="form-group ">
                <label>Cod aff</label><br/>
                <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Codice Affiliazione" type="text" style="width:180px" v-model="search.COD_AFF.VALUE">
            </div> 
            <button class="btn btn-success float-right btn-sm mt-4" v-show="$root.utente.FK_ID_LIVELLO == 1" v-on:click="nuovoMovimento()"><i class="fal fa-plus"></i> Nuovo</button>
            <button class="btn btn-info float-right btn-sm mt-4"  v-on:click="esportaExcel()"><i class="fal fa-file-excel"></i> Esporta excel</button>
        </form>
        
    </div>
    <div class="isprint-grid-container" >    
        <table class="isprint-grid shadow" >
            <thead class="isprint-grid-thead" >
                <tr style="left: 0px;background:#1cc88a;color:#fff">
                    <th style="width:8%"><span>ID</span></th>
                    <th style="width:22%"><span>Società</span></th>
                    <th style="width:20%"><span>Note</span></th>
                    <th style="width:15%"><span >Data creazione / ult. modifica</span></th>
                    <th style="width:5%"><span >Importo</span></th>
                    <th style="width:5%"><span >Util</span></th>
                    <th style="width:5%"><span >Residuo</span></th>
                    <th style="width:20%"><span >Azioni</span></th>
                </tr>
            </thead>
            <tbody class="isprint-grid-tbody" style="font-size:14px !important">
                <tr style="left: 0px;height:50px" v-bind:key="movimento.ID_GARA"  v-for="movimento in paginatedList.arr">
                    <td  style="width:8%">
                        <kbd>{{ movimento.ID_MOVIMENTO }}</kbd>
                        <span class="badge badge-success ml-2" v-show="movimento.FK_ID_TIPO == 1">CRED</span>
                        <span class="badge badge-danger ml-2" v-show="movimento.FK_ID_TIPO == 2">DEB</span>
                    </td>
                    <td  style="width:22%">
                        <strong>{{ movimento.COD_AFF }}</strong> - {{ movimento.DENOMINAZIONE }}
                    </td>
                    <td  style="width:20%">
                        {{ movimento.NOTE }}
                    </td>
                   
                    <td  style="width:15%">
                        {{ filters.formatDateTime(movimento.DATA_CREAZIONE) }}
                    </td>
                    <td  style="width:5%;text-align:right">
                       €  {{ filters.formattaImporto(movimento.IMPORTO) }}
                    </td>
                    <td  style="width:5%;text-align:right">
                       €  {{ filters.formattaImporto(movimento.UTIL) }}
                    </td>
                    <td  style="width:5%;text-align:right">
                       €  {{ filters.formattaImporto(movimento.IMPORTO - movimento.UTIL) }}
                    </td>
                    <td  style="width:20%">
                        <button class="btn btn-warning float-right btn-sm" v-show="$root.utente.FK_ID_LIVELLO == 1" v-on:click="modificaMovimento(movimento)"><i class="fal fa-pencil"></i></button>
                        <button class="btn btn-primary float-right btn-sm" v-on:click="getUtilizziMovimento(movimento)"><i class="fal fa-list"></i></button>
                    </td>                    
                </tr>
            </tbody>
        </table>
        <Pagine v-model="search.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedList.count"  :numpagine="search.PAGINATION.LENGTH"></Pagine>
    </div>

    <div class="modal fade" id="popUpMovimento" data-backdrop="true" role="dialog"  aria-modal="true" >
        <div class="modal-dialog" style="min-width:574px;"  >
            <div class="modal-content" >
                <div class="modal-header modal-header-info" >
                    <h4 style="color:#fff" class="modal-title"><i class="fal fa-euro-sign fa-fw"></i> Gara</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span>&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row" style="width:100%">
                        <div class="form-group col-md-4" style="margin-bottom:.50rem">
                            <label>Società </label>
                            <Select2  v-model="movimentoSelezionato.FK_ID_SOCIETA" style="width:516px" :apiselected="'ge/societa/s2/soc?a=0'" :api="'ge/societa/s2?a=0'" ></Select2>
                        </div> 
                        <div class="form-group col-md-12" style="margin-bottom:.50rem">
                            <label >NOTE <i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
                            <textarea autocomplete="off" type="text" class="form-control " placeholder="Descrizione" v-model="movimentoSelezionato.NOTE">
                            </textarea>
                        </div> 
                        <div class="form-group col-md-12" style="margin-bottom:.50rem">
                            <label >Importo <i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
                            <input autocomplete="off" type="text" class="form-control " placeholder="Importo" v-model="movimentoSelezionato.IMPORTO">
                        </div> 
                        <div class="form-group col-md-12" style="margin-bottom:.50rem">
                            <label>Tipo </label>
                            <select class="form-control " v-model="movimentoSelezionato.FK_ID_TIPO">
                                <option value="1">CREDITO</option>
                                <option value="2">DEBITO</option>
                            </select>
                        </div> 
                    </div>
                </div>
                <div class="modal-footer">
                    <button data-dismiss="modal" class="btn btn-danger float-right"><i class="fal fa-times"></i> Annulla</button>
                    <button class="btn btn-success float-right" v-on:click="salvaMovimento()"><i class="fal fa-check"></i> Salva</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="popUpQuoteMovimento" data-backdrop="true" role="dialog"  aria-modal="true" >
        <div class="modal-dialog" style="min-width:1000px;"  >
            <div class="modal-content" >
                <div class="modal-header modal-header-info" >
                    <h4 style="color:#fff" class="modal-title"><i class="fal fa-euro-sign fa-fw"></i> Gara</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span>&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row" style="width:100%">
                        <ul class="list-group" style="width:100%">
                            <li class="list-group-item" v-for="quota in movimentoSelezionato.quote" v-bind:key="quota.ID_QUOTA">
                                <kbd> ORDINE {{ quota.FK_ID_ORDINE }}</kbd> <span class="badge badge-info ml-2"> € {{ quota.PREZZO_TOTALE }}</span> {{ quota.DESCRIZIONE }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="modal-footer">
                    <button data-dismiss="modal" class="btn btn-danger float-right"><i class="fal fa-times"></i> Annulla</button>
                    <button class="btn btn-success float-right" v-on:click="salvaMovimento()"><i class="fal fa-check"></i> Salva</button>
                </div>
            </div>
        </div>
    </div>

</div>
</template>
<script>


import Pagine from '@/components/utils/Pagine.vue'
import Select2 from '@/components/utils/Select2.vue'

export default {
    name:"Movimenti",
    components:{
        Pagine, 
        Select2
    },
    data:function(){
        return{
            filters:global.filters,
            search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:50},
                DENOMINAZIONE:{TYPE:'TEXT',VALUE:''}, 
                COD_AFF:{TYPE:'TEXT',VALUE:''}, 
                ID_MOVIMENTO:{TYPE:'TEXT',VALUE:''}, 
            },
            arrayMovimenti:new Array(),
            movimentoSelezionato:{},
        }
    },
    methods: {
        getStatoMovimento(movimento){
            switch (movimento.STATO){
                case 1:
                    return "Iscrizioni aperte <br/>fino al <strong>"+ this.filters.formatDate(movimento.DATA_SCADENZA_ISCRIZIONI) +"</strong>";
                case 2:
                    return "Iscrizioni chiuse";
                case 3:
                    return "Movimento concluso";
                case 5:
                    return "Coming soon";
                default:
                    return "-";
            }
        },
        openRoute : function(movimento,route){
            this.$root.movimentoSelezionato = utils.clone(movimento);
            global.router.push(route);
        },
        canAddMovimento : function(){
            if (this.$root.utente.FK_ID_LIVELLO == 1){
                return true;
            }
            return false;
        },
        nuovoMovimento : function(){
            this.movimentoSelezionato = {ID_MOVIMENTO:0};
            utils.openModal("popUpMovimento");
        },
        modificaMovimento : function(movimento){
            this.movimentoSelezionato = utils.clone(movimento);
            utils.openModal("popUpMovimento");
        },
        salvaMovimento : function(){
            const metodo = this.movimentoSelezionato.ID_MOVIMENTO > 0 ? utils.put : utils.post;
            console.log(metodo);
            metodo("ge/movimento",this.movimentoSelezionato,(response)=>{
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    utils.closeModal("popUpMovimento");
                    utils.get('/ge/movimenti',{}, (response) => {
                        this.arrayMovimenti = response.data;
                    }); 
                }
            });
        },
        getUtilizziMovimento : function(movimento){
            utils.get('/ge/movimento',{ID_MOVIMENTO:movimento.ID_MOVIMENTO}, (response) => {
                this.movimentoSelezionato = response.data;
                utils.openModal("popUpQuoteMovimento");
            }); 
        },
        esportaExcel : function(){
            window.open(utils.BASE_API + "core/report?ID_REPORT=2003&FORMATO=2&SESSION_TOKEN="+utils.SESSION_TOKEN);
        }
    },
    computed : {
        paginatedList(){
            var arr = utils.inGrid(this.arrayMovimenti,this.search);
            return arr;
        },
    },
    created : function(){
        utils.get('/ge/movimenti',{}, (response) => {
            this.arrayMovimenti = response.data;
        });        
    },
}
</script>