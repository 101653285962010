<template>
    <div>
        <ul class="nav nav-tabs" id="tabs" role="tablist" style="margin-top:25px">
            <li class="nav-item" >
                <a class="nav-link isprint-tab evento"   role="tab"  aria-selected="false" style="background: rgb(22 36 96 / 18%); font-weight: bold;  color: rgb(23, 37, 95); border: 1px solid#172460 !important;">
                    <kbd style="background-color: #17255f;">{{ evento.ID_EVENTO }}</kbd> {{ evento.DESCRIZIONE }}
                    <button class="btn btn-grid btn-dark ml-4" v-on:click="back()" ><i class="fa fa-arrow-left"></i> Indietro</button>
                </a>                
            </li> 
            <li class="nav-item">
                <a class="nav-link isprint-tab active evento"  data-toggle="tab" id="linkGenerale" data-tab="generale" href="#generale"  role="tab" aria-controls="generale" aria-selected="true">
                    <i class="fal "></i> Generale
                </a>
            </li>
            <li class="nav-item"  v-show="evento.ID_EVENTO > 0">
                <a class="nav-link isprint-tab evento" data-toggle="tab" data-tab="iscrizioni" href="#iscrizioni"  role="tab" aria-controls="iscrizioni" aria-selected="false">
                    <i class="fal "></i> Stato iscrizioni
                </a>
            </li>

            <li class="nav-item" v-show="evento.ID_EVENTO > 0" >
                <a class="nav-link isprint-tab evento" data-toggle="tab" data-tab="gareCat" href="#gareCat"  role="tab" aria-controls="gareCat" aria-selected="false">
                    <i class="fal "></i> Gare / categorie
                </a>
            </li>

            <li class="nav-item" v-show="evento.ID_EVENTO > 0 && (evento.FK_ID_SPORT == 1 || evento.FK_ID_SPORT == 2 || evento.FK_ID_SPORT == 3)">
                <a class="nav-link isprint-tab evento" data-toggle="tab" data-tab="ordine" href="#ordine"  role="tab" aria-controls="ordine" aria-selected="false">
                    <i class="fal "></i> Ordine
                </a>
            </li>

            <li class="nav-item" v-show="evento.ID_EVENTO > 0 && evento.FK_ID_SPORT == 1">
                <a class="nav-link isprint-tab evento" data-toggle="tab" data-tab="requisiti" href="#requisiti"  role="tab" aria-controls="requisiti" aria-selected="false">
                    <i class="fal "></i> Requisiti iscrizione
                </a>
            </li>

            <li class="nav-item" v-show="evento.ID_EVENTO > 0 && $root.APP == 'NUOTOSPRINT'">
                <a class="nav-link isprint-tab evento" data-toggle="tab" data-tab="tempiLimite" href="#tempiLimite"  role="tab" aria-controls="tempiLimite" aria-selected="false">
                    <i class="fal "></i> Tempi limite
                </a>
            </li>

            <li class="nav-item" v-show="evento.ID_EVENTO > 0 && $root.APP == 'NUOTOSPRINT'">
                <a class="nav-link isprint-tab evento" data-toggle="tab" data-tab="admin" href="#admin"  role="tab" aria-controls="admin" aria-selected="false">
                    <i class="fal "></i> Admin
                </a>
            </li>  
            <div class="dropdown float-right"  style="margin-top:0px">
                <button class="btn  btn-lg dropdown-toggle btn-primary" style="background: #162460;" type="button"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fal fa-link"></i> Link Rapidi
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                    <button class="dropdown-item" v-on:click="openRoute('Gara')" type="button">Gara</button>
                    <div class="dropdown-divider"></div>
                    <div class="dropdown-item">
                        <strong>Risultati</strong>
                    </div>
                    <button class="dropdown-item" v-on:click="ricalcolaRisultati()" type="button">Ricalcola risultati</button>
                    <button class="dropdown-item" v-on:click="elaboraPassaggi()" type="button">Elabora passaggi</button>
                    <button class="dropdown-item" v-on:click="stampaPassaggi()" type="button">Stampa passaggi</button>
                    <button class="dropdown-item" v-on:click="stampaRipescaggi()" type="button">Stampa ripescaggi</button>
                    <div class="dropdown-divider"></div>
                    <div class="dropdown-item">
                        <strong>Iscrizioni</strong>
                    </div>
                    <button class="dropdown-item" v-on:click="openRoute('Iscrizioni')" type="button">Modifica iscrizioni</button>
                    <button class="dropdown-item" v-on:click="elencoIscritti()" type="button">Elenco iscritti</button>
                    <button class="dropdown-item" v-on:click="riepilogoIscrizioni()" type="button">Riepilogo società</button>                    
                    <div class="dropdown-divider"></div>
                    <button class="dropdown-item" style="font-weight:bold" v-on:click="duplicaEvento()" type="button">Duplica evento</button>
                </div>
            </div>  

        </ul>
        <div class="tab-content card-tab" style="margin-top:-20px" >
            <div class="tab-pane active" id="generale" style="min-height:600px;height:100%" role="tabpanel">
                <div class="card shadow" style="margin-top:20px;min-height:600px;height:100%;padding-top: 10px;padding-left:30px">
                    <div class="row w-100">
                        <div class="col-7">
                            <div class="font-weight-bold text-ecomm h3 mb-2" style="color:#017cb7;border-bottom: 2px solid;margin-bottom: 20px !important;margin-top: 10px;"><i class="fal fa-info-circle"></i> Info di base</div>
                            <div class="row w-100">
                                <div class="form-group col-4">
                                    <label>Descrizione</label>
                                    <input type="text" class="form-control" v-model="evento.DESCRIZIONE">
                                </div>
                                <div class="form-group col-3">
                                    <label>Luogo</label>
                                    <input type="text" class="form-control" v-model="evento.LUOGO">
                                </div>
                                <div class="form-group col-3">
                                    <label>Campionato</label>
                                    <select class="form-control " v-model="evento.FK_ID_CAMPIONATO">
                                        <option v-bind:value="0">Nessun campionato</option>
                                        <option v-for="campionato in arrayCampionati" v-bind:key="campionato.ID_CAMPIONATO" v-bind:value="campionato.ID_CAMPIONATO">{{campionato.DESCRIZIONE}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-2">
                                    <label>Regione</label>
                                    <select class="form-control " v-model="evento.FK_ID_REGIONE">
                                        <option v-for="regione in $root.arrayRegioni" v-bind:key="regione.ID_REGIONE" v-bind:value="regione.ID_REGIONE">{{regione.NOME}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-4">
                                    <label>Data inizio</label>
                                    <DatePicker type="text" class="form-control" v-model="evento.DATA_INIZIO"></DatePicker>
                                </div>
                                <div class="form-group col-4">
                                    <label>Data fine</label>
                                    <DatePicker type="text" class="form-control" v-model="evento.DATA_FINE"></DatePicker>
                                </div>
                                <div class="form-group col-4">
                                    <label>Data chiusura iscrizioni</label>
                                    <DatePicker type="text" class="form-control" v-model="evento.DATA_SCADENZA_ISCRIZIONI"></DatePicker>
                                </div>
                                <div class="form-group col-2" v-show="$root.APP != 'CONFSPORT'">
                                    <label>Tipo evento</label>
                                    <select class="form-control" v-model="evento.FLG_GARA_SOCIALE">
                                        <option v-bind:value="0">Trofeo</option>
                                        <option v-bind:value="1">Gara sociale</option>
                                    </select>
                                </div>
                                <div class="form-group col-4">
                                    <label>Metodo calcolo importi</label>
                                    <select class="form-control" v-model="evento.FK_ID_METODO_CALCOLO_IMPORTI">
                                        <option v-bind:value="1">1 - STANDARD</option>
                                        <option v-bind:value="2">2 - PRESENZA IND + PRESENZA SQU</option>
                                        <option v-bind:value="3">3 - QUOTA FISSA ATLETA + IMPORTI GARE PERSONALIZZATI</option>
                                    </select>
                                </div>
                                <div class="form-group col-3">
                                    <label>Stato evento</label>
                                    <select class="form-control" v-model="evento.STATO">
                                        <option v-bind:value="1">Iscrizioni aperte</option>
                                        <option v-bind:value="2">Iscrizioni chiuse</option>
                                        <option v-bind:value="3">Evento concluso</option>
                                        <option v-bind:value="4">Cooming soon</option>
                                    </select>
                                </div>
                                <div class="form-group col-3">
                                    <label>Metodo calcolo risultati</label>
                                    <select class="form-control" v-model="evento.FK_ID_METODO_CALCOLO_PUNTEGGIO">
                                       
                                    </select>
                                </div>
                               
                                <div class="form-group col-md-2" style="margin-bottom:.50rem">
                                    <label>Sport </label>
                                    <select class="form-control " disabled v-model="evento.FK_ID_SPORT">
                                        <option v-for="sport in $root.arraySport" v-bind:key="sport.ID_SPORT" v-bind:value="sport.ID_SPORT">{{sport.DESCRIZIONE}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-12">
                                    <label>Note</label>
                                    <textarea class="form-control" style="min-height: 160px;" v-model="evento.NOTE">
                                        
                                    </textarea>
                                </div>
                                <div class="col-12 mt-2">
                                    <button class="btn btn-success btn-lg btn-block" v-on:click="salvaEvento()"><i class="fal fa-check"></i> Salva evento</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-5">
                            <div class="font-weight-bold text-ecomm h3 mb-2" style="color:#017cb7;border-bottom: 2px solid;margin-bottom: 20px !important;margin-top: 10px;"><i class="fal fa-cogs"></i> Opzioni</div>
                            <div id="accordion" style="margin-top:30px">
                                <div class="card" >
                                    <template v-for="gruppo in arrayOpzioni" v-bind:key="gruppo.FK_ID_PADRE">
                                        <div  class="card-header" :id="'heading__' + gruppo.FK_ID_PADRE" style="background:#017cb7;" v-show="(gruppo.FK_ID_PADRE != 2 && gruppo.FK_ID_PADRE != 3) || ((gruppo.FK_ID_PADRE == 2 || gruppo.FK_ID_PADRE == 3) && (evento.FK_ID_SPORT == 1))">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link" style="color:#fff" data-toggle="collapse" :data-target="'#collapse__'+gruppo.FK_ID_PADRE" aria-expanded="true" :aria-controls="'collapse__'+gruppo.FK_ID_PADRE">
                                                    <span style="font-weight:normal;font-size:22px"><div style="width:40px;float:left"><i class="fal " v-bind:class="gruppo.icona"></i></div> <div style="float:left">{{gruppo.titolo}}</div></span>
                                                </button>
                                                <button class="btn btn-success btn-sm float-right" v-if="gruppo.FK_ID_PADRE == 100" v-on:click="nuovaOpzione()" style="margin-top:4px"><i class="fal fa-check"></i> Nuova opzione</button>
                                            </h5>
                                        </div>
                                        <div :id="'collapse__'+ gruppo.FK_ID_PADRE" class="collapse" :aria-labelledby="'heading__'+ gruppo.FK_ID_PADRE" data-parent="#accordion">
                                            <div class="card-body">
                                                <ul class="list-group">
                                                    <li class="list-group-item" style="padding:3px" v-for="opzione in gruppo.OPZIONI" v-bind:key="opzione.FK_ID_OPZIONE">
                                                        <div class="col-md-8" style="padding-top: 6px;font-size: 18px;">
                                                            <span>{{opzione.DESCRIZIONE}}</span>
                                                            <br/><small><small>{{opzione.SMALL}}</small></small>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <IControl :campo="opzione" v-model="opzione.VALORE"></IControl>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                                <button class="btn btn-success btn-lg btn-block mt-4" v-on:click="salvaOpzioni()"><i class="fal fa-check"></i> Salva opzioni</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="tab-pane" id="iscrizioni" style="min-height:600px;height:100%" role="tabpanel">
                <div class="card shadow" style="margin-top:20px;min-height:600px;height:100%">             
                    <div class="isprint-grid-container" >    
                        <table class="isprint-grid shadow" >
                            <thead class="isprint-grid-thead" >
                            <!--<isort v-bind:ordinamento.sync="ordinamento" :label="'Cognome e nome'" :campo="'COGNOME'" ></isort>-->
                                <tr style="left: 0px;background:#f4b30d;color:#fff">
                                    <th style="width:30%"><span>Società</span></th>
                                    <th style="width:10%"><span >Tot atleti / Pres ind</span></th>
                                    <th style="width:10%"><span >Tot squadre</span></th>
                                    <th style="width:10%"><span >Pagato</span></th>
                                    <th style="width:10%"><span >Stornato da crediti</span></th>
                                    <th style="width:10%"><span > Totale</span></th>
                                    <th style="width:20%"><span >Azioni</span></th>
                                </tr>
                            </thead>
                            <tbody class="isprint-grid-tbody" style="font-size:14px !important">
                                <tr style="left: 0px;height:50px" v-bind:key="societa.FK_ID_GARA"  v-for="societa in paginatedListRiepilogo.arr">
                
                                    <td  style="width:30%">                                       
                                        <strong>{{ societa.DENOMINAZIONE }}</strong>
                                    </td>
                                    <td  style="width:10%">
                                       {{ societa.TOT_ATLETI }} / {{ societa.TOT_IND }}
                                    </td>
                                    <td  style="width:10%">
                                        {{ societa.TOT_SQU }}
                                    </td>
                                    <td  style="width:10%;text-align:right">
                                        € {{ filters.formattaImporto(societa.IMPORTO_PAGATO )}} 
                                    </td>
                                    <td  style="width:10%;text-align:right">
                                        € {{ filters.formattaImporto(societa.IMPORTO_STORNATO_DA_CREDITI )}}
                                    </td>
                                    <td  style="width:10%;text-align:right">
                                       € {{ filters.formattaImporto(societa.IMPORTO_TOTALE )}} 
                                    </td>
                                    <td  style="width:20%;text-align: right;">
                                       <button class="btn btn-grid btn-primary" v-on:click="prorogaIscrizioni(societa,1)" v-show="societa.FLG_PROROGA == 0">Attiva proroga</button>
                                       <button class="btn btn-grid btn-danger" v-on:click="prorogaIscrizioni(societa,0)" v-show="societa.FLG_PROROGA == 1">Disattiva proroga</button>
                                    </td>                    
                                </tr>
                            </tbody>
                        </table>
                        <div class="row">
                            <div class="col-4">
                                <Pagine v-model="searchRiepilogo.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedListRiepilogo.count"  :numpagine="searchRiepilogo.PAGINATION.LENGTH"></Pagine>
                            </div>
                            <div class="col-8" style="margin-top:20px" >
                                <ul class="pagination float-right">
                                    <li class="kt-pagination__link--last page-item"><span tabindex="0" class="page-link" style="font-weight: bold;background:#162460;color:#fff"><i class="fal fa-user"></i> {{getTotali('TOT_ATLETI')}}</span></li>
                                    <li class="kt-pagination__link--last page-item"><span tabindex="0" class="page-link" style="font-weight: bold;background:#162460;color:#fff"><i class="fal fa-check-square"></i> {{getTotali('TOT_IND')}}</span></li>
                                    <li class="kt-pagination__link--last page-item"><span tabindex="0" class="page-link" style="font-weight: bold;background:#162460;color:#fff"><i class="fal fa-users"></i> {{getTotali('TOT_SQU')}}</span></li>
                                    <li class="kt-pagination__link--last page-item"><span tabindex="0" class="page-link" style="font-weight: bold;background:#162460;color:#fff">DOVUTO: € {{getTotali('IMPORTO_TOTALE')}}</span></li>
                                    <li class="kt-pagination__link--last page-item"><span tabindex="0" class="page-link" style="font-weight: bold;background:#1cc88a;color:#fff">INCASSATO: € {{getTotali('IMPORTO_PAGATO')}}</span></li>
                                    
                                </ul>  
                            </div>                
                        </div>                        
                    </div>
                </div>
            </div>
            <div class="tab-pane" id="gareCat" style="min-height:600px;height:100%" role="tabpanel">
                <div class="card shadow" style="margin-top:20px;min-height:600px;height:100%">
                    <div class="row">
                        <div class="col-8" style="padding: 15px;padding-left: 30px;">
                            <div class="row">
                                <div class="form-group mr-1 ml-3">
                                    <label>Tipologia</label>
                                    <select class="form-control  form-control-sm" v-model="indiceAllSelezionato">
                                        <option v-for="opzione in arrayAllCategorieEvento" v-bind:value="opzione.INDICE" v-bind:key="opzione.INDICE">
                                            {{ opzione.DESCRIZIONE }}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group mr-1">
                                    <label>Descrizione</label>
                                    <input type="text" class="form-control form-control-sm" v-model="searchCategorie.DESCRIZIONE.VALUE">
                                </div>
                                <div class="form-group mr-1">
                                    <label>ID_CAT</label>
                                    <input type="text" class="form-control form-control-sm" v-model="searchCategorie.ID_CATEGORIA.VALUE">
                                </div>
                                <div class="form-group mr-1">
                                    <label>ID_CAT_ASS</label>
                                    <input type="text" class="form-control form-control-sm" v-model="searchCategorie.FK_ID_CATEGORIA_ASS.VALUE">
                                </div>
                            </div>
                            
                            <ul class="list-group">
                                <li v-on:click="setCurrentCategoria($event,categoria)" v-bind:class="{'list-group-item-success':categoria.SELEZIONATO == 1 && categoria.CURRENT != 1,'list-group-item-primary':categoria.CURRENT == 1}" class="list-group-item" style="padding:4px;padding-left:10px" v-for="categoria in paginatedListCategorie.arr" v-bind:key="categoria.ID_CATEGORIA">
                                    <div class="col-8">
                                        <span class="badge bg-maschio mr-1">{{filters.formatDateYear(categoria.DATA_NASCITA_A_M)}} - {{filters.formatDateYear(categoria.DATA_NASCITA_DA_M)}}</span>
                                        <span class="badge bg-femmina">{{filters.formatDateYear(categoria.DATA_NASCITA_A_F)}} - {{filters.formatDateYear(categoria.DATA_NASCITA_DA_F)}}</span>
                                        <strong class="ml-2">{{categoria.DESCRIZIONE}}</strong> <small> {{ categoria.LABEL }}</small>
                                    </div>
                                    <!-- 
                                    <div class="col-2">
                                        <span>PRESENZE: 1</span>
                                    </div>
                                    -->
                                    <div class="col-4">
                                        <button v-on:click="aggiungiCategoria(categoria)" class="float-right btn btn-grid btn-success ml-1" v-show="categoria.SELEZIONATO == 0 && categoria.CURRENT == 1"><i class="fal fa-plus"></i> Aggiungi</button>
                                        <button v-on:click="rimuoviCategoria(categoria)" class="float-right btn btn-grid btn-danger ml-1" v-show="categoria.SELEZIONATO == 1 && categoria.CURRENT == 1"><i class="fal fa-times"></i> Rimuovi</button>
                                        <button v-on:click="opzioniCategoria()" class="float-right btn btn-grid btn-primary" v-show="categoria.SELEZIONATO == 1 && categoria.CURRENT == 1"><i class="fal fa-cogs"></i> Opzioni</button>
                                    </div>
                                    
<!-- 
                                    <span class="badge badge-info mr-1">{{ categoria.FK_ID_CATEGORIA_ASS }}</span>
                                    <span class="badge badge-primary mr-1">{{ categoria.ID_CATEGORIA }}</span>
     -->                             
                                </li>
                            </ul>
                            <Pagine v-model="searchCategorie.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedListCategorie.count"  :numpagine="searchCategorie.PAGINATION.LENGTH"></Pagine>

                        </div>
                        <div class="col-4" style="padding: 15px;padding-right: 30px;">
                            <div class="form-group mr-1">
                                <label>Descrizione</label>
                                <input type="text" class="form-control form-control-sm" v-model="searchGare.DESCRIZIONE.VALUE">
                            </div>
                            <div class="row">
                                <div class="col-6" v-on:click="selezionaGara($event,gara)"  v-for="gara in paginatedListGare.arr" v-bind:key="gara.FK_ID_GARA" style="margin-bottom:10px">
                                    <div class="card  shadow " v-bind:style="getStyleGara(gara)" style="height:32px;">
                                        <div class="card-body h-100" style="padding:.15rem;padding-left:.2rem;padding-right:.2rem;font-weight:bold">
                                            <div class="justify-content-center align-self-center" style="font-size:12px;padding-left: 6px ;">
                                                <div class="float-left mt-1">
                                                    {{gara.DESCRIZIONE}}

                                                </div>
                                                <div class="float-right">
                                                    <button v-on:click="opzioniGara(gara)" class="btn btn-grid btn-primary" style="padding: 0.04rem 0.15rem;" v-show="gara.SELEZIONATO == 1"><i class="fal fa-cogs"></i></button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>  
                                <div class="col-12">
                                    <Pagine v-model="searchGare.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedListGare.count"  :numpagine="searchGare.PAGINATION.LENGTH"></Pagine>  
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane" id="ordine" style="min-height:600px;height:100%" role="tabpanel">
                <div class="card shadow" style="margin-top:20px;min-height:600px;height:100%;padding:20px">               
                    <div class="kt-portlet__head mb-2">
                        <div class="isprint-filter" autocomplete="off">
                            <!--                 
                            <div class="form-group">
                                <label>Gara</label><br/>
                                <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Gara" type="text" style="width:160px" v-model="searchGareAll.DESCRIZIONE.VALUE">
                            </div>
                            <div class="form-group">
                                <label>Categoria</label><br/>
                                <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Categoria" type="text" style="width:160px" v-model="searchGareAll.DESC_CATEGORIA.VALUE">
                            </div>
                            <div class="form-group">
                                <label>Tipo</label><br/>
                                <select class="form-control form-control-sm" v-model="searchGareAll.FK_ID_TIPO.VALUE">
                                    <option value="">TUTTI</option>
                                    <option value="1">INDIVIDUALE</option>
                                    <option value="2">STAFFETTA</option>
                                </select>
                            </div>   -->
                            <button class="btn  float-left  btn-primary btn-lg mr-2 " v-on:click="generaOrdine()" style="margin-top:26px"><i class="fal fa-sync"></i> 1. Genera ordine</button>
                            <button class="btn  float-left btn-info btn-lg mr-2" v-on:click="salvaOrdine()" style="margin-top:26px"><i class="fal fa-check"></i> 2. Salva Ordine</button>
                            <button class="btn  float-left btn-success btn-lg  mr-2" v-on:click="generaStartlist()" style="margin-top:26px"><i class="fal fa-play"></i> 3. Genera Startlist</button>
                            
                            <div class="dropdown float-left ml-4"  style="margin-top:26px">
                                <button class="btn  btn-lg dropdown-toggle btn-danger"  type="button"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fal fa-print"></i> Stampe
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                    <div class="dropdown-item"><strong>Startlist</strong></div>
                                    <button class="dropdown-item" v-on:click="stampaStartlist(1)" type="button">1. Startlist PDF</button>
                                    <div class="dropdown-item"><strong>Cartellini</strong></div>
                                    <button class="dropdown-item" v-on:click="stampaCartellini(1)" type="button">1. Ordine di gara</button>
                                    <button class="dropdown-item" v-on:click="stampaCartellini(2)" type="button">2. FEMMINE - MASCHI</button>
                                    <button class="dropdown-item" v-on:click="stampaCartellini(3)" type="button">3. Vuoti</button>
                                </div>
                            </div> 

                        </div>
                    </div>
                    <div class="isprint-grid-container mt-4" style="border-top:1px solid #132361;padding-top:20px" >    
                
                        <h3 v-show="evento.gareOrdinate.length == 0" style="width:100%">Non è stato ancora generato l'ordine di gara - fare click sul pulsante '1. Genera ordine'</h3>

                        <draggable tag="div" :list="evento.gareOrdinate"  class="row w-100" >
                            <div class="mb-2" style="float:left;padding-right: 0.55rem;width:12.5%;    padding-left: 0.55rem;" v-bind:key="gara.ID_ID_GARA"  v-for="gara in paginatedListOrdine.arr" >
                                <div class="card  shadow " >
                                    <div class="card-body h-100 text-center" style="padding:.15rem;padding-left:.2rem;padding-right:.2rem;font-weight:bold;border-radius:6px" v-bind:class="{'bg-maschio':gara.SESSO == 1,'bg-femmina':gara.SESSO == 2}">
                                        <!--<div style="width:100%;text-align:center;margin-top:2px">
                                            {{ gara.ORDINE }}
                                        </div>-->
                                        <strong style="font-size:14px">{{ gara.DESCRIZIONE }}</strong> 
                                        <br/>
                                        <span style="font-size:12px">{{ gara.DESC_CATEGORIA }}</span>
                                    </div>
                                </div>                              
                            </div>                        
                        </draggable>
                  </div>
                </div>
            </div>
            <div class="tab-pane" id="requisiti" style="min-height:600px;height:100%" role="tabpanel">
                <div class="card shadow" style="margin-top:20px;min-height:600px;height:100%">
                    <div class="row w-100">
                        <div class="col-9">
                            
                        </div>
                        <div class="col-3">
                            <div class="card shadow" style="margin-top:20px;min-height:600px;height:100%">
                                <div class="card-header">
                                    <h3>Eventi precedenti</h3>
                                </div>
                                <div class="card-body">
                                    <ul class="list-group">
                                        <li v-bind:class="{'list-group-item-primary':e.SELEZIONATO == 1}" v-on:click="gestioneCollegamentoEvento(e)" class="list-group-item" v-for="e in arrayEventiPrec" v-bind:key="e.ID_EVENTO">{{ e.DESCRIZIONE }}</li>
                                    </ul>
                                    <button class="btn btn-success" v-on:click="elabotaRisultatiPiuEventi()">Elabora passaggi</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane" id="tempiLimite" style="min-height:600px;height:100%" role="tabpanel">
                <div class="card shadow" style="margin-top:20px;min-height:600px;height:100%">
                    tempiLimite
                </div>
            </div>
            <div class="tab-pane" id="admin" style="min-height:600px;height:100%" role="tabpanel">
                <div class="card shadow" style="margin-top:20px;min-height:600px;height:100%">
                    admin
                </div>
            </div>
        </div>
    </div>

    

    <div class="modal fade" id="popUpOpzioniGara" data-backdrop="true" role="dialog"  aria-modal="true" >
        <div class="modal-dialog" style="min-width:800px;"  >
            <div class="modal-content" >
                <div class="modal-header modal-header-info" >
                    <h4 style="color:#fff" class="modal-title">{{ categoriaSelezionata.DESCRIZIONE }} - {{ garaSelezionata.DESCRIZIONE }}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span>&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row" style="width:100%">
                        <div class="form-group col-md-4" style="margin-bottom:.50rem">
                            <label >A Punti</label>
                            <select autocomplete="off"  class="form-control " v-model="garaSelezionata.FLG_PUNTI">
                                <option v-bind:value="0">NO</option>
                                <option v-bind:value="1">SI</option>
                            </select>
                        </div> 
                    </div>
                    <div class="row w-100" >

                        <div id="accordionGare" style="margin-top:30px;width:100%">
                            <div class="card" >
                                <template v-for="gruppo in garaSelezionata.arrayOpzioni" v-bind:key="gruppo.FK_ID_PADRE">
                                    <div  class="card-header" :id="'opzioni_heading__gare__' + gruppo.FK_ID_PADRE" style="background:#017cb7;" v-if="( gruppo.FK_ID_PADRE == 5)">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link" style="color:#fff" data-toggle="collapse" :data-target="'#opzioni_collapse__gare__'+gruppo.FK_ID_PADRE" aria-expanded="true" :aria-controls="'opzioni_collapse__gare__'+gruppo.FK_ID_PADRE">
                                                <span style="font-weight:normal;font-size:22px"><div style="width:40px;float:left"><i class="fal " v-bind:class="gruppo.icona"></i></div> <div style="float:left">{{gruppo.titolo}}</div></span>
                                            </button>
                                            <button class="btn btn-success btn-sm float-right" v-if="gruppo.FK_ID_PADRE == 100" v-on:click="nuovaOpzione()" style="margin-top:4px"><i class="fal fa-check"></i> Nuova opzione</button>
                                        </h5>
                                    </div>
                                    <div v-if="(gruppo.FK_ID_PADRE == 5)" :id="'opzioni_collapse__gare__'+ gruppo.FK_ID_PADRE" class="opzioni_collapse show" :aria-labelledby="'opzioni_heading__gare__'+ gruppo.FK_ID_PADRE" data-parent="#accordionGare">
                                        <div class="card-body">
                                            <ul class="list-group">
                                                <template  v-for="opzione in gruppo.OPZIONI" v-bind:key="opzione.FK_ID_OPZIONE" >
                                                    <li class="list-group-item" style="padding:3px" v-if="opzione.FK_ID_OPZIONE == 18 || opzione.FK_ID_OPZIONE == 20">
                                                        <div class="col-md-8" style="padding-top: 6px;font-size: 18px;">
                                                            <span>{{opzione.DESCRIZIONE}}</span>
                                                            <br/><small><small>{{opzione.SMALL}}</small></small>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <IControl :campo="opzione" v-model="opzione.VALORE"></IControl>
                                                        </div>
                                                    </li>
                                                </template>
                                            </ul>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button data-dismiss="modal" class="btn btn-danger float-right"><i class="fal fa-times"></i> Annulla</button>
                    <button class="btn btn-success float-right" v-on:click="salvaOpzioniGara()"><i class="fal fa-check"></i> Salva</button>
                </div>
            </div>
        </div>
    </div>


    
    <div class="modal fade" id="popUpGaraEvento" data-backdrop="true" role="dialog"  aria-modal="true" >
        <div class="modal-dialog" style="min-width:300px;"  >
            <div class="modal-content" >
                <div class="modal-header modal-header-info" >
                    <h4 style="color:#fff" class="modal-title">{{ garaEventoSelezionata.DESCRIZIONE }} - {{ garaEventoSelezionata.DESC_CATEGORIA }}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span>&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group " style="margin-bottom:.50rem">
                        <label>Note</label>
                        <textarea class="form-control" v-model="garaEventoSelezionata.NOTE"></textarea>
                    </div> 
                </div>
                <div class="modal-footer">
                    <button data-dismiss="modal" class="btn btn-danger float-right"><i class="fal fa-times"></i> Annulla</button>
                    <button class="btn btn-success float-right" v-on:click="salvaNote()"><i class="fal fa-check"></i> Salva</button>
                </div>
            </div>
        </div>
    </div>


    <div class="modal fade" id="popUpDuplicaEvento" data-backdrop="true" role="dialog"  aria-modal="true" >
        <div class="modal-dialog" style="min-width:300px;"  >
            <div class="modal-content" >
                <div class="modal-header modal-header-info" >
                    <h4 style="color:#fff" class="modal-title">Duplica evento</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span>&times;</span>
                    </button>
                </div>
                <div class="modal-body">                    
                    <div class="form-group ">
                        <label>DESCRIZIONE</label>
                        <input type="text" class="form-control" v-model="paramsEventoDaDuplicare.DESCRIZIONE">
                    </div>
                    <div class="form-group ">
                        <label>Luogo</label>
                        <input type="text" class="form-control" v-model="paramsEventoDaDuplicare.LUOGO">
                    </div>
                    <div class="form-group ">
                        <label>Data inizio</label>
                        <DatePicker type="text" class="form-control" v-model="paramsEventoDaDuplicare.DATA_INIZIO"></DatePicker>
                    </div>
                    <div class="form-group ">
                        <label>Data fine</label>
                        <DatePicker type="text" class="form-control" v-model="paramsEventoDaDuplicare.DATA_FINE"></DatePicker>
                    </div>
                    <div class="form-group ">
                        <label>Data chiusura iscrizioni</label>
                        <DatePicker type="text" class="form-control" v-model="paramsEventoDaDuplicare.DATA_SCADENZA_ISCRIZIONI"></DatePicker>
                    </div>
                </div>
                <div class="modal-footer">
                    <button data-dismiss="modal" class="btn btn-danger float-right"><i class="fal fa-times"></i> Annulla</button>
                    <button class="btn btn-success float-right" v-on:click="doDuplicaEvento()"><i class="fal fa-check"></i> Salva</button>
                </div>
            </div>
        </div>
    </div>

    

    <div class="modal fade" id="popUpOpzioniCategoria" data-backdrop="true" role="dialog"  aria-modal="true" >
        <div class="modal-dialog" style="min-width:800px;"  >
            <div class="modal-content" >
                <div class="modal-header modal-header-info" >
                    <h4 style="color:#fff" class="modal-title">Categoria {{ categoriaSelezionata.DESCRIZIONE }}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span>&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row" style="width:100%">
                        <div class="form-group col-md-4" style="margin-bottom:.50rem">
                            <label >Numero gare abilitate</label>
                            <input autocomplete="off" type="text" class="form-control " v-model="categoriaSelezionata.NUMERO_GARE_ABILITATE">
                        </div>
                        <div class="form-group col-md-4" style="margin-bottom:.50rem">
                            <label >Modalità calcolo classifica</label>
                            <select autocomplete="off"  class="form-control " v-model="categoriaSelezionata.FLG_ANNO">
                                <option v-bind:value="0">CATEGORIA</option>
                                <option v-bind:value="1">ANNO DI NASCITA</option>
                            </select>
                        </div> 
                        <div class="form-group col-md-4" style="margin-bottom:.50rem">
                            <label >Modalità calcolo classifica</label>
                            <select autocomplete="off"  class="form-control " v-model="categoriaSelezionata.FK_ID_TIPO_TESSERAMENTO">
                                <option v-bind:value="1">PROPAGANDA</option>
                                <option v-bind:value="2">AGONISTI</option>
                                <option v-bind:value="3">MASTER</option>
                            </select>
                        </div> 
                        <hr/>

                        <div id="accordionOpzioni" style="margin-top:30px;margin-left:8px;width:100%">
                            <div class="card" >
                                <template v-for="gruppo in categoriaSelezionata.arrayOpzioni" v-bind:key="gruppo.FK_ID_PADRE">
                                    <div  class="card-header" :id="'opzioni_heading__' + gruppo.FK_ID_PADRE" style="background:#017cb7;" v-if="(gruppo.FK_ID_PADRE == 1 || gruppo.FK_ID_PADRE == 5)">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link" style="color:#fff" data-toggle="collapse" :data-target="'#opzioni_collapse__'+gruppo.FK_ID_PADRE" aria-expanded="true" :aria-controls="'opzioni_collapse__'+gruppo.FK_ID_PADRE">
                                                <span style="font-weight:normal;font-size:22px"><div style="width:40px;float:left"><i class="fal " v-bind:class="gruppo.icona"></i></div> <div style="float:left">{{gruppo.titolo}}</div></span>
                                            </button>
                                            <button class="btn btn-success btn-sm float-right" v-if="gruppo.FK_ID_PADRE == 100" v-on:click="nuovaOpzione()" style="margin-top:4px"><i class="fal fa-check"></i> Nuova opzione</button>
                                        </h5>
                                    </div>
                                    <div v-if="(gruppo.FK_ID_PADRE == 1 || gruppo.FK_ID_PADRE == 5)" :id="'opzioni_collapse__'+ gruppo.FK_ID_PADRE" class="opzioni_collapse show" :aria-labelledby="'opzioni_heading__'+ gruppo.FK_ID_PADRE" data-parent="#accordionOpzioni">
                                        <div class="card-body">
                                            <ul class="list-group">
                                                <li class="list-group-item" style="padding:3px" v-for="opzione in gruppo.OPZIONI" v-bind:key="opzione.FK_ID_OPZIONE">
                                                    <div class="col-md-8" style="padding-top: 6px;font-size: 18px;">
                                                        <span>{{opzione.DESCRIZIONE}}</span>
                                                        <br/><small><small>{{opzione.SMALL}}</small></small>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <IControl :campo="opzione" v-model="opzione.VALORE"></IControl>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button data-dismiss="modal" class="btn btn-danger float-right"><i class="fal fa-times"></i> Annulla</button>
                    <button class="btn btn-success float-right" v-on:click="salvaOpzioniCategoria()"><i class="fal fa-check"></i> Salva</button>
                </div>
            </div>
        </div>
    </div>


</template>
<script>


import Pagine from '@/components/utils/Pagine.vue'
import DatePicker from '@/components/utils/DatePicker.vue'
import IControl from '@/components/utils/IControl.vue'
import { VueDraggableNext } from 'vue-draggable-next'  



export default {
    name:"Evento",
    components:{
        DatePicker,
        IControl,
        Pagine, 
        draggable: VueDraggableNext,
    },
    data:function(){
        return{
            filters:global.filters,
            evento:{gareAll:new Array(),gareOrdinate:new Array()},
            arrayRiepilogo : new Array(),
            arrayCategorie : new Array(),
            arrayGare : new Array(),
            categoriaSelezionata : {arrayOpzioni:new Array()},
            garaSelezionata:{},
            arrayAllCategorieEvento : new Array(
                {FK_ID_TIPO:1,FLG_DIS:0,ARRAY_CATEGORIE:new Array(),INDICE:1,DESCRIZIONE:"INDIVIDUALI NORMODODATI"},
                {FK_ID_TIPO:1,FLG_DIS:1,ARRAY_CATEGORIE:new Array(),INDICE:2,DESCRIZIONE:"INDIVIDUALI CON DISABILITÀ"},
                {FK_ID_TIPO:2,FLG_DIS:0,ARRAY_CATEGORIE:new Array(),INDICE:3,DESCRIZIONE:"SQUADRE NORMODOTATI"},
                {FK_ID_TIPO:2,FLG_DIS:1,ARRAY_CATEGORIE:new Array(),INDICE:4,DESCRIZIONE:"SQUADRE CON DISABILITÀ"},
            ),
            indiceAllSelezionato:1,
            searchRiepilogo:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:50},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''}, 
                DENOMINAZIONE:{TYPE:'TEXT',VALUE:''}, 
            },
            searchCategorie:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:10},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''}, 
                ID_CATEGORIA:{TYPE:'TEXT',VALUE:''}, 
                FK_ID_CATEGORIA_ASS:{TYPE:'TEXT',VALUE:''}, 
            },
            searchGare:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:20},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''}, 
            },
            searchGareAll :{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:1000},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''}, 
                DESC_CATEGORIA:{TYPE:'TEXT',VALUE:''}, 
                FK_ID_TIPO:{TYPE:'COMBO',VALUE:''}, 
            },  
            arrayEventiPrec : new Array(),
            ordineHasChanged:false,          
            arrayGareCategoria:new Array(),
            arrayCampionati:new Array(),
            garaEventoSelezionata : {},
            paramsEventoDaDuplicare:{},
            arrayOpzioni : new Array(
                    {FK_ID_PADRE:1,FLG_OPZIONE:false,titolo:"Opzioni iscrizione",OPZIONI:new Array(
                                                        {FK_ID_OPZIONE:1,FK_ID_PADRE:1,FLG_OPZIONE:true,DESCRIZIONE:"Numero massimo gare atleta",VALORE:2,TYPE:"TEXT",OPZIONI:new Array()},             
                                                        {FK_ID_OPZIONE:38,FK_ID_PADRE:1,FLG_OPZIONE:true,DESCRIZIONE:"Numero massimo staffette atleta",VALORE:1,TYPE:"TEXT",OPZIONI:new Array()},             
                                                        {FK_ID_OPZIONE:8,FK_ID_PADRE:1,FLG_OPZIONE:true,DESCRIZIONE:"Tempo iscrizione obbligatorio",VALORE:0,TYPE:"SELECT",OPZIONI:new Array({DESCRIZIONE:"NO",ID:0},{DESCRIZIONE:"SI",ID:1})},
                                                        {FK_ID_OPZIONE:34,FK_ID_PADRE:1,FLG_OPZIONE:true,DESCRIZIONE:"Tempo iscrizione bloccato",VALORE:0,TYPE:"SELECT",OPZIONI:new Array({DESCRIZIONE:"NO",ID:0},{DESCRIZIONE:"SI",ID:1})},
                                                        {FK_ID_OPZIONE:23,FK_ID_PADRE:1,FLG_OPZIONE:true,DESCRIZIONE:"Abilita componenti staffetta",VALORE:0,TYPE:"SELECT",OPZIONI:new Array({DESCRIZIONE:"NO",ID:0},{DESCRIZIONE:"SI",ID:1})},
                                                        {FK_ID_OPZIONE:35,FK_ID_PADRE:1,FLG_OPZIONE:true,DESCRIZIONE:"Categoria staff determinata in base a componente più vecchio",TYPE:"TEXT",VALORE:0,OPZIONI:new Array({DESCRIZIONE:"NO",ID:0},{DESCRIZIONE:"SI",ID:1})},
                                                        {FK_ID_OPZIONE:29,FK_ID_PADRE:1,FLG_OPZIONE:true,DESCRIZIONE:"Max presenze gara / soc",VALORE:1000,TYPE:"TEXT",OPZIONI:new Array()},
                                                        {FK_ID_OPZIONE:27,FK_ID_PADRE:1,FLG_OPZIONE:true,DESCRIZIONE:"Max staffette iscrivibili cat",VALORE:10,TYPE:"TEXT",OPZIONI:new Array()},
                                                        {FK_ID_OPZIONE:39,FK_ID_PADRE:1,FLG_OPZIONE:true,DESCRIZIONE:"Max staffette iscrivibili società",VALORE:1000,TYPE:"TEXT",OPZIONI:new Array()},
                                                        {FK_ID_OPZIONE:36,FK_ID_PADRE:1,FLG_OPZIONE:true,DESCRIZIONE:"Note obbligatorie",VALORE:0,TYPE:"SELECT",OPZIONI:new Array({DESCRIZIONE:"NO",ID:0},{DESCRIZIONE:"SI",ID:1})},
                                                        {FK_ID_OPZIONE:37,FK_ID_PADRE:1,FLG_OPZIONE:true,DESCRIZIONE:"DESCRIZIONE nota iscrizione",TYPE:"TEXT",VALORE:'',OPZIONI:new Array()},
                                                        {FK_ID_OPZIONE:47,FK_ID_PADRE:1,FLG_OPZIONE:true,DESCRIZIONE:"WildCard",VALORE:0,TYPE:"SELECT",OPZIONI:new Array({DESCRIZIONE:"NO",ID:0},{DESCRIZIONE:"SI",ID:1})},
                                                        {FK_ID_OPZIONE:54,FK_ID_PADRE:1,FLG_OPZIONE:true,DESCRIZIONE:"Num. giorni successivi scadenza con iscrizioni abilitate",TYPE:"TEXT",VALORE:2,opzioni:new Array()},
                                                        {FK_ID_OPZIONE:55,FK_ID_PADRE:1,FLG_OPZIONE:true,DESCRIZIONE:"Num. giorni successivi scadenza per dichiarazione assenza",TYPE:"TEXT",VALORE:3,opzioni:new Array()},
                                                        {FK_ID_OPZIONE:56,FK_ID_PADRE:1,FLG_OPZIONE:true,DESCRIZIONE:"Atleta più piccolo può partecipare a squadre di cat.superiore",TYPE:"SELECT",VALORE:0,OPZIONI:new Array({DESCRIZIONE:"NO",ID:0},{DESCRIZIONE:"SI",ID:1})},
                                                        {FK_ID_OPZIONE:57,FK_ID_PADRE:1,FLG_OPZIONE:true,DESCRIZIONE:"Atleta più grande può partecipare a squadre di cat. inferiore",TYPE:"SELECT",VALORE:0,OPZIONI:new Array({DESCRIZIONE:"NO",ID:0},{DESCRIZIONE:"SI",ID:1})},
                    )},

                    {FK_ID_PADRE:2,FLG_OPZIONE:false,titolo:"Opzioni vasca",OPZIONI:new Array(
                                                        {FK_ID_OPZIONE:24,FK_ID_PADRE:2,FLG_OPZIONE:true,DESCRIZIONE:"Tipo vasca",VALORE:1,TYPE:"SELECT",OPZIONI:new Array({DESCRIZIONE:"25",ID:1},{DESCRIZIONE:"50",ID:2})},
                                                        {FK_ID_OPZIONE:16,FK_ID_PADRE:2,FLG_OPZIONE:true,DESCRIZIONE:"Numero corsie vasca",TYPE:"TEXT",VALORE:6,OPZIONI:new Array()},
                                                        {FK_ID_OPZIONE:17,FK_ID_PADRE:2,FLG_OPZIONE:true,DESCRIZIONE:"Corsia iniziale",TYPE:"SELECT",VALORE:1,OPZIONI:new Array()},
                    )},                                                    

                    {FK_ID_PADRE:3,FLG_OPZIONE:false,titolo:"Opzioni startList",OPZIONI:new Array(
                                                        {FK_ID_OPZIONE:11,FK_ID_PADRE:3,FLG_OPZIONE:true,DESCRIZIONE:"Ordine Sesso",TYPE:"SELECT",VALORE:4,OPZIONI:new Array({DESCRIZIONE:"F/M",ID:4},{DESCRIZIONE:"M/F",ID:5})},
                                                        {FK_ID_OPZIONE:28,FK_ID_PADRE:3,FLG_OPZIONE:true,DESCRIZIONE:"StartList con BATTERIE",TYPE:"SELECT",VALORE:0,OPZIONI:new Array({DESCRIZIONE:"SI",ID:1},{DESCRIZIONE:"NO",ID:0})},
                                                        {FK_ID_OPZIONE:31,FK_ID_PADRE:3,FLG_OPZIONE:true,DESCRIZIONE:"Raggruppa categorie in batteria",TYPE:"SELECT",VALORE:0,OPZIONI:new Array({DESCRIZIONE:"SI",ID:1},{DESCRIZIONE:"NO",ID:0})}, 
                                                        {FK_ID_OPZIONE:32,FK_ID_PADRE:3,FLG_OPZIONE:true,DESCRIZIONE:"Ordinamento raggruppamenti batteria",TYPE:"SELECT",VALORE:1,OPZIONI:new Array({DESCRIZIONE:"TEMPO",ID:1},{DESCRIZIONE:"CATEGORIA",ID:0},{DESCRIZIONE:"CATEGORIA-TEMPO",ID:2},{DESCRIZIONE:"ANNO-TEMPO",ID:3})},

                    )},  
                                                       
                    {FK_ID_PADRE:4,FLG_OPZIONE:false,titolo:"Opzioni risultati",OPZIONI:new Array(
                                                        {FK_ID_OPZIONE:26,FK_ID_PADRE:4,FLG_OPZIONE:true,DESCRIZIONE:"Mostra punteggi società",VALORE:1,TYPE:"SELECT",OPZIONI:new Array({DESCRIZIONE:"NO",ID:0},{DESCRIZIONE:"SI",ID:1})}, 
                                                        {FK_ID_OPZIONE:12,FK_ID_PADRE:4,FLG_OPZIONE:true,DESCRIZIONE:"Max num atleti a punti GARA/CAT",VALORE:100,TYPE:"TEXT",OPZIONI:new Array()},
                                                        {FK_ID_OPZIONE:13,FK_ID_PADRE:4,FLG_OPZIONE:true,DESCRIZIONE:"Max num staffette a punti GARA/CAT",VALORE:100,TYPE:"TEXT",OPZIONI:new Array()},
                                                        {FK_ID_OPZIONE:14,FK_ID_PADRE:4,FLG_OPZIONE:true,DESCRIZIONE:"Max num atleti a punti EVENTO",VALORE:1000,TYPE:"TEXT",OPZIONI:new Array()},                                                
                                                        {FK_ID_OPZIONE:15,FK_ID_PADRE:4,FLG_OPZIONE:true,DESCRIZIONE:"Posizione scalata su parimerito",VALORE:1,TYPE:"SELECT",OPZIONI:new Array({DESCRIZIONE:"NO",ID:0},{DESCRIZIONE:"SI",ID:1})},
                                                        {FK_ID_OPZIONE:33,FK_ID_PADRE:4,FLG_OPZIONE:true,DESCRIZIONE:"Diversamente abili in classifica con Normodotati",TYPE:"SELECT",VALORE:0,OPZIONI:new Array({DESCRIZIONE:"NO",ID:0},{DESCRIZIONE:"SI",ID:1})},
                                                        {FK_ID_OPZIONE:44,FK_ID_PADRE:4,FLG_OPZIONE:true,DESCRIZIONE:"Max num atleti a punti società",VALORE:0,TYPE:"TEXT",OPZIONI:new Array()},
                                                        {FK_ID_OPZIONE:45,FK_ID_PADRE:4,FLG_OPZIONE:true,DESCRIZIONE:"Max num staffette a punti società",VALORE:0,TYPE:"TEXT",OPZIONI:new Array()},
                                                        {FK_ID_OPZIONE:46,FK_ID_PADRE:4,FLG_OPZIONE:true,DESCRIZIONE:"Evidenzia presenze a punti",VALORE:0,TYPE:"TEXT",OPZIONI:new Array({DESCRIZIONE:"NO",ID:0},{DESCRIZIONE:"SI",ID:1})},                                                        
                                                        {FK_ID_OPZIONE:61,FK_ID_PADRE:4,FLG_OPZIONE:true,DESCRIZIONE:"Punteggi individuali",VALORE:"9,7,6,5,4,3,2,1",TYPE:"TEXT",OPZIONI:new Array()},
                                                        {FK_ID_OPZIONE:62,FK_ID_PADRE:4,FLG_OPZIONE:true,DESCRIZIONE:"Punteggi staffette",VALORE:"18,14,12,10,8,6,4,2",TYPE:"TEXT",OPZIONI:new Array()},
                                                        {FK_ID_OPZIONE:63,FK_ID_PADRE:4,FLG_OPZIONE:true,DESCRIZIONE:"Punteggi determinati da totale atleti",VALORE:1,TYPE:"SELECT",OPZIONI:new Array({DESCRIZIONE:"NO",ID:0},{DESCRIZIONE:"SI",ID:1})},
                                                )},  

                    {FK_ID_PADRE:5,FLG_OPZIONE:false,titolo:"Opzioni importi",OPZIONI:new Array( {
                                                        FK_ID_OPZIONE:18,FK_ID_PADRE:5,FLG_OPZIONE:true,DESCRIZIONE:"Importo presenza gara",TYPE:"TEXT",VALORE:0,OPZIONI:new Array()},
                                                        {FK_ID_OPZIONE:19,FK_ID_PADRE:5,FLG_OPZIONE:true,DESCRIZIONE:"Importo iscrizione",TYPE:"TEXT",VALORE:0,OPZIONI:new Array()},
                                                        {FK_ID_OPZIONE:20,FK_ID_PADRE:5,FLG_OPZIONE:true,DESCRIZIONE:"Importo squadra",TYPE:"TEXT",VALORE:0,OPZIONI:new Array()},
                                                        {FK_ID_OPZIONE:22,FK_ID_PADRE:5,FLG_OPZIONE:true,DESCRIZIONE:"Importo società",TYPE:"TEXT",VALORE:0,OPZIONI:new Array()},
                                                        {FK_ID_OPZIONE:30,FK_ID_PADRE:5,FLG_OPZIONE:true,DESCRIZIONE:"Importo presenza 2",TYPE:"TEXT",VALORE:0,OPZIONI:new Array()},
                                                        {FK_ID_OPZIONE:40,FK_ID_PADRE:5,FLG_OPZIONE:true,DESCRIZIONE:"Importo presenza 3",TYPE:"TEXT",VALORE:0,OPZIONI:new Array()},
                                                        {FK_ID_OPZIONE:48,FK_ID_PADRE:5,FLG_OPZIONE:true,DESCRIZIONE:"Importo presenza 4",TYPE:"TEXT",VALORE:0,OPZIONI:new Array()},
                                                        {FK_ID_OPZIONE:49,FK_ID_PADRE:5,FLG_OPZIONE:true,DESCRIZIONE:"Importo presenza 5",TYPE:"TEXT",VALORE:0,OPZIONI:new Array()},

                                                        {FK_ID_OPZIONE:50,FK_ID_PADRE:5,FLG_OPZIONE:true,DESCRIZIONE:"Importo penale atleta",TYPE:"TEXT",VALORE:0,OPZIONI:new Array()},
                                                        {FK_ID_OPZIONE:51,FK_ID_PADRE:5,FLG_OPZIONE:true,DESCRIZIONE:"Importo penale presenza",TYPE:"TEXT",VALORE:0,OPZIONI:new Array()},
                                                        {FK_ID_OPZIONE:52,FK_ID_PADRE:5,FLG_OPZIONE:true,DESCRIZIONE:"Importo penale squadra",TYPE:"TEXT",VALORE:0,OPZIONI:new Array()},
                                                        {FK_ID_OPZIONE:53,FK_ID_PADRE:5,FLG_OPZIONE:true,DESCRIZIONE:"Importo MORA",TYPE:"TEXT",VALORE:0,OPZIONI:new Array()},

                                                )},  
                    {FK_ID_PADRE:7,FLG_OPZIONE:false,titolo:"Requisiti iscrizione",OPZIONI:new Array(
                                                        {FK_ID_OPZIONE:58,FK_ID_PADRE:6,FLG_OPZIONE:true,DESCRIZIONE:"Controllo su passaggi",TYPE:"SELECT",VALORE:0,OPZIONI:new Array({DESCRIZIONE:"NO",ID:0},{DESCRIZIONE:"SI",ID:1})},
                                                        {FK_ID_OPZIONE:59,FK_ID_PADRE:7,FLG_OPZIONE:true,DESCRIZIONE:"Stato iscrizioni ",VALORE:1,TYPE:"SELECT",OPZIONI:new Array({DESCRIZIONE:"STEP 1 - QUALIFICATI DI DIRITTO",ID:1},{DESCRIZIONE:"STEP 2 - RIPESCAGGI",ID:2})},
                                                        {FK_ID_OPZIONE:60,FK_ID_PADRE:7,FLG_OPZIONE:true,DESCRIZIONE:"Numero ammessi alla finale",VALORE:8,TYPE:"TEXT",OPZIONI:new Array()}
                                                )},  
                                                       
                    {FK_ID_PADRE:6,FLG_OPZIONE:false,titolo:"Altro",OPZIONI:new Array(
                                                        {FK_ID_OPZIONE:21,FK_ID_PADRE:6,FLG_OPZIONE:true,DESCRIZIONE:"Pulsanti comunicato,startlist,risultati visibili",TYPE:"SELECT",VALORE:1,OPZIONI:new Array({DESCRIZIONE:"NO",ID:0},{DESCRIZIONE:"SI",ID:1})},
                                                        {FK_ID_OPZIONE:41,FK_ID_PADRE:7,FLG_OPZIONE:true,DESCRIZIONE:"Pagamento online abilitato",VALORE:0,TYPE:"SELECT",OPZIONI:new Array({DESCRIZIONE:"NO",ID:0},{DESCRIZIONE:"SI",ID:1})},
                                                        {FK_ID_OPZIONE:42,FK_ID_PADRE:7,FLG_OPZIONE:true,DESCRIZIONE:"ID_APP",VALORE:'',TYPE:"SELECT",OPZIONI:new Array()},
                                                        {FK_ID_OPZIONE:43,FK_ID_PADRE:7,FLG_OPZIONE:true,DESCRIZIONE:"Email organizzatore",TYPE:"TEXT",VALORE:'',OPZIONI:new Array()}
                                                )},  
                        
            //ULTIMA OPZIONE:63
            )
        }
    },
    methods: {
        getEvento : function(flgInit = 1){
            utils.get('/ge/evento',{ID_EVENTO:this.evento.ID_EVENTO}, (response) => {
                this.evento = response.data;
                console.log(this.evento);
                for (var i = 0; i < this.arrayOpzioni.length ; i++) {
                    for (var j = 0 ; j < this.arrayOpzioni[i].OPZIONI.length ; j++){
                        var opz = utils.array.select(this.evento.opzioni,"FK_ID_OPZIONE",this.arrayOpzioni[i].OPZIONI[j].FK_ID_OPZIONE,1)[0];
                        if (opz != undefined){
                            this.arrayOpzioni[i].OPZIONI[j].VALORE = opz.VALORE;
                        }
                    }
                }
                if (flgInit == 1){
                    utils.get('/ge/campionati/evento',{ID_SPORT:this.evento.FK_ID_SPORT}, (response) => {
                        this.arrayCampionati = response.data;
                        utils.get('/ge/categorie',{FK_ID_STAGIONE:this.evento.FK_ID_STAGIONE}, (response) => {
                            var arrayCategorie = response.data;
                            this.arrayCategorie = new Array();
                            for (var i = 0 ; i < arrayCategorie.length ; i++) {
                                if (arrayCategorie[i].FK_ID_SPORT == this.evento.FK_ID_SPORT){
                                    this.arrayCategorie.push(arrayCategorie[i]);
                                }
                            }
                            utils.get('/ge/gare',{}, (response) => {
                                var arrayGare = response.data;
                                this.arrayGare = utils.array.select(arrayGare,"FK_ID_SPORT",this.evento.FK_ID_SPORT);
                                this.setAllCategorie(); 
                            });
                        }); 
                    });                   
                }
                else{
                    this.setAllCategorie(); 
                }
            });                         
        },
        setAllCategorie : function(){
            for (var i = 0 ; i < this.arrayAllCategorieEvento.length ; i++){
                var arr = utils.clone(this.arrayCategorie);
                //arr = utils.array.select(arr,"FK_ID_TIPO",this.arrayAllCategorieEvento[i].FK_ID_TIPO);
                for (var j = 0 ; j < arr.length ; j++){
                    arr[j].SELEZIONATO = 0;
                    for (var k = 0 ; k < this.evento.categorie.length ; k++){
                        if (this.evento.categorie[k].FK_ID_CATEGORIA == arr[j].ID_CATEGORIA 
                        && this.evento.categorie[k].FK_ID_TIPO == this.arrayAllCategorieEvento[i].FK_ID_TIPO
                        && this.evento.categorie[k].FLG_DIS == this.arrayAllCategorieEvento[i].FLG_DIS ){
                            arr[j].SELEZIONATO = 1;
                            arr[j].ID_EVENTO_CATEGORIA = this.evento.categorie[k].ID_EVENTO_CATEGORIA;
                            arr[j].FLG_ANNO = this.evento.categorie[k].FLG_ANNO;
                            arr[j].FK_ID_TIPO_TESSERAMENTO = this.evento.categorie[k].FK_ID_TIPO_TESSERAMENTO;
                            arr[j].NUMERO_GARE_ABILITATE = this.evento.categorie[k].NUMERO_GARE_ABILITATE;
                            break;   
                        }
                    }
                }
                this.arrayAllCategorieEvento[i].ARRAY_CATEGORIE = utils.clone(utils.array.ordinaArray(arr,"SELEZIONATO").reverse());
            }
        },
        setCurrentCategoria : function(event,categoria){
            console.log(categoria);
            if (event != null){
                if ($(event.target).is("button") || $(event.target).is("i")){
                    return;
                }
            }            
            for (var i = 0 ; i < this.arrayAllCategorieEvento.length ; i++){
                for (var j = 0 ; j < this.arrayAllCategorieEvento[i].ARRAY_CATEGORIE.length ; j++){
                    this.arrayAllCategorieEvento[i].ARRAY_CATEGORIE[j].CURRENT = 0;
                }  
            }
            categoria.CURRENT = 1;
            this.categoriaSelezionata = utils.clone(categoria);
            if (categoria.ID_EVENTO_CATEGORIA > 0){
                this.getGareCategoriaSelezionata();
            }         
        },
        getGareCategoriaSelezionata : function(){
            var opzione = this.getCurrentOpzione();
            utils.get('/ge/evento/categoria',{ID_EVENTO_CATEGORIA:this.categoriaSelezionata.ID_EVENTO_CATEGORIA},(response) => {
                this.categoriaSelezionata.opzioni = response.data.opzioni;
                this.arrayGareCategoria = utils.clone(utils.array.select(this.arrayGare,"FK_ID_TIPO",opzione.FK_ID_TIPO)).reverse();
                for (var i = 0 ; i < this.arrayGareCategoria.length ; i++){
                    this.arrayGareCategoria[i].SELEZIONATO = 0;
                    var check = utils.array.select(response.data.gare,"FK_ID_GARA",this.arrayGareCategoria[i].ID_GARA,1);
                    if (check.length > 0){
                        this.arrayGareCategoria[i].SELEZIONATO = 1;
                        this.arrayGareCategoria[i].ID_EVENTO_GARA = check[0]['ID_EVENTO_GARA'];
                        this.arrayGareCategoria[i].FLG_PUNTI = check[0]['FLG_PUNTI'];
                    }
                }
                this.arrayGareCategoria = utils.clone(utils.array.ordinaArray(this.arrayGareCategoria,"SELEZIONATO").reverse());
            }); 
        },
        salvaEvento : function(){
            var metodo = this.evento.ID_EVENTO > 0 ? utils.put : utils.post;
            metodo('/ge/evento',this.evento,(response) => {
                if (response.esito == 0){
                    utils.alert.success("Operazione correttamente eseguita");
                    if (this.evento.ID_EVENTO == 0){
                        this.evento.ID_EVENTO = response.id_inserito;
                        this.salvaOpzioni();
                    }
                }
            });
        },
        salvaOpzioni : function(){
            var arr = new Array();
            for (var i = 0 ; i < this.arrayOpzioni.length ; i++){
                for (var j = 0 ; j < this.arrayOpzioni[i].OPZIONI.length ; j++){
                    arr.push(this.arrayOpzioni[i].OPZIONI[j]);
                }
            }
            utils.post('/ge/evento/opzioni',{ID_EVENTO:this.evento.ID_EVENTO,opzioni:arr},(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);    
                }
            });
        },
        getRiepilogo : function(){
            utils.get('/ge/evento/riepilogo',{ID_EVENTO:this.evento.ID_EVENTO},(response) => {
                this.arrayRiepilogo = response.data; 
            });
        },
        getStyleGara : function(gara){
            if (gara.SELEZIONATO == 1){
                return {background:'#1cc88a',color:"#fff"};
            }
            return {};
        },
        aggiungiCategoria : function(categoria){
            var opzione = this.getCurrentOpzione();
            utils.post('/ge/evento/categoria',{FK_ID_EVENTO:this.evento.ID_EVENTO,
                                                FK_ID_CATEGORIA:categoria.ID_CATEGORIA,
                                                FLG_DIS:opzione.FLG_DIS,
                                                FK_ID_TIPO:opzione.FK_ID_TIPO,
                                                FK_ID_TIPO_TESSERAMENTO:1,
                                            },(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    categoria.ID_EVENTO_CATEGORIA = response.id_inserito;
                    categoria.SELEZIONATO = 1;
                    this.setCurrentCategoria(null,categoria);
                } 
            });
        },
        rimuoviCategoria:function(categoria){
            utils.alert.confirm("Sei sicuro di voler eliminare la categoria <strong>"+categoria.DESCRIZIONE+"?</strong>",()=>{
               utils.delete('ge/evento/categoria',{ID_EVENTO_CATEGORIA:categoria.ID_EVENTO_CATEGORIA}, (response) => {
                   if (response.esito == 0){
                       categoria.SELEZIONATO = 0;
                       categoria.ID_EVENTO_CATEGORIA = 0;
                       categoria.CURRENT = 0;
                       this.categoriaSelezionata = {};
                       this.getEvento(0);
                   }
               });
           },this.$root);
        },
        selezionaGara:function(event,gara){
            if ($(event.target).is("button") || $(event.target).is("i")){
                return;
            }
            if (gara.SELEZIONATO == 1){
                utils.delete('ge/evento/gara',{ID_EVENTO_GARA:gara.ID_EVENTO_GARA}, (response) => {
                    if (response.esito == 0){
                        gara.SELEZIONATO = 0;
                        utils.alert.success(response.messaggio);
                        this.getGareCategoriaSelezionata();
                    }
                });
            }
            else{
                var opzione = this.getCurrentOpzione();
                utils.post('/ge/evento/gara',{FK_ID_EVENTO:this.evento.ID_EVENTO,
                                                FK_ID_CATEGORIA:this.categoriaSelezionata.ID_CATEGORIA,
                                                FK_ID_GARA: gara.ID_GARA,
                                                FLG_DIS:opzione.FLG_DIS,
                                                FK_ID_TIPO:opzione.FK_ID_TIPO,
                                                FK_ID_TIPO_TESSERAMENTO:1,
                                                FLG_ANNO:0,
                                                FLG_PUNTI:1
                                            },(response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        this.getGareCategoriaSelezionata();
                    }
                });
            }
        },
        getCurrentOpzione : function(){
            var opzione = utils.array.select(this.arrayAllCategorieEvento,"INDICE",this.indiceAllSelezionato,1)[0];
            return opzione;
        },
        opzioniCategoria : function(){
            this.categoriaSelezionata.arrayOpzioni = utils.clone(this.arrayOpzioni);
            for (var i = 0; i < this.categoriaSelezionata.arrayOpzioni.length ; i++) {
                for (var j = 0 ; j < this.categoriaSelezionata.arrayOpzioni[i].OPZIONI.length ; j++){
                    var opz = utils.array.select(this.categoriaSelezionata.opzioni,"FK_ID_OPZIONE",this.categoriaSelezionata.arrayOpzioni[i].OPZIONI[j].FK_ID_OPZIONE,1)[0];
                    if (opz != undefined){
                        this.categoriaSelezionata.arrayOpzioni[i].OPZIONI[j].VALORE = opz.VALORE;
                    }
                }
            }
            utils.openModal("popUpOpzioniCategoria");
        },
        opzioniGara : function(gara){
            utils.get('/ge/evento/gara',gara,(response) => {
                this.garaSelezionata = utils.clone(response.data);
                this.garaSelezionata.DESCRIZIONE = gara.DESCRIZIONE;
                this.garaSelezionata.arrayOpzioni = utils.clone(this.arrayOpzioni);
                for (var i = 0; i < this.garaSelezionata.arrayOpzioni.length ; i++) {
                    for (var j = 0 ; j < this.garaSelezionata.arrayOpzioni[i].OPZIONI.length ; j++){
                        var opz = utils.array.select(this.garaSelezionata.opzioni,"FK_ID_OPZIONE",this.garaSelezionata.arrayOpzioni[i].OPZIONI[j].FK_ID_OPZIONE,1)[0];
                        if (opz != undefined){
                            this.garaSelezionata.arrayOpzioni[i].OPZIONI[j].VALORE = opz.VALORE;
                        }
                    }
                }
                utils.openModal("popUpOpzioniGara");
            });  
        },
        salvaOpzioniCategoria(){
            this.categoriaSelezionata.opzioniSave = new Array();
            for (var i = 0; i < this.categoriaSelezionata.arrayOpzioni.length ; i++) {
                for (var j = 0 ; j < this.categoriaSelezionata.arrayOpzioni[i].OPZIONI.length ; j++){
                    this.categoriaSelezionata.opzioniSave.push(this.categoriaSelezionata.arrayOpzioni[i].OPZIONI[j]);
                }
            }
            utils.put('/ge/evento/categoria',this.categoriaSelezionata,(response) => {
                if (response.esito == 0){
                    utils.closeModal("popUpOpzioniCategoria");
                    utils.alert.success(response.messaggio);
                }
            });
        },
        salvaOpzioniGara : function(){
            this.garaSelezionata.opzioniSave = new Array();
            for (var i = 0; i < this.garaSelezionata.arrayOpzioni.length ; i++) {
                for (var j = 0 ; j < this.garaSelezionata.arrayOpzioni[i].OPZIONI.length ; j++){
                    this.garaSelezionata.opzioniSave.push(this.garaSelezionata.arrayOpzioni[i].OPZIONI[j]);
                }
            }
            utils.put('/ge/evento/gara',this.garaSelezionata,(response) => {
                if (response.esito == 0){
                    utils.closeModal("popUpOpzioniGara");
                    utils.alert.success(response.messaggio);
                }
            });
        },
        ordineChangeHandler : function(event){
			//this.evento.gareAll = this.filters.draggableChangeHandler(event,this.evento.gareAll,this.searchGareAll,"ID_EVENTO_GARA");
            console.log(this.evento.gareAll);
            this.$forceUpdate();
			this.ordineHasChanged = true;		
		},	 
        generaOrdine : function(){
            utils.alert.confirm("<strong>ATTENZIONE</strong><br/>Sei sicuro di voler proseguire?<br/>L'operazione <strong>NON È REVERSIBILE</strong> e ordinerà in maniera casuale le gare cancellando, eventualmente, l'ordinamento precedentemente impostato",()=>{
                utils.post('ge/evento/generaordine',{ID_EVENTO:this.evento.ID_EVENTO}, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio)
                        this.getEvento();
                    }
                });               
            },this.$root);
        },
        salvaOrdine : function(){
            for (var i = 0 ; i < this.evento.gareOrdinate.length ; i++){
                this.evento.gareOrdinate[i]['ORDINE'] = (i+1);
            }
            utils.post('ge/evento/updateordine',{ID_EVENTO:this.evento.ID_EVENTO,items:this.evento.gareOrdinate}, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio)
                    this.getEvento();
                }
            });    
        },
        openModalNote : function(garaEvento){
            this.garaEventoSelezionata = utils.clone(garaEvento);
            utils.openModal("popUpGaraEvento");
        },
        salvaNote : function(){
            utils.put('ge/evento/gara/note',this.garaEventoSelezionata, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    utils.closeModal("popUpGaraEvento");
                    this.getEvento();
                }
            }); 
        },
        openRoute : function(route){
            this.$root.eventoSelezionato = utils.clone(this.evento);
            global.router.push(route);
        },
        generaStartlist : function(){
            utils.alert.confirm("<strong>ATTENZIONE</strong><br/>Sei sicuro di voler proseguire?<br/>L'operazione <strong>NON È REVERSIBILE</strong> ed creerà una nuova Startlist eliminando la precedente",()=>{
                utils.post('ge/evento/generastartlist',{ID_EVENTO:this.evento.ID_EVENTO}, (response) => {
                    if (response.esito == 0){
                        this.getEvento();
                    }
                });               
            },this.$root);
        },
        stampaCartellini : function(modalita){
            var url = utils.BASE_API + "core/report?ID_REPORT=1002&MODALITA="+modalita+"&ID_EVENTO="+this.evento.ID_EVENTO+"&SESSION_TOKEN="+utils.SESSION_TOKEN;
            if (modalita == 3){
                url += "&FLG_VUOTI=1";
            }
            window.open(url);
        },
        stampaStartlist : function(formato){
            window.open(utils.BASE_API + "core/report?ID_REPORT=1001&FORMATO="+formato+"&ID_EVENTO="+this.evento.ID_EVENTO+"&SESSION_TOKEN="+utils.SESSION_TOKEN);
        },
        stampaPassaggi : function(formato = 1){
            window.open(utils.BASE_API + "core/report?ID_REPORT=1005&FORMATO="+formato+"&ID_EVENTO="+this.evento.ID_EVENTO+"&SESSION_TOKEN="+utils.SESSION_TOKEN);
        },
        stampaRipescaggi : function(formato = 1){
            window.open(utils.BASE_API + "core/report?ID_REPORT=1006&FORMATO="+formato+"&ID_EVENTO="+this.evento.ID_EVENTO+"&SESSION_TOKEN="+utils.SESSION_TOKEN);
        },        
        ricalcolaRisultati : function(){
            utils.post('/ns/evento/calcolaClassifiche',{ID_EVENTO:this.evento.ID_EVENTO}, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio)
                }                
            });
        },
        elabotaRisultatiPiuEventi : function(){
            utils.post('/ns/evento/elabora',{ID_EVENTO:this.evento.ID_EVENTO}, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio)
                }                
            });
        },
        elencoIscritti : function(){
            var url = utils.BASE_API + "core/report?ID_REPORT=2001&ID_EVENTO="+this.evento.ID_EVENTO+"&SESSION_TOKEN="+utils.SESSION_TOKEN+"&FORMATO=2&ORD=1";
            window.open(url);   
        },
        riepilogoIscrizioni : function(){
            var url = utils.BASE_API + "core/report?ID_REPORT=1004&FORMATO=2&ID_EVENTO="+this.evento.ID_EVENTO+"&SESSION_TOKEN="+utils.SESSION_TOKEN;
            window.open(url);  
        },
        getTotali(campo){
            return utils.array.sumByField(this.paginatedListRiepilogo.arr,campo)
        },
        getEventiPrec : function(){
            utils.get('/ge/evento/prec',{ID_EVENTO:this.evento.ID_EVENTO}, (response) => {
                if (response.esito == 0){
                    this.arrayEventiPrec = response.data;
                }                
            });
        },
        gestioneCollegamentoEvento : function(e){
            var metodo = e.SELEZIONATO == 0 ? utils.post : utils.delete;
            metodo('ge/evento/coll',{FK_ID_EVENTO_PRINCIPALE:this.evento.ID_EVENTO,FK_ID_EVENTO:e.ID_EVENTO}, (response) => {
                if (response.esito == 0){
                    this.getEventiPrec();
                }                
            });
        },
        prorogaIscrizioni : function(societa,flgProroga){
            utils.post('/ge/evento/proroga',{ID_EVENTO:this.evento.ID_EVENTO,FK_ID_SOCIETA:societa.ID_SOCIETA,FLG_PROROGA:flgProroga}, (response) => {
                if (response.esito == 0){
                    this.getRiepilogo();
                }                
            });
            
        },
        duplicaEvento : function(){
            this.paramsEventoDaDuplicare = {ID_EVENTO:this.evento.ID_EVENTO};
            utils.openModal("popUpDuplicaEvento");
        },
        doDuplicaEvento : function(){ 
            utils.post('/ge/evento/duplica',this.paramsEventoDaDuplicare, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.evento = {gareAll:new Array(),gareOrdinate:new Array(),ID_EVENTO:response.id_inserito},
                    this.getEvento();
                }                
            });
        },
        back:function(){
            global.router.push("Eventi");
        }
    },
    computed : {
        paginatedListRiepilogo(){
            var arr = utils.inGrid(this.arrayRiepilogo,this.searchRiepilogo);
            return arr;
        },
        paginatedListCategorie(){
            var arr = utils.array.select(this.arrayAllCategorieEvento,"INDICE",this.indiceAllSelezionato,1)[0];
            var arrRet = utils.inGrid(arr.ARRAY_CATEGORIE,this.searchCategorie);
            return arrRet;
        },
        paginatedListGare(){
            var arr = utils.inGrid(this.arrayGareCategoria,this.searchGare);
            return arr;
        },
        paginatedListOrdine(){
            var arr = utils.inGrid((this.evento.gareOrdinate != undefined) ? this.evento.gareOrdinate : new Array(),this.searchGareAll);
            return arr;
        }
    },
    beforeCreate : function(){
       global.utils.deltaGriglia = 80;
    },
    unmounted : function(){
       global.utils.deltaGriglia = 0;
    },
    beforeCreate : function(){
        setTimeout(() => {
            global.utils.deltaGriglia = 100;   
            global.utils.resizeContent();     
        }, 300);
    },
    unmounted : function(){
       global.utils.deltaGriglia = 0;
    },
    created : function(){
        setTimeout(() => {
            $('a[data-toggle="tab"]').on('shown.bs.tab', (e) => {
                var tab = $(e.target).data("tab");
                switch(tab){
                    case 'generale':
                        this.getEvento();
                        break;
                    case 'iscrizioni':
                        this.getRiepilogo();
                        break;
                    case 'gareCat':
                        this.getEvento();
                        break;
                    case 'ordine':
                        this.getEvento();
                        break;
                    case 'requisiti':
                        this.getEventiPrec();
                        break;
                    case 'tempiLimite':
                        break;
                    case 'admin':
                        break;
                  }
            });            
        },300);
        var evento = utils.clone(this.$root.eventoSelezionato);
        if (evento.ID_EVENTO > 0){
            this.evento = {gareAll:new Array(),gareOrdinate:new Array(),ID_EVENTO:evento.ID_EVENTO},
            this.getEvento();
        }   
        else{
            this.evento = {ID_EVENTO:0,FK_ID_SPORT:evento.FK_ID_SPORT,gareAll:new Array(),gareOrdinate:new Array()};
            utils.get('/ge/campionati/evento',{ID_SPORT:this.evento.FK_ID_SPORT}, (response) => {
                this.arrayCampionati = response.data;
            });
        }     
    },
}
</script>