<template>
    <div>
        <div class="kt-portlet__head">
            <form class="isprint-filter" autocomplete="off">                
                <div class="form-group ">
                    <label>Descrizione</label><br/>
                    <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Descrizione" type="text" style="width:180px" v-model="search.DESCRIZIONE.VALUE">
                </div>
                <div class="form-group" v-show="$root.utente.FK_ID_LIVELLO == 1">
                    <label>Sport </label>
                    <select class="form-control form-control-sm" v-model="search.FK_ID_SPORT.VALUE">
                        <option v-for="sport in $root.arraySport" v-bind:key="sport.ID_SPORT" v-bind:value="sport.ID_SPORT">{{sport.DESCRIZIONE}}</option>
                    </select>
                </div>    
                <div class="form-group" v-show="$root.utente.FK_ID_LIVELLO == 1">
                    <label>Stagione </label>
                    <select class="form-control form-control-sm" v-model="search.FK_ID_STAGIONE.VALUE">
                        <option v-for="stagione in arrayStagioni" v-bind:key="stagione.ID_STAGIONE" v-bind:value="stagione.ID_STAGIONE">{{stagione.DESCRIZIONE}}</option>
                    </select>
                </div>   
                <button v-show="$root.utente.FK_ID_LIVELLO == 1" class="btn btn-success float-right btn-sm" v-on:click="nuovoCampionato()" style="margin-top:26px"><i class="fal fa-plus"></i> Nuovo campionato</button>
                <button v-show="$root.utente.FK_ID_LIVELLO != 1" class="btn btn-primary float-right btn-lg" v-on:click="eventi()" style="margin-top:10px"><i class="fal fa-trophy"></i> Vai a Eventi</button>

            </form>
        </div>
        <div class="isprint-grid-container" >    
            <table class="isprint-grid shadow" >
                <thead class="isprint-grid-thead" >
                <!--<isort v-bind:ordinamento.sync="ordinamento" :label="'Cognome e nome'" :campo="'COGNOME'" ></isort>-->
                    <tr style="left: 0px;background:#858796;color:#fff">
                        <th style="width:60%"><span>Campionato</span></th>
                        <th style="width:10%"><span >Stagione</span></th>
                        <th style="width:10%"><span >Sport</span></th>
                        <th style="width:20%"><span >Azioni</span></th>
                    </tr>
                </thead>
                <tbody class="isprint-grid-tbody" style="font-size:14px !important">
                    <tr style="left: 0px;height:50px" v-bind:key="campionato.ID_CAMPIONATO"  v-for="campionato in paginatedList.arr" v-bind:class="{'td-ns':campionato.FK_ID_STATO == 1}">
                        <td  style="width:60%">
                            <strong>{{campionato.DESCRIZIONE}}</strong>
                            <span v-show="campionato.FK_ID_STATO == 1 && $root.utente.FK_ID_LIVELLO == 2"> | Data iscrizione: {{ filters.formatDateTime(campionato.DATA_ISCRIZIONE) }}</span>
                            <br v-show="$root.utente.FK_ID_LIVELLO == 1" />
                            <span v-show="$root.utente.FK_ID_LIVELLO == 1">{{ campionato.FK_ID_COMITATO == 1 ? 'COMITATO REGIONALE' : 'COMITATO NAZIONALE' }}</span>
                        </td>
                        <td  style="width:10%">
                            <i class="fal fa-calendar-alt" ></i> {{ campionato.STAGIONE }}
                        </td>
                        <td  style="width:10%">
                             {{ getSport(campionato.FK_ID_SPORT) }}
                        </td>
                        <td  style="width:20%">
                            <input type="checkbox" style="width:20px;height:20px" @change="calcolaImporti()" v-show="$root.utente.FK_ID_LIVELLO != 1 && campionato.FK_ID_STATO == 0" v-model="campionato.SELEZIONATO" v-bind:true-value="1" v-bind:false-value="0" class="float-right">
                            <button v-show="$root.utente.FK_ID_LIVELLO == 1" class="btn btn-warning btn-sm float-right" v-on:click="modificaCampionato(campionato)"><i class="fal fa-pencil"></i></button>
                        </td>                    
                    </tr>
                </tbody>
            </table>
            <div class="row">
                <div class="col-6">
                    <Pagine v-model="search.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedList.count"  :numpagine="search.PAGINATION.LENGTH"></Pagine>        
                </div>
                <div class="col-6">
                    <button v-show="getSelezionati().length > 0" v-on:click="iscrizione()" class="btn btn-success btn-lg mt-3 float-right">Prosegui con l'iscrizione (€ {{  filters.formattaImporto(importoCampionati) }})</button>
                </div>
            </div>  
        </div>

        <div class="modal fade" id="popUpCampionato" data-backdrop="true" role="dialog"  aria-modal="true" >
            <div class="modal-dialog" style="min-width:574px;"  >
                <div class="modal-content" >
                    <div class="modal-header modal-header-info" >
                        <h4 style="color:#fff" class="modal-title"><i class="fal fa-euro-sign fa-fw"></i> Campionato</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span>&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row" style="width:100%">
                            <div class="form-group col-md-8" style="margin-bottom:.50rem">
                                <label >Descrizione </label>
                                <input autocomplete="off" type="text" class="form-control " placeholder="Descrizione" v-model="campionatoSelezionato.DESCRIZIONE">
                            </div> 
                            <div class="form-group col-md-4" style="margin-bottom:.50rem">
                                <label>Sport </label>
                                <select class="form-control " v-model="campionatoSelezionato.FK_ID_SPORT">
                                    <option v-for="sport in $root.arraySport" v-bind:key="sport.ID_SPORT" v-bind:value="sport.ID_SPORT">{{sport.DESCRIZIONE}}</option>
                                </select>
                            </div>   

                            <div class="form-group col-md-6" style="margin-bottom:.50rem">
                                <label>Stagione </label>
                                <select class="form-control" v-model="campionatoSelezionato.FK_ID_STAGIONE">
                                    <option v-for="stagione in arrayStagioni" v-bind:key="stagione.ID_STAGIONE" v-bind:value="stagione.ID_STAGIONE">{{stagione.DESCRIZIONE}}</option>
                                </select>
                            </div> 

                            <div class="form-group col-md-6" style="margin-bottom:.50rem">
                                <label>Comitato </label>
                                <select class="form-control " v-model="campionatoSelezionato.FK_ID_COMITATO" :disabled="campionatoSelezionato.FK_ID_TIPO == 1" >
                                    <option value="1">Regionale</option>
                                    <option value="2">Nazionale</option>
                                </select>
                            </div>                                                      
                                
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button data-dismiss="modal" class="btn btn-danger float-right"><i class="fal fa-times"></i> Annulla</button>
                        <button class="btn btn-success float-right" v-on:click="salvaCampionato()"><i class="fal fa-check"></i> Salva</button>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>
<script>


import Pagine from '@/components/utils/Pagine.vue'


export default {
    name:"Campionati",
    components:{
        Pagine, 
    },
    data:function(){
        return{
            filters:global.filters,
            search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:50},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''}, 
                FK_ID_SPORT:{TYPE:'COMBO',VALUE:''}, 
                FK_ID_STAGIONE:{TYPE:'COMBO',VALUE:''}, 
            },
            arrayCampionati:new Array(),
            arrayStagioni:new Array(),
            campionatoSelezionato:{},
            importoCampionati:0
        }
    },
    methods: {
        openRoute : function(campionato,route){
            this.$root.campionatoSelezionato = utils.clone(campionato);
            global.router.push(route);
        },
        canAddCampionato : function(){
            if (this.$root.utente.FK_ID_LIVELLO == 1){
                return true;
            }
            return false;
        },
        nuovoCampionato : function(){
            this.campionatoSelezionato = {ID_CAMPIONATO:0,FK_ID_SPORT:0};
            utils.openModal("popUpCampionato");
        },
        modificaCampionato : function(gara){
            this.campionatoSelezionato = utils.clone(gara);
            utils.openModal("popUpCampionato");
        },
        salvaCampionato : function(){
            const metodo = this.campionatoSelezionato.ID_CAMPIONATO > 0 ? utils.put : utils.post;
            metodo("ge/campionato",this.campionatoSelezionato,(response)=>{
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    utils.closeModal("popUpCampionato");
                    utils.get('/ge/campionati',{}, (response) => {
                        this.arrayCampionati = response.data.CAMPIONATI;
                    }); 
                }
            });
        },
        getSport : function(idSport){
            var sport = utils.array.select(this.$root.arraySport,"ID_SPORT",idSport,1)[0]
            return sport.DESCRIZIONE
        },
        eventi : function(){
            global.router.push("Eventi");
        },
        calcolaImporti : function(){
            utils.get('ge/campionati/importo',{ARRAY_NUOVI:this.getSelezionati()}, (response) => {
                this.importoCampionati = response.data.IMPORTO_TOTALE;
            });  
        },
        iscrizione : function(){
            utils.post('ge/campionati/iscrizione',{ARRAY_NUOVI:this.getSelezionati()}, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    if (response.data.IMPORTO_TOTALE > 0){
                        this.$root.ordine = {ID_ORDINE:response.data.ID_ORDINE};
                        global.router.push("Stripe");
                    }
                    else{
                        global.router.push("Eventi");
                    }
                }
            }); 
        },
        getSelezionati : function(){
            return utils.array.select(this.arrayCampionati,"SELEZIONATO",1);
        }
    },
    computed : {
        paginatedList(){
            var arr = utils.inGrid(this.arrayCampionati,this.search);
            return arr;
        },
    },
    created : function(){
        this.ID_SPORT = this.$root.SPORT_SELEZIONATO;
        utils.get('/ge/campionati',{ID_SPORT:this.ID_SPORT}, (response) => {
            this.arrayStagioni = response.data.STAGIONI.reverse();
            this.arrayCampionati = response.data.CAMPIONATI;
            if (this.$root.utente.FK_ID_LIVELLO > 1){
                var arrayCampionatiAssociati = response.data.CAMPIONATI_ASSOCIATI;
                for (var i = 0; i < this.arrayCampionati.length ; i++){
                    var trovato = utils.array.select(arrayCampionatiAssociati,"FK_ID_CAMPIONATO",this.arrayCampionati[i].ID_CAMPIONATO,1);
                    this.arrayCampionati[i].FK_ID_STATO = trovato.length > 0 ? 1 : 0;  
                    this.arrayCampionati[i].SELEZIONATO = 0;
                    if (trovato.length > 0){
                        this.arrayCampionati[i].DATA_ISCRIZIONE = trovato[0].DATA_ISCRIZIONE;                      
                    }
                    
                }
            }
        });        
    },
}
</script>