<template>
    <div >
        <input style="width:50px;float:left" v-on:keyup.enter="formattaInUscita();onEnter()" v-on:keyup="formattaInUscita()" maxlength="2" type="text" class="form-control" v-bind:class="{'form-control-sm':dim == 'sm'}"  v-model="tempo.MINUTI" placeholder="m">                   
        <input style="width:50px;float:left" v-on:keyup.enter="formattaInUscita();onEnter()" v-on:keyup="formattaInUscita()"  maxlength="2" type="text" class="form-control" v-bind:class="{'form-control-sm':dim == 'sm'}"  v-model="tempo.SECONDI" placeholder="s">        
        <input style="width:50px;float:left" v-show="showCentesimi()" v-on:keyup.enter="formattaInUscita();onEnter()" v-on:keyup="formattaInUscita()"  maxlength="2" type="text" class="form-control" v-bind:class="{'form-control-sm':dim == 'sm'}"  v-model="tempo.CENTESIMI" placeholder="cc">
    </div>
    
   
</template>
<script>

import { computed } from 'vue'


export default {
    name: 'Tempo',
    components:{
        
    },
    props: {
        modelValue: {
            type: Number,
        },
        dim:{
            type:String,
            defaultValue: ''
        },
        mostraCentesimi:{
            type:Boolean,
            required:false,
            defaultValue: true
        }
    },
    emits: ['update:modelValue','onenter'],
    setup(props, { emit }) {
        const tempo = computed({
            get: () => {
                var t = props.modelValue;
                if (isNaN(t)){
                    return {MINUTI: 0,SECONDI:0,CENTESIMI:0};
                }
                var MINUTI = parseInt(t/6000);
                var SECONDI = Math.floor((t - (MINUTI *  6000))/100);
                var CENTESIMI = (t - (MINUTI * 6000) - (SECONDI * 100));
                return {MINUTI: MINUTI,SECONDI:SECONDI,CENTESIMI:CENTESIMI};
            }
             ,
        });
        return { tempo };
    },
    data:function(){
        return{

        }
    },
    methods: {
        formattaInUscita(){
            var t =  parseInt(parseInt(this.tempo.MINUTI) * 6000) + parseInt( parseInt(this.tempo.SECONDI) * 100) + parseInt(this.tempo.CENTESIMI);
            this.$emit('update:modelValue',t);
        },
        onEnter : function(){
            this.$emit("onenter");
        },
        showCentesimi : function(){
            if (this.mostraCentesimi == false){
                return false;
            }
            return true;
        }
    },
    computed: {
    
    },
    updated : function(){
        
    },
    watch: {
        modelValue: {
            handler(val) {
                this.formattaInUscita(val);
            },
            deep: true
        },
    }
}

</script>