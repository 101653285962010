<template>
    <div>
    <div class="kt-portlet__head">
        <form class="isprint-filter" autocomplete="off">                
            <div class="form-group ">
                <label>ID</label><br/>
                <input class="form-control form-control-sm"  autocomplete="off"  placeholder="ID Ordine" type="text" style="width:180px" v-model="search.ID_ORDINE.VALUE">
            </div> 
            <div class="form-group ">
                <label>Denominazione</label><br/>
                <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Denominazione" type="text" style="width:180px" v-model="search.DENOMINAZIONE.VALUE">
            </div> 
            <div class="form-group ">
                <label>Cod aff</label><br/>
                <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Codice Affiliazione" type="text" style="width:180px" v-model="search.COD_AFF.VALUE">
            </div> 
            <div class="form-group ">
                <label>Tipologia</label><br/>
                <select class="form-control form-control-sm" v-model="search.FK_ID_TIPO_ORDINE.VALUE">
                    <option value="">Tutte</option>
                    <option v-for="sport in $root.arraySport" v-bind:key="sport.ID_SPORT" v-bind:value="sport.ID_SPORT">{{ sport.DESCRIZIONE }}</option>
                    <option value="1000">Campionati</option>
                </select>
            </div> 
            <!-- 
            <button v-show="$root.utente.FK_ID_LIVELLO == 1" v-on:click="deleteOrdini()" class="btn btn-danger">ELIMINA ORDINI</button>
                -->
        </form>
    </div>
    <div class="isprint-grid-container" >    
        <table class="isprint-grid shadow" >
            <thead class="isprint-grid-thead" >
            <!--<isort v-bind:ordinamento.sync="ordinamento" :label="'Cognome e nome'" :campo="'COGNOME'" ></isort>-->
                <tr style="left: 0px;background:#f4b30d;color:#fff">
                    <th style="width:6%"><span>ID</span></th>
                    <th style="width:27%"><span>Società</span></th>
                    <th style="width:10%"><span >Data creazione</span></th>
                    <th style="width:10%"><span >Importo</span></th>
                    <th style="width:10%"><span >Stornato</span></th>
                    <th style="width:18%"><span >Stato</span></th>
                    <th style="width:21%"><span >Azioni</span></th>
                </tr>
            </thead>
            <tbody class="isprint-grid-tbody" style="font-size:14px !important">
                <tr style="left: 0px;height:50px" v-bind:key="ordine.ID_GARA"  v-for="ordine in paginatedList.arr">
                    <td  style="width:6%">
                        <kbd>{{ ordine.ID_ORDINE }}</kbd>
                    </td>
                    <td  style="width:27%">
                        <strong>{{ ordine.COD_AFF }}</strong> {{ ordine.DENOMINAZIONE }}
                    </td>
                    <td  style="width:10%">
                        {{ filters.formatDateTime(ordine.DATA_CREAZIONE) }}
                    </td>
                    <td  style="width:10%;text-align:right;font-weight: bold;">
                       €  {{ filters.formattaImporto(ordine.IMPORTO) }}
                    </td>
                    <td  style="width:10%;text-align:right">
                       €  {{ filters.formattaImporto(ordine.IMPORTO_STORNATO) }}
                    </td>
                  
                    <td  style="width:18%;text-align:right">
                        <span class="badge badge-success" v-show="ordine.FK_ID_STATO_PAGAMENTO == 1">PAGATO</span>
                        <span class="badge badge-danger" v-show="ordine.FK_ID_STATO_PAGAMENTO == 0">DA PAGARE</span>
                        <span class="badge badge-warning" v-show="ordine.FK_ID_PERSONA_GIURIDICA < 0">ORDINE ANNULLATO</span>
                   </td>
                    <td  style="width:21%">
                        <button class="btn btn-info btn-grid float-right ml-2 mr-2"  v-on:click="reportOrdine(ordine)"><i class="far fa-print"></i> Report</button>
                        <button class="btn btn-warning btn-grid float-right ml-2 mr-2" v-show="$root.utente.FK_ID_LIVELLO == 1 && ordine.FK_ID_PERSONA_GIURIDICA > 0"  v-on:click="modificaOrdine(ordine)"><i class="fa fa-pencil"></i> Modifica</button>                         
                        <button class="btn btn-danger btn-grid float-right ml-2 mr-2" v-show="$root.utente.FK_ID_LIVELLO == 1 && ordine.FK_ID_PERSONA_GIURIDICA > 0" v-on:click="deleteOrdine(ordine)"><i class="fa fa-times"></i> Annulla</button>                         
                        <button class="btn btn-warning btn-grid float-right ml-2 mr-2" v-show="ordine.FK_ID_STATO_PAGAMENTO == 0 && $root.utente.FK_ID_LIVELLO != 1" v-on:click="pagaOrdine(ordine)"><i class="fa fa-euro-sign"></i> Paga</button>                         
                    </td>                    
                </tr>
            </tbody>
        </table>
        <Pagine v-model="search.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedList.count"  :numpagine="search.PAGINATION.LENGTH"></Pagine>
    </div>
</div>

<div class="modal fade" id="popUpOrdine" data-backdrop="true" role="dialog"  aria-modal="true" >
    <div class="modal-dialog" style="min-width:1000px;"  >
        <div class="modal-content" >
            <div class="modal-header modal-header-info" >
                <h4 style="color:#fff" class="modal-title"> Ordine {{ ordineSelezionato.ID_ORDINE }}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span>&times;</span>
                </button>
            </div>
            <div class="modal-body">
                
                <ul class="list-group">
                    <li class="list-group-item" v-for="quota in ordineSelezionato.quote" v-bind:key="quota.ID_QUOTA">
                        <div class="col-9">{{ quota.DESCRIZIONE }} </div>
                        <div class="col-3" style="text-align:right">€ {{ quota.PREZZO_TOTALE }} </div>
                    </li>
                </ul>
                <div>
                    <h4 class="mt-4">La Società ha un credito disponibile di € {{ creditoDisponibile }}</h4>
                    <button class="btn btn-success btn-block btn-lg" v-on:click="applicaCredito()" :disabled="!creditoDisponibile > 0"><i class="fa fa-euro-sign"></i> Applica credito</button>
                </div>
            </div>
        </div>
    </div>
</div>

</template>
<script>


import Pagine from '@/components/utils/Pagine.vue'


export default {
    name:"Ordini",
    components:{
        Pagine, 
    },
    data:function(){
        return{
            filters:global.filters,
            search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:50},
                DENOMINAZIONE:{TYPE:'TEXT',VALUE:''}, 
                COD_AFF:{TYPE:'TEXT',VALUE:''}, 
                FK_ID_TIPO_ORDINE : {TYPE:'COMBO',VALUE:''}, 
                ID_ORDINE:{TYPE:'TEXT',VALUE:''}, 
            },
            arrayOrdini:new Array(),
            ordineSelezionato:{},
            creditoDisponibile:0
        }
    },
    methods: {
        getStatoOrdine(ordine){
            switch (ordine.STATO){
                case 1:
                    return "Iscrizioni aperte <br/>fino al <strong>"+ this.filters.formatDate(ordine.DATA_SCADENZA_ISCRIZIONI) +"</strong>";
                case 2:
                    return "Iscrizioni chiuse";
                case 3:
                    return "Ordine concluso";
                case 5:
                    return "Coming soon";
                default:
                    return "-";
            }
        },
        openRoute : function(ordine,route){
            this.$root.ordineSelezionato = utils.clone(ordine);
            global.router.push(route);
        },
        canAddOrdine : function(){
            if (this.$root.utente.FK_ID_LIVELLO == 1){
                return true;
            }
            return false;
        },
        nuovoOrdine : function(){
            var ordine = {ID_EVENTO:0,FK_ID_SPORT:this.ID_SPORT};
            this.$root.ordineSelezionato = utils.clone(ordine);
            global.router.push("Ordine");
        },
        pagaOrdine : function(ordine){
            this.$root.ordine = {ID_ORDINE:ordine.ID_ORDINE};
            global.router.push("Stripe");
        },
        modificaOrdine : function(ordine){
            utils.get('/ge/ordine',{ID_ORDINE:ordine.ID_ORDINE}, (response) => {
                this.ordineSelezionato = response.data.ORDINE;
                this.creditoDisponibile = response.data.CREDITO;
                utils.openModal("popUpOrdine");
            });
        },
        applicaCredito : function(){
            utils.alert.confirm("<strong>ATTENZIONE</strong><br/>Sei sicuro di voler proseguire?<br/>L'operazione <strong>NON È REVERSIBILE</strong>",()=>{
                utils.post('/ge/ordine/storna',{ID_ORDINE:this.ordineSelezionato.ID_ORDINE}, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        utils.get('/ge/ordini',{}, (response) => {
                            this.arrayOrdini = response.data;
                        });   
                    }
                });
            },this.$root);
        },
        deleteOrdine : function(ordine){
            utils.alert.confirm("<strong>ATTENZIONE</strong><br/>Sei sicuro di voler proseguire?<br/>L'operazione <strong>NON È REVERSIBILE</strong>",()=>{
                utils.post('/ge/ordine/azzera/delete',{ID_ORDINE:ordine.ID_ORDINE}, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        utils.get('/ge/ordini',{}, (response) => {
                            this.arrayOrdini = response.data;
                        });   
                    }
                });
            },this.$root);
        },
        reportOrdine : function(ordine){
            var url = utils.BASE_API + "core/report?ID_REPORT=2002&ID_ORDINE="+ordine.ID_ORDINE+"&SESSION_TOKEN="+utils.SESSION_TOKEN;
            window.open(url);   
        },
        deleteOrdini : function(){
            utils.delete('/ge/ordini/scaduti/delete',{}, (response) => {
                
            });
        }
    },
    computed : {
        paginatedList(){
            var arr = utils.inGrid(this.arrayOrdini,this.search);
            return arr;
        },
    },
    created : function(){
        utils.get('/ge/ordini',{}, (response) => {
            this.arrayOrdini = response.data;
        });        
    },
}
</script>