<template>
    <div class="row" style="width:100%">
        <div class="form-group col-md-4" style="margin-bottom:.50rem">
            <label style="color:#008a59;font-weight:bold">Cognome <i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
            <input autocomplete="off" type="text" class="form-control form-control-sm" placeholder="Cognome" v-model="persona.COGNOME">
        </div> 
        <div class="form-group col-md-4" style="margin-bottom:.50rem">
            <label style="color:#008a59;font-weight:bold">Nome <i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
            <input autocomplete="off" type="text" class="form-control form-control-sm" placeholder="Nome" v-model="persona.NOME">
        </div>
        <div class="form-group col-md-2" style="margin-bottom:.50rem">
            <label style="color:#008a59;font-weight:bold">Sesso <i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
            <select class="form-control form-control-sm" v-model="persona.SESSO">
                <option value="1">MASCHIO</option>
                <option value="2">FEMMINA</option>
            </select>
        </div>  
        <div class="form-group col-md-2" style="margin-bottom:.50rem">
            <label style="color:#008a59;font-weight:bold">Data nascita<i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
            <DatePicker v-model="persona.DATA_NASCITA" :dim="'sm'"></DatePicker>
        </div>        
    </div>
    
    <div class="row" style="width:100%">
        <div class="form-group col-md-3" style="margin-bottom:.50rem">
            <label style="color:#008a59;font-weight:bold">Luogo di nascita<i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
            <Select2  v-model="persona.FK_ID_COMUNE_NASCITA" :w="189" :api="'core/geo/comuni/select2'" @onSelectItem="calcolaCodiceFiscale($event)" :apiselected="'core/basic/element?className=Comune&ID=ID_COMUNE&DESCRIZIONE=NOME&select2=1'"></Select2>
        </div>
        <div class="form-group col-md-3" style="margin-bottom:.50rem">
            <label style="color:#008a59;font-weight:bold">Codice Fiscale<i v-show="isCampoObbligatorio(29) == 1" class="fal fa-asterisk" style="color:#1cc88a"></i></label>
            <input autocomplete="off" type="text" class="form-control form-control-sm" placeholder="Codice fiscale" v-model="persona.CODICE_FISCALE">
        </div>   
        <div class="form-group col-md-3" style="margin-bottom:.50rem">
            <label style="color:#008a59;font-weight:bold">Email<i v-show="isCampoObbligatorio(30) == 1" class="fal fa-asterisk" style="color:#1cc88a"></i></label>
            <input autocomplete="off" type="text" class="form-control  form-control-sm" placeholder="Email" v-model="persona.EMAIL">
        </div>
        <div class="form-group col-md-3" style="margin-bottom:.50rem">
            <label style="color:#008a59;font-weight:bold">Telefono<i v-show="isCampoObbligatorio(31) == 1" class="fal fa-asterisk" style="color:#1cc88a"></i></label>
            <input autocomplete="off" type="text" class="form-control form-control-sm" placeholder="Telefono" v-model="persona.TELEFONO">
        </div>   
    </div>
    <!-- 
    <div class="row" style="width:100%">
        <div class="form-group col-md-6" style="margin-bottom:.50rem">
            <label style="color:#008a59;font-weight:bold">Indirizzo</label>
            <input autocomplete="off" type="text" class="form-control form-control-sm" placeholder="Indirizzo" v-model="persona.INDIRIZZO">
        </div>  
        <div class="form-group col-md-4" style="margin-bottom:.50rem">
            <label style="color:#008a59;font-weight:bold">Comune</label>
            <Select2 @onSelectItem="setCap($event)" v-model="persona.FK_ID_COMUNE" :w="250" :api="'core/geo/comuni/select2'" :apiselected="'core/basic/element?className=Comune&ID=ID_COMUNE&DESCRIZIONE=NOME&select2=1'"></Select2>
        </div>
        <div class="form-group col-md-2" style="margin-bottom:.50rem">
            <label style="color:#008a59;font-weight:bold">Cap</label>
            <input autocomplete="off" type="text" class="form-control form-control-sm" placeholder="Cap" v-model="persona.CAP">
        </div> 
    </div>-->
</template>
<script>

import { computed } from 'vue'
import Select2 from '@/components/utils/Select2.vue'
import DatePicker from '@/components/utils/DatePicker.vue'



export default {
    name: 'PersonaFisica',
    components:{
        Select2,
        DatePicker
    },
    props: {
        modelValue: {
            type: Object,
            default: () => ({}),
        },
        co:{
            type:Array,
        }
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const persona = computed({
            get: () => props.modelValue,
            set: (val) => emit('update:modelValue', val),
        });
        return { persona };
    },
    data:function(){
        return{
        }
    },
    methods: {
        setCap(item){
            console.log("SET CAP");
            if (this.persona.CAP == "" || (this.persona.CAP != item.CAP && item.CAP.indexOf("x") == -1)){
                this.persona.CAP = item.CAP;                
            }       
        },
        calcolaCodiceFiscale : function(comune){
            console.log(comune);
            var date = moment(this.persona.DATA_NASCITA,"DD/MM/YYYY",true);
            if (this.persona.SESSO > 0 && this.persona.COGNOME != "" && this.persona.NOME != "" && date.isValid() && this.persona.FK_ID_COMUNE_NASCITA > 0 && comune.ID_COMUNE != 8094){
                date = new Date(date._d);
                //giorno,mese,anno,cognome,nome,sesso,comune
                var codiceFiscale = utils.calcolaCodiceFiscale(date.getDate(),date.getMonth() + 1,date.getFullYear(),this.persona.COGNOME,this.persona.NOME,this.persona.SESSO,comune.COD_FISCO);
                codiceFiscale = codiceFiscale.toUpperCase();
                if (codiceFiscale != this.persona.CODICE_FISCALE){
                    this.persona.CODICE_FISCALE = codiceFiscale;
                    utils.alert.info("Codice fiscale calcolato automaticamente");
                }
            }
        },
        isCampoObbligatorio(idOpzione){
            if (this.co != undefined){
                var r = utils.array.select(this.co,"FK_ID_OPZIONE",idOpzione,1)[0];
                if (r != undefined){
                    return r['VALORE'];
                }                
            }
            return 0;
        },
    },
    computed: {
    
    },
    watch: {
        
    },
}

</script>