<template>
    <div>
    <div class="kt-portlet__head">
        <form class="isprint-filter" autocomplete="off">                
            <div class="form-group">
                <label>ID_GARA</label><br/>
                <input class="form-control form-control-sm"  autocomplete="off"  placeholder="ID_GARA" type="text" style="width:100px" v-model="search.ID_GARA.VALUE">
            </div>
            <div class="form-group ">
                <label>Descrizione</label><br/>
                <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Descrizione" type="text" style="width:180px" v-model="search.DESCRIZIONE.VALUE">
            </div>
            <div class="form-group ">
                <label>Abbr</label><br/>
                <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Abbr" type="text" style="width:140px" v-model="search.DESC_ABBR.VALUE">
            </div>
            <div class="form-group">
                <label>Tipo</label><br/>
                <select class="form-control form-control-sm" v-model="search.FK_ID_TIPO.VALUE">
                    <option value="">TUTTI</option>
                    <option value="1">INDIVIDUALE</option>
                    <option value="2">STAFFETTA</option>
                </select>
            </div>    
            <div class="form-group">
                <label>Sport </label>
                <select class="form-control form-control-sm" v-model="search.FK_ID_SPORT.VALUE">
                    <option v-for="sport in $root.arraySport" v-bind:key="sport.ID_SPORT" v-bind:value="sport.ID_SPORT">{{sport.DESCRIZIONE}}</option>
                </select>
            </div>          
            <button class="btn btn-success float-right btn-sm" v-on:click="nuovaGara()" style="margin-top:26px"><i class="fal fa-plus"></i> Nuova gara</button>
        </form>
    </div>
    <div class="isprint-grid-container" >    
        <table class="isprint-grid shadow" >
            <thead class="isprint-grid-thead" >
            <!--<isort v-bind:ordinamento.sync="ordinamento" :label="'Cognome e nome'" :campo="'COGNOME'" ></isort>-->
                <tr style="left: 0px;background:#69d0ff;color:#fff">
                    <th style="width:5%"><span>TIPO</span></th>
                    <th style="width:10%"><span>ID</span></th>
                    <th style="width:40%"><span>Descrizione</span></th>
                    <th style="width:10%"><span >Componenti</span></th>
                    <th style="width:35%"><span >Azioni</span></th>
                </tr>
            </thead>
            <tbody class="isprint-grid-tbody" style="font-size:14px !important">
                <tr style="left: 0px;height:50px" v-bind:key="gara.ID_GARA"  v-for="gara in paginatedList.arr">
                    <td  style="width:5%">
                       <i class="fal fa-2x" v-bind:class="{'fa-users':gara.FK_ID_TIPO == 2,'fa-user':gara.FK_ID_TIPO == 1}"></i>
                    </td>
                    <td  style="width:10%">
                        {{ gara.ID_GARA }}
                    </td>
                    <td  style="width:40%">
                        <strong>{{gara.DESCRIZIONE}}</strong>
                        <br/>
                        {{ getStile(gara.FK_ID_STILE) }}
                    </td>
                    <td  style="width:10%">
                        {{gara.COMPONENTI}}
                    </td>
                    <td  style="width:35%">
                        <button class="btn btn-grid btn-warning" v-on:click="modificaGara(gara)"><i class="fal fa-pencil"></i> Modifica</button>
                    </td>                    
                </tr>
            </tbody>
        </table>
        <Pagine v-model="search.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedList.count"  :numpagine="search.PAGINATION.LENGTH"></Pagine>
    </div>



    <div class="modal fade" id="popUpGara" data-backdrop="true" role="dialog"  aria-modal="true" >
        <div class="modal-dialog" style="min-width:574px;"  >
            <div class="modal-content" >
                <div class="modal-header modal-header-info" >
                    <h4 style="color:#fff" class="modal-title"><i class="fal fa-euro-sign fa-fw"></i> Gara</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span>&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row" style="width:100%">
                        <div class="form-group col-md-8" style="margin-bottom:.50rem">
                            <label >Descrizione <i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
                            <input autocomplete="off" type="text" class="form-control " placeholder="Descrizione" v-model="garaSelezionata.DESCRIZIONE">
                        </div> 
                        <div class="form-group col-md-4" style="margin-bottom:.50rem">
                            <label >Abbr <i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
                            <input autocomplete="off" type="text" class="form-control " placeholder="Abbr" v-model="garaSelezionata.DESC_ABBR">
                        </div> 
                        <div class="form-group col-md-4" style="margin-bottom:.50rem">
                            <label>Sport </label>
                            <select class="form-control " v-model="garaSelezionata.FK_ID_SPORT">
                                <option v-for="sport in $root.arraySport" v-bind:key="sport.ID_SPORT" v-bind:value="sport.ID_SPORT">{{sport.DESCRIZIONE}}</option>
                            </select>
                        </div>   

                        <div class="form-group col-md-4" style="margin-bottom:.50rem">
                            <label>Tipo </label>
                            <select class="form-control " v-model="garaSelezionata.FK_ID_TIPO">
                                <option value="1">INDIVIDUALE</option>
                                <option value="2">SQUADRA</option>
                            </select>
                        </div> 

                        <div class="form-group col-md-4" style="margin-bottom:.50rem">
                            <label>Sesso </label>
                            <select class="form-control " v-model="garaSelezionata.SESSO" :disabled="garaSelezionata.FK_ID_TIPO == 1" >
                                <option value="1">MASCHI</option>
                                <option value="2">FEMMINE</option>
                                <option value="3">MIXED</option>
                            </select>
                        </div> 
                        <div class="form-group col-md-4" :disabled="garaSelezionata.FK_ID_TIPO == 1" style="margin-bottom:.50rem">
                            <label >Componenti </label>
                            <input autocomplete="off" type="text" class="form-control " placeholder="Componenti" v-model="garaSelezionata.COMPONENTI" :disabled="garaSelezionata.FK_ID_TIPO == 1">
                        </div> 


                        <div class="form-group col-md-4" v-show="$root.APP == 'NUOTOSPRINT'" style="margin-bottom:.50rem">
                            <label >Metri <i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
                            <input autocomplete="off" type="text" class="form-control " placeholder="Metri" v-model="garaSelezionata.METRI">
                        </div> 
                        <div class="form-group col-md-4" v-show="$root.APP == 'NUOTOSPRINT'"  style="margin-bottom:.50rem">
                            <label>Stile <i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
                            <select class="form-control " v-model="garaSelezionata.FK_ID_STILE">
                                <option value="1">FARFALLA</option>
                                <option value="2">DORSO</option>
                                <option value="3">RANA</option>
                                <option value="4">STILE LIBERO</option>
                                <option value="5">MISTI</option>
                                <option value="6">PINNE/ALTRO</option>
                            </select>
                        </div> 
                        <div class="form-group col-md-4"  v-show="$root.APP == 'NUOTOSPRINT'" style="margin-bottom:.50rem">
                            <label >Fin <i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
                            <input autocomplete="off" type="text" class="form-control " placeholder="Cod Fin" v-model="garaSelezionata.COD_FIN">
                        </div> 

                        
                             
                    </div>
                </div>
                <div class="modal-footer">
                    <button data-dismiss="modal" class="btn btn-danger float-right"><i class="fal fa-times"></i> Annulla</button>
                    <button class="btn btn-success float-right" v-on:click="salvaGara()"><i class="fal fa-check"></i> Salva</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>


import Pagine from '@/components/utils/Pagine.vue'


export default {
    name:"Gare",
    components:{
        Pagine, 
    },
    data:function(){
        return{
            filters:global.filters,
            search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:50},
                ID_GARA:{TYPE:'TEXT',VALUE:''}, 
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''}, 
                DESC_ABBR:{TYPE:'TEXT',VALUE:''}, 
                FK_ID_TIPO:{TYPE:'COMBO',VALUE:''}, 
                FK_ID_SPORT:{TYPE:'COMBO',VALUE:''}, 
            },
            arrayGare:new Array(),
            garaSelezionata:{},
        }
    },
    methods: {
        getStile(fkIdStile){
            switch (fkIdStile){
                case 1:
                    return "Farfalla";
                case 2:
                    return "Dorso";
                case 3:
                    return "Rana";
                case 4:
                    return "Stile libero";
                case 5:
                    return "Misti";
                case 6:
                    return "Pinne/Altro";
            }
        },
        nuovaGara : function(){
            this.garaSelezionata = {ID_GARA:0,FK_ID_TIPO:1};
            utils.openModal("popUpGara");
        },
        modificaGara : function(gara){
            this.garaSelezionata = utils.clone(gara);
            utils.openModal("popUpGara");
        },
        salvaGara : function(){
            const metodo = this.garaSelezionata.ID_GARA > 0 ? utils.put : utils.post;
            metodo("ge/gara",this.garaSelezionata,(response)=>{
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    utils.closeModal("popUpGara");
                    utils.get('/ge/gare',{}, (response) => {
                        this.arrayGare = response.data;
                    }); 
                }
            });
        }
    },
    computed : {
        paginatedList(){
            var arr = utils.inGrid(this.arrayGare,this.search);
            return arr;
        },
    },
    created : function(){
        utils.get('/ge/gare',{}, (response) => {
            this.arrayGare = response.data;
        });        
    },
}
</script>