import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

import Amministrazione from '../views/Amministrazione.vue'
import Gare from '../views/Gen/Gare.vue'
import Categorie from '../views/Gen/Categorie.vue'
import Societa from '../views/Gen/Societa.vue'
import Eventi from '../views/Gen/Eventi.vue'
import Evento from '../views/Gen/Evento.vue'
import Gara from '../views/Ns/Gara.vue'
import Sport from '../views/Gen/Sport.vue'
import Iscrizioni from '../views/Gen/Iscrizioni.vue'
import Stripe from '../views/Gen/Stripe.vue'
import Ordini from '../views/Gen/Ordini.vue'
import Portafoglio from '../views/Gen/Portafoglio.vue'
import Campionati from '../views/Gen/Campionati.vue'


import Allenamenti from '../views/Ns/Allenamenti.vue'
import Allenamento from '../views/Ns/Allenamento.vue'

import Dashboard from '../views/Dashboard.vue'
import Login from '../views/Login.vue'
import Utenti from '../components/common/Utenti.vue'





const routes = [
    {path: '/login',  name: 'Login',    component: Login},
    {path: '/dashboard',  name: 'Dashboard',    component: Dashboard},

    {    path: '/Amministrazione',    name: 'Amministrazione',    component: Amministrazione  },
    {    path: '/Gare',         name: 'Gare',         component: Gare  },
    {    path: '/Categorie',    name: 'Categorie',    component: Categorie  },
    {    path: '/Societa',    name: 'Societa',    component: Societa  },
    {    path: '/Eventi',    name: 'Eventi',    component: Eventi  },
    {    path: '/Evento',    name: 'Evento',    component: Evento  },
    {    path: '/Gara',    name: 'Gara',    component: Gara  },
    {    path: '/Sport',    name: 'Sport',    component: Sport  },
    {    path: '/Iscrizioni',    name: 'Iscrizioni',    component: Iscrizioni  },
    {    path: '/Stripe',    name: 'Stripe',    component: Stripe  },
    {    path: '/Ordini',    name: 'Ordini',    component: Ordini  },
    {    path: '/Portafoglio',    name: 'Portafoglio',    component: Portafoglio  },
    {    path: '/Campionati',    name: 'Campionati',    component: Campionati  },

    {    path: '/Allenamenti',    name: 'Allenamenti',    component: Allenamenti  },
    {    path: '/Allenamento',    name: 'Allenamento',    component: Allenamento  },


    {path: '/Utenti',  name: 'Utenti',    component: Utenti},

  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
