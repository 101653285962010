<template>
    <div>
    <div class="kt-portlet__head">
        <form class="isprint-filter" autocomplete="off">                
            <div class="form-group ">
                <label>Descrizione</label><br/>
                <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Descrizione" type="text" style="width:180px" v-model="search.DESCRIZIONE.VALUE">
            </div>
            <div class="form-group " v-show="$root.APP != 'CONFSPORT'">
                <label>Organizzatore</label><br/>
                <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Organizzatore" type="text" style="width:180px" v-model="search.DENOMINAZIONE.VALUE">
            </div> 
            <div class="form-group col-2">
                <label>Regione</label>
                <select class="form-control form-control-sm" v-model="search.FK_ID_REGIONE.VALUE">
                    <option value="">Tutti</option>
                    <option v-for="regione in $root.arrayRegioni" v-bind:key="regione.ID_REGIONE" v-bind:value="regione.ID_REGIONE">{{regione.NOME}}</option>
                </select>
            </div>
            <div class="form-group col-2">
                <label>Anno</label>
                <select class="form-control form-control-sm " v-model="search.ANNO.VALUE">
                    <option value="">Tutti</option>
                    <option v-for="anno in arrayAnni" v-bind:key="anno" v-bind:value="anno">{{anno}}</option>
                </select>
            </div>
            <button class="btn btn-success float-right btn-sm" v-show="canAddEvento()" v-on:click="nuovoEvento()" style="margin-top:26px"><i class="fal fa-plus"></i> Nuovo evento</button>
        </form>
    </div>
    <div class="isprint-grid-container" >    
        <table class="isprint-grid shadow" >
            <thead class="isprint-grid-thead" >
            <!--<isort v-bind:ordinamento.sync="ordinamento" :label="'Cognome e nome'" :campo="'COGNOME'" ></isort>-->
                <tr style="left: 0px;background:#f4b30d;color:#fff">
                    <th style="width:40%"><span>Descrizione</span></th>
                    <th style="width:20%"><span >Info</span></th>
                    <th style="width:15%"><span >Stato</span></th>
                    <th style="width:25%"><span >Azioni</span></th>
                </tr>
            </thead>
            <tbody class="isprint-grid-tbody" style="font-size:14px !important">
                <tr style="left: 0px;height:50px" v-bind:key="evento.ID_GARA"  v-for="evento in paginatedList.arr">
 
                    <td  style="width:40%">
                        <strong>{{evento.DESCRIZIONE}}</strong>
                        <br/>
                        <small>{{ evento.DENOMINAZIONE }}</small>
                    </td>
                    <td  style="width:20%">
                        <i class="fal fa-map-marker-alt" ></i> {{ evento.LUOGO }} <br/> <i class="fal fa-calendar" ></i> {{ filters.formatDate(evento.DATA_INIZIO) }}
                    </td>
                    <td  style="width:15%">
                        <span v-html="getStatoEvento(evento)"></span>
                    </td>
                    <td  style="width:25%">
                        <button class="btn btn-grid btn-warning float-right ml-2" v-on:click="checkCanOpenIscrizioni(evento)" style="margin-top:6px" ><i class="fal fa-pencil"></i> Iscrizioni</button>                       
                        <div class="dropdown float-right"  style="margin-top:6px">
                            <button class="btn btn-danger btn-sm dropdown-toggle" type="button"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fal fa-print"></i> Stampe
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                <!-- 
                                <button class="dropdown-item" v-on:click="setAzioneMassiva(1)" type="button">Startlist</button>
                                <button class="dropdown-item">Startlist (con atleti evidenziati)</button>
                                <button class="dropdown-item" v-on:click="esportazioneSoci()" type="button"> Risultati</button>-->
                                <button class="dropdown-item" v-on:click="elencoIscritti(evento,1,1)" type="button"><i class="fal fa-file-pdf"></i> Iscritti PDF (ordine alfabetico)</button>
                                <button class="dropdown-item" v-on:click="elencoIscritti(evento,2,1)" type="button"><i class="fal fa-file-pdf"></i> Iscritti PDF (ordine per anno)</button>
                                <button class="dropdown-item" v-on:click="elencoIscritti(evento,1,2)" type="button"><i class="fal fa-file-excel"></i> Iscritti EXCEL (ordine alfabetico)</button>
                                <button class="dropdown-item" v-on:click="elencoIscritti(evento,2,2)" type="button"><i class="fal fa-file-excel"></i> Iscritti EXCEL (ordine per anno)</button>
                            </div>
                        </div>   
                        <div class="dropdown float-right mr-2 ml-2"  style="margin-top:6px" v-show="$root.utente.FK_ID_LIVELLO == 1">
                            <button class="btn btn-primary btn-sm dropdown-toggle" type="button"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fal fa-trophy"></i> Evento
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                <button class="dropdown-item" v-show="(evento.FK_ID_SPORT == 1 || evento.FK_ID_SPORT == 2 || evento.FK_ID_SPORT == 3)" v-on:click="openRoute(evento,'Gara')" >Gara</button>
                                <button class="dropdown-item" v-on:click="openRoute(evento,'Evento')" type="button">Gestione</button> 
                                <!--    
                                <button class="dropdown-item" type="button"> Duplica</button>
                                <button class="dropdown-item" v-show="evento.FK_ID_SPORT == 1" type="button">Ricalcola risultati</button>
                                -->
                            </div>
                        </div>   
                    </td>                    
                </tr>
            </tbody>
        </table>
        <Pagine v-model="search.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedList.count"  :numpagine="search.PAGINATION.LENGTH"></Pagine>
    </div>


</div>
</template>
<script>


import Pagine from '@/components/utils/Pagine.vue'


export default {
    name:"Eventi",
    components:{
        Pagine, 
    },
    data:function(){
        return{
            filters:global.filters,
            search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:50},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''}, 
                FK_ID_REGIONE:{TYPE:'COMBO',VALUE:''}, 
                ANNO:{TYPE:'COMBO',VALUE:''}, 
                DENOMINAZIONE:{TYPE:'TEXT',VALUE:''}, 
            },
            arrayAnni:new Array(),
            arrayEventi:new Array(),
            eventoSelezionata:{},
            ID_SPORT:1
        }
    },
    methods: {
        getStatoEvento(evento){
            switch (evento.STATO){
                case 1:
                    return "Iscrizioni aperte <br/>fino al <strong>"+ this.filters.formatDate(evento.DATA_SCADENZA_ISCRIZIONI) +"</strong>";
                case 2:
                    return "Iscrizioni chiuse";
                case 3:
                    return "Evento concluso";
                case 5:
                    return "Coming soon";
                default:
                    return "-";
            }
        },
        openRoute : function(evento,route){
            this.$root.eventoSelezionato = utils.clone(evento);
            global.router.push(route);
        },
        canAddEvento : function(){
            if (this.$root.utente.FK_ID_LIVELLO == 1){
                return true;
            }
            return false;
        },
        nuovoEvento : function(){
            console.log(this.ID_SPORT);
            var evento = {ID_EVENTO:0,FK_ID_SPORT:this.ID_SPORT};
            this.$root.eventoSelezionato = utils.clone(evento);
            global.router.push("Evento");
        },
        checkCanOpenIscrizioni : function(evento){
            utils.get('/ge/evento',{ID_EVENTO:evento.ID_EVENTO}, (response) => {
                if (response.data.canOpenIscrizioni == 1){
                    this.openRoute(evento,'Iscrizioni')
                }
                else{
                    utils.alert.warning("Iscrizioni non abilitate per questa Società");
                }
            });                        
        },
        elencoIscritti : function(evento,ordine,formato){
            var url = utils.BASE_API + "core/report?ID_REPORT=2001&ID_EVENTO="+evento.ID_EVENTO+"&SESSION_TOKEN="+utils.SESSION_TOKEN+"&FORMATO="+formato+"&ORD="+ordine;
            window.open(url);   
        }
    },
    computed : {
        paginatedList(){
            var arr = utils.inGrid(this.arrayEventi,this.search);
            return arr;
        },
    },
    created : function(){
        this.ID_SPORT = this.$root.SPORT_SELEZIONATO;
        utils.get('/ge/eventi',{ID_SPORT:this.ID_SPORT}, (response) => {
            this.arrayEventi = response.data;
        });      
        var d = new Date().getFullYear();
        const annoIniziale = d;
        for (d ; d > (annoIniziale - 2) ; --d)  {
            this.arrayAnni.push(d);
        }
    },
}
</script>