<template>
  <div>
    <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark shadow static-top headerbar " style="min-height: 69px;padding:0px; box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.45) !important;z-index:999;" v-bind:style="determinaColoreBarra()">
      <router-link tag="a" class="navbar-brand lift-panel" data-toggle="tooltip" data-placement="bottom" title="Dashboard" href="#" :to="'Dashboard'" style="padding-left:6px;margin-right:0px">
        <span style="font-size:26px;margin-left:20px;font-weight:bold" v-show="APP == 'CONFSPORT' && TIPO_PORTALE == 1"><i class="fal fa-home"></i> C.N.S. Libertas - Portale Gare</span>
        <span style="font-size:26px;margin-left:20px;font-weight:bold" v-show="APP == 'CONFSPORT' && TIPO_PORTALE == 2"><i class="fal fa-home"></i> Confsport Italia - Portale Gare</span>
        <span style="font-size:26px;margin-left:20px;font-weight:bold" v-show="APP == 'NUOTOSPRINT'"><i class="fal fa-home"></i> NuotoSprint</span>
      </router-link>
      <button class="navbar-toggler mr-2" type="button" data-toggle="collapse" id="btnCollapseMenu" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div v-if="utente.SOCIETA != undefined && utente.SOCIETA.usoAbilitato == 1" class="topbar-divider d-none d-md-block " ></div>
      <div  class="collapse navbar-collapse " id="navbarSupportedContent" style="z-index:545418498489416;">
        <ul class="navbar-nav mr-auto" style="margin-top:-2px" v-if="utente.SOCIETA != undefined && utente.SOCIETA.usoAbilitato == 1">
          <li class="nav-item" style="height:56px;margin-right:4px" v-show="checkMobile(route)" :data-color="getDataColor(route)" v-bind:key="route.ID_MENU" v-bind:class="{'dropdown':route.items.length > 0}" v-for="route in arrayMenu" >
              <router-link  tag="a" class="nav-link" :title="route.DESCRIZIONE" v-bind:class="{'text-center':isMobile == false}" style="height:56px;border-radius: 6px;padding-left:0px;padding-right:0px;" :to="route.ROUTE" v-if="! route.items.length > 0" v-bind:style="isCurrent(route)">
                <span style="width:100%">
                  <span class="fa-stack" style="font-size:26px;width:52px">
                    <i class="fas fa-square fa-stack-2x"  v-bind:style="iconColor(route)" ></i>
                    <i class="fal fa-stack-1x fa-inverse" v-bind:class="[route.ICONA]"></i>
                  </span>
                  <span style="display: inline-block;    margin-top: 18px;padding-right:10px" v-show="routeIsSelected(route) || isMobile == true">{{route.DESCRIZIONE}}</span>
                </span>
              </router-link>            
          </li>
        </ul>
      </div>
    
      <div v-if="isMobile == false">
        <div v-if="utente.ID_UTENTE > 0" style="color:#fff;text-align:right">{{utente.USERNAME}}<br/>{{utente.personaGiuridica.DENOMINAZIONE}}</div>
      </div>
      <div v-if="utente.ID_UTENTE > 0" class="topbar-divider d-none d-md-block " ></div>
     
       <ul  v-if="utente.SOCIETA != undefined && utente.SOCIETA.usoAbilitato == 1"  class="navbar-nav ml-auto userinfo " style="" >
        <li class="nav-item dropdown no-arrow">
          <a class="nav-link dropdown-toggle"  v-if="utente.ID_UTENTE > 0" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span style="font-size:20px;font-weight:bold;color:#fff">{{currentStagione.DESCRIZIONE}}</span>
          </a>
          <!-- Dropdown - User Information -->
          <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
            <div class="dropdown-item"><strong>Seleziona stagione</strong></div>
            <a class="dropdown-item" href="#" v-on:click="doModificaStagioneCorrente(stagione.ID_STAGIONE)" v-show="stagione.SELEZIONABILE == 1 && stagione.ID_STAGIONE != idStagioneCorrente" v-for="stagione in arrayStagioni" v-bind:key="stagione.ID_STAGIONE"  >
              <span>{{stagione.DESCRIZIONE}}</span>
            </a>
          </div>
        </li>
      </ul>
      <div v-if="utente.SOCIETA != undefined && utente.SOCIETA.usoAbilitato == 1"  class="topbar-divider d-none d-md-block " ></div>


      <ul class="navbar-nav ml-auto userinfo " style="" >
        <li class="nav-item dropdown no-arrow">
          <a class="nav-link dropdown-toggle"  v-if="utente.ID_UTENTE > 0" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="fa-stack">
              <i class="fas fa-circle fa-stack-2x"></i>
              <i class="fas fa-user fa-stack-1x fa-inverse"></i>
            </span>
            <!--
            <img class="img-profile rounded-circle" :src="'CORE/UPLOAD/AVATAR/'+ utente.URL_AVATAR" style="width:37px;height:37px">-->
          </a>
          <!-- Dropdown - User Information -->
          <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
           
            <a class="dropdown-item" href="#" v-on:click="modificaPassword()">
              <i class="fas fa-cogs fa-sm fa-fw mr-2 "></i>
              Password
            </a>
     
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#"   v-on:click="logout()" >
              <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 "></i>
              Logout
            </a>
          </div>
        </li>
      </ul>
    </nav>
    <div id="wrapper">
      <div id="content-wrapper" class="d-flex flex-column" style="margin-top:69px;padding-top:5px;overflow-y: auto:  !important;" >
          <div id="content" >
            <div class="container-fluid"  v-if="isMobile == false" id="kt_vue_content_parent" >
                <router-view/>
            </div>
            <div class="container-fluid" v-else id="kt_vue_content_parent" style="padding-left: 0.2rem ;padding-right: 0.2rem;">
                <router-view/>
            </div>
          </div>
      </div>

      <div class="modal fade" id="popUpConfirm"  role="dialog"  aria-modal="true" >
            <div class="modal-dialog"  >
                <div class="modal-content" >
                    <div class="modal-header modal-header-info">
                        <h5 class="modal-title">{{alertConfirm.TITLE}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        </button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-md-2" style="margin-top:10px;font-size:40px">
                              <i class="fas " v-bind:class="alertConfirm.ICON"></i>
                          </div>
                          <div class="col-md-10 align-items-center" style="margin-top:10px">
                              <div v-html="alertConfirm.MESSAGE"></div>
                          </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" v-on:click="alertConfirm.KOHandler()"><i class="fa fa-times fa-fw"></i>{{alertConfirm.KO}}</button>
                        <button type="button" class="btn btn-success" v-on:click="alertConfirm.OKHandler()"><i class="fa fa-check fa-fw"></i>{{alertConfirm.OK}}</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="popUpModificaPassword"  role="dialog"  aria-modal="true" >
            <div class="modal-dialog"  >
                <div class="modal-content" >
                    <div class="modal-header modal-header-info">
                        <h5 class="modal-title">Modifica Password</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        </button>
                    </div>
                    <div class="modal-body">
                      <form  autocomplete="off">   
                        <div class="form-group" >
                            <label>Vecchia password</label><br/>
                            <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Vecchia password" type="password" v-model="passwordUtente.VECCHIA_PASSWORD">
                        </div> 
                        <div class="form-group" >
                            <label>Nuova password</label><br/>
                            <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Nuova password" type="password" v-model="passwordUtente.PASSWORD">
                        </div> 
                        <div class="form-group" >
                            <label>Conferma nuova password</label><br/>
                            <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Conferma nuova password" type="password" v-model="passwordUtente.CONFERMA_PASSWORD">
                        </div>  
                      </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal"><i class="fa fa-times fa-fw"></i>Annulla</button>
                        <button type="button" class="btn btn-success" v-on:click="doModificaPassword()"><i class="fa fa-check fa-fw"></i>Ok</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="popUpAlertStandard"  role="dialog"  aria-modal="true" >
            <div class="modal-dialog"  >
                <div class="modal-content" >
                    <div class="modal-header modal-header-info">
                        <h5 class="modal-title">{{alertStd.TITLE}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        </button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-md-2" style="margin-top:10px;font-size:40px">
                              <i v-bind:class="alertStd.ICON"></i>
                          </div>
                          <div class="col-md-10 align-items-center" style="margin-top:10px">
                              <div v-html="alertStd.MESSAGE"></div>
                          </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-success" v-on:click="alertStd.OKHandler()"><i class="fa fa-check fa-fw"></i>{{alertStd.OK}}</button>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="modal fade" id="popUpModificaStagione"  role="dialog"  aria-modal="true" >
            <div class="modal-dialog"  >
                <div class="modal-content" >
                    <div class="modal-header modal-header-info">
                        <h5 class="modal-title"><i class="fal fa-calendar-check"></i> Modifica stagione corrente</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        </button>
                    </div>
                    <div class="modal-body">
                      <form  autocomplete="off">   
                        <div class="form-group" >
                            <label>Stagione corrente</label><br/>
                            <select class="form-control" v-model="idStagioneCorrente">
                              <option v-for="stagione in arrayStagioni" v-show="stagione.SELEZIONABILE == 1" v-bind:key="stagione.ID_STAGIONE" v-bind:value="stagione.ID_STAGIONE">{{stagione.DESCRIZIONE}}</option>
                            </select>
                        </div>  
                      </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal"><i class="fa fa-times fa-fw"></i>Annulla</button>
                        <button type="button" class="btn btn-success" v-on:click="doModificaStagioneCorrente()"><i class="fa fa-check fa-fw"></i>Ok</button>
                    </div>
                </div>
            </div>
        </div>

    </div>    
  </div>
</template>


<script>

export default {
    name: 'App',
    data:function(){
        return {
          filters:global.filters,
          alertConfirm:{
            MESSAGE:"prova"
          },
          zindex:1100,
          title:'Dashboard',
          icon:'fa-home',
          passwordUtente:{},
          alertStd:{},
          isAdmin : false,
          utente:{SOCIETA:{personaGiuridica:{}},persona:{}},
          eventoSelezionato:{},
          arrayMenu: new Array(),
          arrayStagioni : new Array(),
          idStagioneCorrente:0,
          isMobile:false,
          previousRoute:"/",
          VERSIONE:"",
          LOGHI_PATH:"",
          APP:"",
          TIPO_PORTALE:1,
          arraySport:new Array(),
          SPORT_SELEZIONATO:1,
          ordine:{},
          ID_ALLENAMENTO_SELEZIONATO:0,
          ID_GRUPPO_SELEZIONATO:0,
          arrayRegioni:new Array(),
          arrayModalita:new Array(),
          arrayDistanze:[12,25,50,75,100,125,150,200,250,300,400,500,600,800,1000,1500],
          arrayStili:new Array(),
          arrayIntensita : new Array(),
          arrayGruppiUtente : new Array()
        }
    },
    methods : {
      logout : function(){
        global.utils.ajax('core/logout',{}, (response) => {
          this.$root.utente = {};
          global.utils.deleteCookie("session_token");
          global.router.push("Login");
          this.arrayMenu = new Array();
        });
      },
      iconColor:function(menu){
        return {color:menu.COLORE};
      },
      routeIsSelected(menu){
        return (menu.ROUTE == this.currentRouteName /*|| (this.mainMenuActive == 0 && menu.ROUTE == this.previousRoute)*/);
      },
      isCurrent : function(menu){
        var stile = {};
        stile['color'] = "#fff";
        stile['border-bottom'] = "2px solid #5a5c69";
        stile['font-weight'] = "bold";
        if (this.routeIsSelected(menu)){
          stile['background'] = menu.COLORE;
          stile['border'] = "2px solid #fff" ;

         // stile['border-bottom'] = "2px solid " + menu.COLORE;
        }
        else{
          //stile['background'] = menu.COLORE + "20";
          stile['border'] = "2px solid rgba(0,0,0,0)" ;
        }
        return stile;
      },
      confirm : function(alertConfirm){
        this.alertConfirm = alertConfirm;
        var modal = global.$('#popUpConfirm');
        modal.modal('show');
      },
      setHeader : function(_title,_icon){
        this.title = _title;
        this.icon = _icon;
      },
      back : function(){
        global.router.go(-1);
      },
      modificaPassword : function(){
        var modal = global.$('#popUpModificaPassword');
        modal.modal('show');
      },
      modificaStagioneCorrente : function(){
        var modal = global.$('#popUpModificaStagione');
        modal.modal('show');
      },
      doModificaStagioneCorrente : function(_id = 0){
        if (_id > 0){
          this.idStagioneCorrente = _id;
        }
        var modal = global.$('#popUpModificaStagione');
        modal.modal('hide');
        global.utils.ajax('as/stagionecorrente/update',{ID_STAGIONE:this.idStagioneCorrente}, (response,status) => {
          if (response.esito == 0){
            global.utils.alert.success("Operazione correttamente eseguita");
            this.startHandler();
          }
        });
      },
      modificaProfilo : function(){
        global.router.push("Profilo");
      },
      doModificaPassword : function(){
        //)7.HRK=L3M!xiyRq,vZ
        var modal = global.$('#popUpModificaPassword');
        modal.modal('hide');
        global.utils.ajax('core/utente/password/update',this.passwordUtente, (response,status) => {
          if (response.esito == 0){
            global.utils.alert.success("Operazione correttamente eseguita");
          }
        });
      },
      getDataColor : function(route){
        return route.DESCRIZIONE;
      },
      openPopUpLogin : function(){
          var modal = global.$('#popUpRecoveryLogin');
          modal.modal('show');
      },
      checkMobile : function(route){
        if (this.isMobile == false){
          return true;
        }
        else{
          if (route.ROUTE == '/Soci' || route.ROUTE == '/attivita' || route.ROUTE == '/abbonamenti'){
            return true;
          }
        }
        return false;
      },
      determinaColoreBarra(){
        var stile = {};
        if (this.APP == 'NUOTOSPRINT'){
          stile['background-color'] = "#69d0ff !important";
        }
        else{
          if (this.TIPO_PORTALE == 1){
            stile['background-color'] = "#132361 !important";
          }
          else{
            stile['background-color'] = "#ff5730 !important";
          }
        }
        return stile;
        
      },
      startHandler : function(){
        this.arrayMenu = new Array();
        global.router.push("Start");
        //1.VERIFICO ESISTENZA TOKEN
        console.log("*** AVVIO APPLICAZIONE ***");
        var token = global.utils.getCookie("session_token");
        //SE E' PRESENTE IL TOKEN, PROVIAMO A RECUPERARE L'Utente
        if (token != "" && token != undefined && token != "undefined"){
            global.utils.SESSION_TOKEN = token;
            global.utils.ajax('core/logged',{}, (response) => {
              if (response.esito == 0 && response.data.UTENTE.ID_UTENTE > 0){
                this.utente = response.data.UTENTE;
                this.isAdmin = false;
                for (var i = 0 ; i < this.utente.funzionalita.length ; i++){
                  if (this.utente.funzionalita[i].FK_ID_ITEM > 0){
                    this.arrayMenu.push(this.utente.funzionalita[i].menuItem);
                  }
                  if (this.utente.funzionalita[i].ID_FUNZIONALITA == 14){
                    this.isAdmin = true;
                  }                  
                }
                this.arrayMenu.push(
                  {
                    ABILITATO:1,
                    COLORE:"#00bcd4",
                    DELTA_H:0,
                    DESCRIZIONE:"Guida",
                    FK_ID_MENU:1,
                    ICONA:"fal fa-question",
                    ID_ITEM:15,
                    ID_ITEM_PADRE:0,
                    ORDINE:0,
                    ROUTE:"/Guida",
                    items:new Array(),
                    numberOfKeys:1,
                    params:new Array(),
                   }
                );
                global.router.push("Dashboard");
              }
              else{
                this.utente = {SOCIETA:{}};
                this.arrayMenu = new Array();
                this.utente.SOCIETA.usoAbilitato = 0;
                global.router.push("Login");
              }
            });
        }
        else{
          global.utils.deleteCookie("session_token");
          global.utils.SESSION_TOKEN = "";
          this.utente.SOCIETA.usoAbilitato = 0;
          this.arrayMenu = new Array();
          global.router.push("Login");
        }
      }
    },
    computed : {
      currentRouteName() {
          return this.$route.path;
      },
      mainMenuActive(){
        for (var i = 0 ; i < this.arrayMenu.length ; i++){
          if (this.arrayMenu[i].ROUTE == this.currentRouteName){
            return 1;
          }
        }
        return 0;
      },
      currentStagione(){
        for (var i = 0 ; i < this.arrayStagioni.length ; i++){
          if (this.arrayStagioni[i].ID_STAGIONE == this.idStagioneCorrente){
            return this.arrayStagioni[i];
          }
        }
        return {};
      }
    },
    created : function(){
        this.filters.root = this;
        var ua = navigator.userAgent;
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua))
            this.isMobile = true;
        else
            this.isMobile = false;

        //global.utils.BASE_API = "http://localhost/dryland_2/CORE/SERVER/API/"; //process.env.VUE_APP_API_URL;
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.VERSIONE =  process.env.VUE_APP_VERSION_NUMBER;
        this.LOGHI_PATH = process.env.VUE_APP_LOGHI_PATH;
        this.APP = process.env.VUE_APP_TIPO_APP;
        this.TIPO_PORTALE = process.env.VUE_APP_TIPO_PORTALE_CONFSPORT;
        this.arraySport.push({ID_SPORT:1,DESCRIZIONE:'NUOTO'});
        if (this.APP == 'CONFSPORT'){
            this.arraySport.push({ID_SPORT:2,DESCRIZIONE:'GINNASTICA ARTISTICA FEMMINILE'});
            this.arraySport.push({ID_SPORT:3,DESCRIZIONE:'GINNASTICA ARTISTICA MASCHILE'});
            this.arraySport.push({ID_SPORT:4,DESCRIZIONE:'NUOTO SINCRONIZZATO'});
            this.arraySport.push({ID_SPORT:5,DESCRIZIONE:'GINNASTICA RITMICA'});
            this.arraySport.push({ID_SPORT:6,DESCRIZIONE:'DANZA'});
            this.arraySport.push({ID_SPORT:7,DESCRIZIONE:'BASKET'});
            this.arraySport.push({ID_SPORT:8,DESCRIZIONE:'PATTINAGGIO'});
        }
        this.startHandler();
        setTimeout(() => {
            $('.modal').on('show.bs.modal',  (e) => {

               /* setTimeout(() => {
                    this.zindex++;
                    $('.modal-backdrop').last().css("z-index",this.zindex);
                }, 200);
                setTimeout(() => {
                    this.zindex++;
                    $('.modal.fade.show').last().css("z-index",this.zindex); 
                }, 400);*/
                //ALLINEAMENTO POPUP
                setTimeout(() => {
                    console.log("allineamento livelli");
                    var arrayPopUp = $('.modal.fade.show');
                    var arrayBackDrop =  $('.modal-backdrop');
                    if (arrayPopUp.length == arrayBackDrop.length){
                      for (var i = 0 ; i < arrayPopUp.length ; i++) {
                        this.zindex++;
                        $(arrayBackDrop[i]).css("z-index",this.zindex); 
                        this.zindex++;
                        $(arrayPopUp[i]).css("z-index",this.zindex);
                      }
                    }    
                }, 500);
                    
            });                    
        }, 400);
        /*
        global.utils.ajax2('core/login',{USERNAME:'nadia.tirotta',PASSWORD:'nadia'}, (response) => {
          global.utils.SESSION_TOKEN = response.data.SESSION_TOKEN;
          global.utils.ajax2('core/logged',{USERNAME:'nadia.tirotta',PASSWORD:'nadia'}, () => {
            global.utils.ajax2('core/home',{USERNAME:'nadia.tirotta',PASSWORD:'nadia'}, () => {
              global.router.push("/soci"); 
            }); 
          });        
        });*/


        //this.startHandler();
    },
    
    watch:{
        $route (valore,valoreOld){
         // console.log(valore.path,valoreOld.path);
          this.previousRoute = valoreOld.path;
          if (valore.path == "/Pagamento"){
            this.previousRoute = "/pagamenti";
          }
          $('.modal').hide();
          $('.modal-backdrop').remove();
          $('#kt_vue_content_parent').hide().fadeIn(600);
          setTimeout(() => {
            global.$('form').submit(function(event){
                event.preventDefault();
                event.stopPropagation();
                return false;
            });
            $('.modal').on('show.bs.modal',  (e) => {

                global.$('form').submit(function(event){
                    event.preventDefault();
                    event.stopPropagation();
                    return false;
                });
                this.zindex++;
                $(e.target).css("z-index",this.zindex); 
                //ALLINEAMENTO POPUP
                setTimeout(() => {
                    console.log("allineamento livelli");
                    var arrayPopUp = $('.modal.fade.show');
                    var arrayBackDrop =  $('.modal-backdrop');
                    if (arrayPopUp.length == arrayBackDrop.length){
                      for (var i = 0 ; i < arrayPopUp.length ; i++) {
                        this.zindex++;
                        $(arrayBackDrop[i]).css("z-index",this.zindex); 
                        this.zindex++;
                        $(arrayPopUp[i]).css("z-index",this.zindex);
                      }
                    }    
                }, 500);
            });      
            global.utils.resizeContent();
          }, 300);
        }
    }
}
</script>


