<template>
    <div>
    <div class="kt-portlet__head">
        <form class="isprint-filter" autocomplete="off">                
            <div class="form-group ">
                <label>Descrizione</label><br/>
                <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Descrizione" type="text" style="width:180px" v-model="search.DESCRIZIONE.VALUE">
            </div>
            <div class="form-group ">
                <label>Abbr</label><br/>
                <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Abbr" type="text" style="width:140px" v-model="search.DESC_ABBR.VALUE">
            </div>
            <div class="form-group ">
                <label>Label</label><br/>
                <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Label" type="text" style="width:140px" v-model="search.LABEL.VALUE">
            </div>
            <div class="form-group">
                <label>ID_CATEGORIA</label><br/>
                <input class="form-control form-control-sm"  autocomplete="off"  placeholder="ID_CATEGORIA" type="text" style="width:160px" v-model="search.ID_CATEGORIA.VALUE">
            </div>
            <div class="form-group">
                <label>ID_CATEGORIA ASS</label><br/>
                <input class="form-control form-control-sm"  autocomplete="off"  placeholder="ID_CATEGORIA ASS" type="text" style="width:160px" v-model="search.FK_ID_CATEGORIA_ASS.VALUE">
            </div>
            <div class="form-group">
                <label>Sport </label>
                <select class="form-control  form-control-sm" v-model="search.FK_ID_SPORT.VALUE">
                    <option value="">Tutte</option>
                    <option v-for="sport in $root.arraySport" v-bind:key="sport.ID_SPORT" v-bind:value="sport.ID_SPORT">{{sport.DESCRIZIONE}}</option>
                </select>
            </div>
            
            <!-- 
            <div class="form-group">
                <label>Tipo</label><br/>
                <select class="form-control form-control-sm" v-model="search.FK_ID_TIPO.VALUE">
                    <option value="">TUTTI</option>
                    <option value="1">INDIVIDUALE</option>
                    <option value="2">SQUADRA</option>
                </select>
            </div>              
            -->
            <button class="btn btn-success float-right btn-sm" v-on:click="nuovaCategoria()" style="margin-top:26px"><i class="fal fa-plus"></i> Nuova categoria</button>
        </form>
    </div>
    <div class="isprint-grid-container" >    
        <table class="isprint-grid shadow" >
            <thead class="isprint-grid-thead" >
            <!--<isort v-bind:ordinamento.sync="ordinamento" :label="'Cognome e nome'" :campo="'COGNOME'" ></isort>-->
                <tr style="left: 0px;background:#69d0ff;color:#fff">
                    <th style="width:5%"><span>TIPO</span></th>
                    <th style="width:10%"><span>ID</span></th>
                    <th style="width:40%"><span>Descrizione</span></th>
                    <th style="width:20%"><span >Anni</span></th>
                    <th style="width:10%"><span >Sport</span></th>                    
                    <th style="width:15%"><span >Azioni</span></th>
                </tr>
            </thead>
            <tbody class="isprint-grid-tbody" style="font-size:14px !important">
                <tr style="left: 0px;height:50px" v-bind:key="categoria.ID_categoria_CARICATO"  v-for="categoria in paginatedList.arr">
                    <td  style="width:5%">
                       <i class="fal fa-2x" v-bind:class="{'fa-users':categoria.FK_ID_TIPO == 2,'fa-user':categoria.FK_ID_TIPO == 1}"></i>
                    </td>
                    <td  style="width:10%">
                        {{ categoria.ID_CATEGORIA }} <strong>({{ categoria.FK_ID_CATEGORIA_ASS }})</strong>
                    </td>
                    <td  style="width:40%">
                        <strong>{{categoria.DESCRIZIONE}}</strong>
                        <br/>
                        {{ categoria.DESC_ABBR }} | {{ categoria.LABEL }}
                    </td>                    
                    <td  style="width:20%;">
                        <span class="badge badge-femmina">{{filters.formatDateYear(categoria.DATA_NASCITA_DA_F)}} - {{filters.formatDateYear(categoria.DATA_NASCITA_A_F)}}</span><br/>
                        <span class="badge badge-maschio mt-1">{{filters.formatDateYear(categoria.DATA_NASCITA_DA_M)}} - {{filters.formatDateYear(categoria.DATA_NASCITA_A_M)}}</span>
                    </td>
                    <td  style="width:10%">
                        {{getSport(categoria.FK_ID_SPORT)}}
                    </td>
                    <td  style="width:15%">
                        <button class="btn btn-grid btn-warning" v-on:click="modificaCategoria(categoria)"><i class="fal fa-pencil"></i> Modifica</button>
                    </td>                    
                </tr>
            </tbody>
        </table>
        <Pagine v-model="search.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedList.count"  :numpagine="search.PAGINATION.LENGTH"></Pagine>
    </div>



    <div class="modal fade" id="popUpCategoria" data-backdrop="true" role="dialog"  aria-modal="true" >
        <div class="modal-dialog" style="min-width:768px;"  >
            <div class="modal-content" >
                <div class="modal-header modal-header-info" >
                    <h4 style="color:#fff" class="modal-title"> Categoria</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span>&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row" style="width:100%">
                        <div class="form-group col-md-6" style="margin-bottom:.50rem">
                            <label >Descrizione </label>
                            <input autocomplete="off" type="text" class="form-control " placeholder="Descrizione" v-model="categoriaSelezionata.categoriaAss.DESCRIZIONE">
                        </div> 
                        <div class="form-group col-md-4" style="margin-bottom:.50rem">
                            <label >Label </label>
                            <input autocomplete="off" type="text" class="form-control " placeholder="Label" v-model="categoriaSelezionata.categoriaAss.LABEL">
                        </div> 
                        <div class="form-group col-md-2" style="margin-bottom:.50rem">
                            <label >Abbr </label>
                            <input autocomplete="off" type="text" class="form-control " placeholder="Abbr" v-model="categoriaSelezionata.categoriaAss.DESC_ABBR">
                        </div> 
                        
                        <div class="form-group col-md-6" style="margin-bottom:.50rem">
                            <label >Anno nascita DA - M (01/01/....)</label>
                            <!-- 
                            <select class="form-control " v-model="categoriaSelezionata.DATA_NASCITA_DA_M">
                                <option v-for="anno in arrayAnni" v-bind:key="anno" v-bind:value="anno">{{ anno }}</option>
                            </select>-->
                            <DatePicker type="text" class="form-control" v-model="categoriaSelezionata.DATA_NASCITA_DA_M"></DatePicker>
                        </div> 
                        <div class="form-group col-md-6" style="margin-bottom:.50rem">
                            <label >Anno nascita A - M (31/12/....) </label>
                            <!-- 
                            <select class="form-control "  v-model="categoriaSelezionata.DATA_NASCITA_A_M">
                                <option v-for="anno in arrayAnni" v-bind:key="anno" v-bind:value="anno">{{ anno }}</option>
                            </select>-->
                            <DatePicker type="text" class="form-control" v-model="categoriaSelezionata.DATA_NASCITA_A_M"></DatePicker>

                        </div> 

                        <div class="form-group col-md-6" style="margin-bottom:.50rem">
                            <label >Anno nascita DA - F (01/01/....)</label>
                            <!-- 
                            <select class="form-control "  v-model="categoriaSelezionata.DATA_NASCITA_DA_F">
                                <option v-for="anno in arrayAnni" v-bind:key="anno" v-bind:value="anno">{{ anno }}</option>
                            </select> -->
                            <DatePicker type="text" class="form-control" v-model="categoriaSelezionata.DATA_NASCITA_DA_F"></DatePicker>

                        </div> 
                        <div class="form-group col-md-6" style="margin-bottom:.50rem">
                            <label >Anno nascita A - F </label>
                            <!-- 
                            <select class="form-control "  v-model="categoriaSelezionata.DATA_NASCITA_A_F">
                                <option v-for="anno in arrayAnni" v-bind:key="anno" v-bind:value="anno">{{ anno }}</option>
                            </select>-->
                            <DatePicker type="text" class="form-control" v-model="categoriaSelezionata.DATA_NASCITA_A_F"></DatePicker>

                        </div>              
                        <!-- 
                        <div class="form-group col-md-4" style="margin-bottom:.50rem">
                            <label>Tipo </label>
                            <select class="form-control " v-model="categoriaSelezionata.categoriaAss.FK_ID_TIPO">
                                <option value="1">INDIVIDUALE</option>
                                <option value="2">SQUADRA</option>
                            </select>
                        </div> 
                        -->         
                        <div class="form-group col-md-4" style="margin-bottom:.50rem">
                            <label>Sport </label>
                            <select class="form-control " v-model="categoriaSelezionata.categoriaAss.FK_ID_SPORT">
                                <option v-for="sport in $root.arraySport" v-bind:key="sport.ID_SPORT" v-bind:value="sport.ID_SPORT">{{sport.DESCRIZIONE}}</option>
                            </select>
                        </div>                              
                    </div>
                </div>
                <div class="modal-footer">
                    <button data-dismiss="modal" class="btn btn-danger float-right"><i class="fal fa-times"></i> Annulla</button>
                    <button class="btn btn-success float-right" v-on:click="salvaCategoria()"><i class="fal fa-check"></i> Salva</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>

import DatePicker from '@/components/utils/DatePicker.vue'
import Pagine from '@/components/utils/Pagine.vue'


export default {
    name:"Categorie",
    components:{
        Pagine, 
        DatePicker
    },
    data:function(){
        return{
            filters:global.filters,
            search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:50},
                ID_CATEGORIA:{TYPE:'TEXT',VALUE:''}, 
                FK_ID_CATEGORIA_ASS:{TYPE:'TEXT',VALUE:''}, 
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''}, 
                DESC_ABBR:{TYPE:'TEXT',VALUE:''}, 
                LABEL:{TYPE:'TEXT',VALUE:''}, 
                FK_ID_SPORT:{TYPE:'COMBO',VALUE:''}, 
            },
            arrayCategorie:new Array(),
            categoriaSelezionata:{categoriaAss:{}},
            arrayAnni:new Array()
        }
    },
    methods: {
        nuovaCategoria : function(){
            this.categoriaSelezionata = {ID_CATEGORIA:0,FK_ID_TIPO:1,FK_ID_SPORT:1,categoriaAss:{ID_CATEGORIA_ASS:0}};
            utils.openModal("popUpCategoria");
        },
        modificaCategoria : function(categoria){            
            utils.get("ge/categoria",{ID_CATEGORIA:categoria.ID_CATEGORIA},(response) =>{
                var cat = response.data;
                /*
                cat.DATA_NASCITA_DA_M = cat.DATA_NASCITA_DA_M.toString().substr(6,4);
                cat.DATA_NASCITA_A_M = cat.DATA_NASCITA_A_M.toString().substr(6,4);
                cat.DATA_NASCITA_DA_F = cat.DATA_NASCITA_DA_F.toString().substr(6,4);
                cat.DATA_NASCITA_A_F = cat.DATA_NASCITA_A_F.toString().substr(6,4);
                */
                this.categoriaSelezionata = cat;
                utils.openModal("popUpCategoria");
            });
            
        },
        salvaCategoria : function(){
            const metodo = this.categoriaSelezionata.ID_CATEGORIA > 0 ? utils.put : utils.post;
            /*
            this.categoriaSelezionata.DATA_NASCITA_DA_M = '01/01/'+this.categoriaSelezionata.DATA_NASCITA_DA_M;
            this.categoriaSelezionata.DATA_NASCITA_A_M = '31/12/'+this.categoriaSelezionata.DATA_NASCITA_A_M;
            this.categoriaSelezionata.DATA_NASCITA_DA_F = '01/01/'+this.categoriaSelezionata.DATA_NASCITA_DA_F;
            this.categoriaSelezionata.DATA_NASCITA_A_F = '31/12/'+this.categoriaSelezionata.DATA_NASCITA_A_F;
            */
            metodo("ge/categoria",this.categoriaSelezionata,(response)=>{
                if (response.esito == 0){
                    utils.closeModal("popUpCategoria");
                    utils.alert.success(response.messaggio);
                    utils.get('/ge/categorie',{}, (response) => {
                        this.arrayCategorie = response.data;
                    }); 
                }
            });
        },
        getSport : function(idSport){
            var sports = utils.array.select(this.$root.arraySport,"ID_SPORT",idSport,1);
            if (sports.length > 0){
                return sports[0].DESCRIZIONE
            }
            return "-";            
        }
    },
    computed : {
        paginatedList(){
            var arr = utils.inGrid(this.arrayCategorie,this.search);
            return arr;
        },
    },
    created : function(){
        for (var i = new Date().getFullYear() ; i > 1899  ; --i) {
            this.arrayAnni.push(i);
        }
        utils.get('/ge/categorie',{}, (response) => {
            this.arrayCategorie = response.data;
        });        
    },
}
</script>