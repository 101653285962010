<template>
    <nav   style="margin-top:20px">
        <Pag 
            v-model="localValue"
            :page-count="numpagine"
            :container-class="'pagination'"
            :first-last-button="true"
            :page-link-class="'page-link'"
            :next-link-class="'page-link'"
            :prev-link-class="'page-link'"
            :active-class="'active'"
            :first-button-text="'<<'"
            :last-button-text="'>>'"
            :next-text="'>'"
            :prev-text="'<'"
            :numrighe="numrighe"
            :pdf="pdf"
            :xlsx="xlsx"
            :csv="csv"
            @esportazione="esportazione($event)">
        </Pag>       
    </nav>
</template>
<script>
import Pag from '@/components/utils/Pag.vue'


export default {
    name: 'Pagine',
    components: {
        Pag
    },
    props: {
        options: {
          
        },
        modelValue: Number,
        numpagine: {
          type: Number,
        },
        numrighe: {
            type: Number,
        },
        pdf:{
            type:Boolean,
            default : false
        },
        xlsx:{
            type:Boolean,
            default : false
        },
        csv:{
            type:Boolean,
            default : false
        }
    },
    emits: ['update:modelValue'],

    data:function(){
        return{
            //CREO UN VALORE COPIA DEL VALORE IN INPUT PERCHE' VUE NON PUO' GESTIRE DIRETTAMENTE IL V-MODEL ALL'ITERNO DEL CHILD COMPONENT
            localValue:global.utils.clone(this.modelValue)
        }
    },
    methods: {
        esportazione:function(formato){
            this.$emit('esportazione',formato);
        }
    },
    computed: {
    
    },
    watch: {
        localValue:function(){
            this.$emit('update:modelValue', this.localValue)
        }
    },
}

</script>