<template>
    <div >
        <input style="width:50px;float:left" v-on:keyup.enter="formattaInUscita();onEnter()" v-on:keyup="formattaInUscita()" maxlength="2" type="text" class="form-control form-control-sm" v-bind:class="{'form-control-sm':dim == 'sm'}"  v-model="punteggio.UNITA" placeholder="U">                   
        <input style="width:50px;float:left" v-on:keyup.enter="formattaInUscita();onEnter()" v-on:keyup="formattaInUscita()"  maxlength="2" type="text" class="form-control form-control-sm" v-bind:class="{'form-control-sm':dim == 'sm'}"  v-model="punteggio.DECIMALI" placeholder="CC">        
    </div>
    
   
</template>
<script>

import { computed } from 'vue'


export default {
    name: 'Punteggio',
    components:{
        
    },
    props: {
        modelValue: {
            type: Number,
        },
        dim:{
            type:String,
            defaultValue: ''
        }
    },
    emits: ['update:modelValue','onenter'],
    setup(props, { emit }) {
        const punteggio = computed({
            get: () => {
                var t = props.modelValue;
                if (isNaN(t)){
                    return {UNITA: 0,DECIMALI:0};
                }
                var UNITA = parseInt(t/100);
                var DECIMALI = Math.floor((t - (UNITA *  100)));
                return {UNITA: UNITA,DECIMALI:DECIMALI};
            }
             ,
        });
        return { punteggio };
    },
    data:function(){
        return{

        }
    },
    methods: {
        formattaInUscita(){
            var t =  parseInt(parseInt(this.punteggio.UNITA) * 100) + parseInt( parseInt(this.punteggio.DECIMALI));
            this.$emit('update:modelValue',t);
        },
        onEnter : function(){
            this.$emit("onenter");
        }
    },
    computed: {
    
    },
    updated : function(){
        
    },
    watch: {
        modelValue: {
            handler(val) {
                this.formattaInUscita(val);
            },
            deep: true
        },
    }
}

</script>