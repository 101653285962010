<template>
    <div style="width:100%">
        <input v-bind:class="campo.CLASSE_CTRL"  v-if="campo.TYPE == 'TEXT'" :placeholder="campo.LABEL" type="text" class="form-control input-sm" v-model="localValue">
        <input v-bind:class="campo.CLASSE_CTRL"  v-if="campo.TYPE == 'PASSWORD'" :placeholder="campo.LABEL" type="password" class="form-control input-sm" v-model="localValue" >
        <textarea v-bind:class="campo.CLASSE_CTRL"  v-if="campo.TYPE == 'TEXTAREA'" :placeholder="campo.LABEL" type="text" class="form-control input-sm" v-model="localValue"></textarea>

        <select v-bind:class="campo.CLASSE_CTRL"  v-if="campo.TYPE == 'SELECT'" :placeholder="campo.LABEL" class="form-control input-sm" v-model="localValue" >
            <option v-for="option in getOptions" v-bind:value="option.ID" v-bind:key="option.ID">{{option.DESCRIZIONE}}</option>
        </select>
        <Select2 v-model="localValue" v-if="campo.TYPE == 'SELECT2'"  :options="campo.OPTIONS" :api="campo.API" :apiselected="campo.API_SELECTED"></Select2>
        <DatePicker v-bind:class="campo.CLASSE_CTRL"  v-if="campo.TYPE == 'DATE'" :placeholder="campo.LABEL"  v-model="localValue"></DatePicker>
    </div>
</template>
<script>

import { computed } from 'vue'

import Select2 from '@/components/utils/Select2.vue'
import DatePicker from '@/components/utils/DatePicker.vue'

export default {
    name:"IControl",
    components:{
        DatePicker,
        Select2
    },
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: [String, Number]
        },
    },
    props:['campo'],
    setup(props, { emit }) {
        const localValue = computed({
            get: () => props.campo.VALORE,
            set: (val) => emit('update:modelValue', val),
        });
        return { localValue };
    }, 
    computed:{
        getOptions : function() {
            if (this.campo.hasOwnProperty("OPZIONI")){
                if (Array.isArray(this.campo.OPZIONI)){
                    return this.campo.OPZIONI;
                }
                else{
                    try{
                        return eval(this.campo.OPZIONI);
                    }
                    catch(Exception){
                        return new Array();
                    }                    
                }
            }
            return new Array();
        }
    }
}
</script>