<template>
    <div>
    <div class="kt-portlet__head">
        <form class="isprint-filter" autocomplete="off">                
            <div class="form-group">
                <label>ID_SOCIETA</label><br/>
                <input class="form-control form-control-sm"  autocomplete="off"  placeholder="ID_SOCIETA" type="text" style="width:100px" v-model="search.ID_SOCIETA.VALUE">
            </div>
            <div class="form-group">
                <label>Codice affiliazione</label><br/>
                <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Cod aff" type="text" style="width:100px" v-model="search.COD_AFF.VALUE">
            </div>
            <div class="form-group ">
                <label>Denominazione</label><br/>
                <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Denominazione" type="text" style="width:180px" v-model="search.DENOMINAZIONE.VALUE">
            </div>     
            <div class="form-group">
                <label>Regione</label><br/>
                <Select2  v-model="search.FK_ID_REGIONE.VALUE" :w="250" :api="'core/basic/search/select2?className=Regione&ID=ID_REGIONE&DESCRIZIONE=NOME'" ></Select2>
            </div>
            <div class="form-group">
                <label>Provincia</label><br/>
                <Select2  v-model="search.FK_ID_PROVINCIA.VALUE" :w="250" :api="'core/basic/search/select2?className=Provincia&ID=ID_PROVINCIA&DESCRIZIONE=NOME'" ></Select2>
            </div>
            <div class="form-group">
                <label>Comune</label><br/>
                <Select2  v-model="search.FK_ID_COMUNE.VALUE" :w="250" :api="'core/basic/search/select2?className=Comune&ID=ID_COMUNE&DESCRIZIONE=NOME'" ></Select2>
            </div>
            <button class="btn btn-success float-right btn-sm" v-on:click="nuovaSocieta()" style="margin-top:26px"><i class="fal fa-plus"></i> Nuova societa</button>
            <button class="btn btn-primary float-right btn-sm" v-on:click="caricaFlussoSocieta()" style="margin-top:26px"><i class="fal fa-upload fa-fw"></i> Upload Societa</button> 
            <button class="btn btn-primary float-right btn-sm" v-on:click="caricaFlussoAtletiAll()" style="margin-top:26px"><i class="fal fa-upload fa-fw"></i> Upload Atleti</button> 
        </form>
    </div>
    <div class="isprint-grid-container" >    
        <table class="isprint-grid shadow" >
            <thead class="isprint-grid-thead" >
            <!--<isort v-bind:ordinamento.sync="ordinamento" :label="'Cognome e nome'" :campo="'COGNOME'" ></isort>-->
                <tr style="left: 0px;background:#69d0ff;color:#fff">
                    <th style="width:10%"><span>ID</span></th>
                    <th style="width:10%"><span>Codice affiliazione</span></th>
                    <th style="width:20%"><span>Denominazione</span></th>
                    <th style="width:20%"><span>Contatti</span></th>
                    <th style="width:25%"><span >Atleti</span></th>
                    <th style="width:15%"><span >Azioni</span></th>
                </tr>
            </thead>
            <tbody class="isprint-grid-tbody" style="font-size:14px !important">
                <tr style="left: 0px;height:50px" v-bind:key="societa.ID_SOCIETA"  v-for="societa in paginatedList.arr">
                    <td  style="width:10%">
                        <kbd>{{ societa.ID_SOCIETA }}</kbd> 
                    </td>
                    <td  style="width:10%">
                        <strong>{{ societa.COD_AFF }}</strong>
                    </td>
                    <td  style="width:20%">
                        <strong>{{ societa.DENOMINAZIONE }}</strong>
                    </td>
                    <td  style="width:20%">
                        <strong>{{societa.TELEFONO}}</strong>
                        <br/>
                        {{ societa.EMAIL }}
                    </td>
                    <td  style="width:25%;text-align: right;">
                        <button class="btn btn-grid btn-danger mr-2" v-on:click="deleteAtleti(societa)"><i class="fal fa-trash-alt"></i> Elimina tutti gli atleti</button>          
                        <button class="btn btn-grid btn-primary" style="width:80px" v-on:click="getAtleti(societa)"><i class="fal fa-users"></i> {{societa.TOT}}</button>                        
                    </td>
                    <td  style="width:15%;text-align:right">
                        <button class="btn btn-grid btn-warning" v-on:click="modificaSocieta(societa)"><i class="fal fa-pencil"></i> Modifica</button>
                    </td>                    
                </tr>
            </tbody>
        </table>
        <Pagine v-model="search.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedList.count"  :numpagine="search.PAGINATION.LENGTH"></Pagine>
    </div>



    <div class="modal fade" id="popUpSocieta" data-backdrop="true" role="dialog"  aria-modal="true" >
        <div class="modal-dialog" style="min-width:800px;"  >
            <div class="modal-content" >
                <div class="modal-header modal-header-info" >
                    <h4 style="color:#fff" class="modal-title"><i class="fal fa-euro-sign fa-fw"></i> Societa</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span>&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row" style="width:100%">
                        <div class="form-group col-md-8" style="margin-bottom:.50rem">
                            <label >Denominazione <i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
                            <input autocomplete="off" type="text" class="form-control " placeholder="Descrizione" v-model="societaSelezionata.DENOMINAZIONE">
                        </div> 
                        <div class="form-group col-md-4" style="margin-bottom:.50rem">
                            <label >Cod Aff. <i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
                            <input autocomplete="off" type="text" class="form-control " placeholder="Cod.Aff" v-model="societaSelezionata.COD_AFF">
                        </div> 
                        <div class="form-group col-md-4" style="margin-bottom:.50rem">
                            <label >Telefono <i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
                            <input autocomplete="off" type="text" class="form-control " placeholder="Telefono" v-model="societaSelezionata.TELEFONO">
                        </div> 
                        <div class="form-group col-md-4" style="margin-bottom:.50rem">
                            <label >Email <i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
                            <input autocomplete="off" type="text" class="form-control " placeholder="Email" v-model="societaSelezionata.EMAIL">
                        </div> 
                        <div class="form-group col-md-4" style="margin-bottom:.50rem">
                            <label >Data scadenza affiliazione <i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
                            <DatePicker v-model="societaSelezionata.DATA_SCADENZA_AFFILIAZIONE"></DatePicker>
                        </div> 
                        <div class="form-group col-md-4">
                            <label>Comune</label>
                            <Select2  v-model="societaSelezionata.personaGiuridica.FK_ID_COMUNE" :w="250" :api="'core/basic/search/select2?className=Comune&ID=ID_COMUNE&DESCRIZIONE=NOME'" :apiselected="'core/basic/element?className=Comune&ID=ID_COMUNE&DESCRIZIONE=NOME&select2=1'"></Select2>
                        </div>
                        <div class="form-group col-md-4" style="margin-bottom:.50rem">
                            <label >Partita IVA <i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
                            <input autocomplete="off" type="text" class="form-control " placeholder="Partita IVA" v-model="societaSelezionata.personaGiuridica.PARTITA_IVA">
                        </div> 
                        <div class="form-group col-md-4" style="margin-bottom:.50rem">
                            <label >Codice Fiscale <i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
                            <input autocomplete="off" type="text" class="form-control " placeholder="Codice Fiscale" v-model="societaSelezionata.personaGiuridica.CODICE_FISCALE">
                        </div> 
                        
                             
                    </div>
                </div>
                <div class="modal-footer">
                    <button data-dismiss="modal" class="btn btn-danger float-right"><i class="fal fa-times"></i> Annulla</button>
                    <button class="btn btn-success float-right" v-on:click="salvaSocieta()"><i class="fal fa-check"></i> Salva</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="popUpAtleti" data-backdrop="true" role="dialog"  aria-modal="true" >
        <div class="modal-dialog" style="min-width:1100px;"  >
            <div class="modal-content" >
                <div class="modal-header modal-header-info" >
                    <h4 style="color:#fff" class="modal-title">Societa {{ societaSelezionata.DENOMINAZIONE }}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span>&times;</span>
                    </button>
                </div>
                <div class="modal-body">                    
                    <div class="kt-portlet__head" >
                        <form class="isprint-filter" autocomplete="off">                
                            <div class="form-group">
                                <label>Cognome</label><br/>
                                <input type="text" class="form-control form-control-sm" v-model="searchAtleti.COGNOME.VALUE">
                            </div>
                            <div class="form-group">
                                <label>Nome</label><br/>
                                <input type="text" class="form-control form-control-sm" v-model="searchAtleti.NOME.VALUE">
                            </div>

                            <button class="btn btn-success float-right btn-sm" v-on:click="nuovoAtleta()"  style="margin-top:28px"><i class="fal fa-plus fa-fw"></i> Nuovo</button>
                            <button class="btn btn-primary float-right btn-sm" v-on:click="caricaFlusso()" style="margin-top:28px"><i class="fal fa-upload fa-fw"></i> Upload</button>

                            <!-- 
                                
                            
                            <div class="dropdown float-right"  style="margin-top:28px;margin-right:10px">
                                <button class="btn btn-warning dropdown-toggle btn-sm" type="button"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fal fa-euro-sign"></i> Certificazioni
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                    <button class="dropdown-item" v-on:click="certificazionePagamenti(1)" type="button"><i class="fal fa-file-pdf"></i> Pagamenti nella stagione corrente</button>
                                    <button class="dropdown-item" v-on:click="certificazionePagamenti(0,new Date().getFullYear())" type="button"><i class="fal fa-file-pdf"></i> Pagameneti nel {{new Date().getFullYear()}}</button>
                                    <button class="dropdown-item" v-on:click="certificazionePagamenti(0,new Date().getFullYear() - 1)" type="button"><i class="fal fa-file-pdf"></i> Pagameneti nel {{new Date().getFullYear() - 1}}</button>
                                    <button class="dropdown-item" v-on:click="certificazionePagamenti(0,new Date().getFullYear() - 2)" type="button"><i class="fal fa-file-pdf"></i> Pagameneti nel {{new Date().getFullYear() - 2}}</button>
                                </div>
                            </div>
                            -->

                        </form>
                    </div>
                    <div class="isprint-grid-modal-container" >    
                        <table class="isprint-grid-modal shadow" >
                            <thead class="isprint-grid-modal-thead" >
                            <!--<isort v-bind:ordinamento.sync="ordinamento" :label="'Cognome e nome'" :campo="'COGNOME'" ></isort>-->
                                <tr style="left: 0px;background:#69d0ff"  >
                                    <th  style="width:35%"><span>Atleta</span></th>
                                    <th  style="width:10%"><span >Data nascita</span></th>
                                    <th  style="width:10%"><span >Tessera</span></th>
                                    <th  style="width:15%"><span>Scad. Tesseramento</span></th>                                    
                                    <th  style="width:30%"><span >Azioni</span></th>
                                </tr>
                            </thead>
                            <tbody class="isprint-grid-modal-tbody" style="font-size:14px !important">
                                <tr style="left: 0px;height:40px"  v-for="atleta in paginatedListAtleti.arr" v-bind:key="atleta.ID_ATLETA">
                                    <td  style="width:35%"><span>
                                        <i style="font-size:24px" v-bind:class="{'color-maschio':atleta.SESSO == 1,'color-femmina':atleta.SESSO == 2}" class="fa-fw fal fa-user"></i>                                        
                                        {{ atleta.COGNOME }} {{ atleta.NOME }}</span></td>
                                    <td  style="width:10%"><span >{{ filters.formatDate(atleta.DATA_NASCITA) }}</span></td>
                                    <td  style="width:10%"><span >{{ atleta.TESSERA}}</span></td>
                                    <td  style="width:15%"><span>{{filters.formatDate(atleta.DATA_SCADENZA_TESSERAMENTO)}}</span></td>                                    
                                    <td  style="width:30%">
                                        <button class="btn btn-grid btn-warning float-right" v-on:click="modificaAtleta(atleta)"><i class="fal fa-pencil"></i> Modifica</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>   
                        <div class="row">
                            <div class="col-md-9">
                                <pagine v-model="searchAtleti.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedListAtleti.count"  :numpagine="searchAtleti.PAGINATION.LENGTH"></pagine>
                            </div>                      
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="popUpAtleta" data-backdrop="true" role="dialog"  aria-modal="true" >
        <div class="modal-dialog" style="min-width:1100px;"  >
            <div class="modal-content" >
                <div class="modal-header modal-header-info" >
                    <h4 style="color:#fff" class="modal-title">Atleta</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span>&times;</span>
                    </button>
                </div>
                <div class="modal-body">   
                    <PersonaFisica  v-model="atletaSelezionato.personaFisica"></PersonaFisica>                                     
                    <div class="row w-100" style="border-top:1px solid #ddd;margin-top:20px;padding-top:20px">
                        <div class="form-group col-md-4" style="margin-bottom:.50rem">
                            <label >Numero Tessera <i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
                            <input autocomplete="off" type="text" class="form-control form-control-sm " placeholder="Numero tessera" v-model="atletaSelezionato.TESSERA">
                        </div> 
                        <div class="form-group col-md-4" style="margin-bottom:.50rem">
                            <label >Data scadenza tesseramento <i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
                            <DatePicker :dim="'sm'" v-model="atletaSelezionato.DATA_SCADENZA_TESSERAMENTO"></DatePicker>
                        </div> 
                        <div class="form-group col-md-4" style="margin-bottom:.50rem">
                            <label >Tipo tesseramento <i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
                            <select class="form-control form-control-sm" v-model="atletaSelezionato.FK_ID_TIPO_QUALIFICA">
                                <option value="1">Atleta</option>
                                <option value="4">Tecnico/dirigente</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button data-dismiss="modal" class="btn btn-danger float-right"><i class="fal fa-times"></i> Annulla</button>
                    <button class="btn btn-success float-right" v-on:click="salvaAtleta()"><i class="fal fa-check"></i> Salva</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="popUpUploadTesserati" data-backdrop="true" role="dialog"  aria-modal="true" >
        <div class="modal-dialog" style="min-width:576px;"  >
            <div class="modal-content" >
                <div class="modal-header modal-header-info" >
                    <h4 style="color:#fff" class="modal-title">Caricamento massivo atleti - Societa {{ societaSelezionata.DENOMINAZIONE }}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span>&times;</span>
                    </button>
                </div>
                <div class="modal-body">   
                    <Upload @onerror="errorCaricamento($event)" @onsuccess="successCaricamento($event)" :args="args"></Upload>
                </div>
     
            </div>
        </div>
    </div>

    <div class="modal fade" id="popUpUploadSocieta" data-backdrop="true" role="dialog"  aria-modal="true" >
        <div class="modal-dialog" style="min-width:576px;"  >
            <div class="modal-content" >
                <div class="modal-header modal-header-info" >
                    <h4 style="color:#fff" class="modal-title">Caricamento massivo società</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span>&times;</span>
                    </button>
                </div>
                <div class="modal-body">   
                    <Upload @onerror="errorCaricamento($event)" @onsuccess="successCaricamentoSocieta($event)" :args="argsSocieta"></Upload>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="popUpUploadTesseratiAll" data-backdrop="true" role="dialog"  aria-modal="true" >
        <div class="modal-dialog" style="min-width:576px;"  >
            <div class="modal-content" >
                <div class="modal-header modal-header-info" >
                    <h4 style="color:#fff" class="modal-title">Caricamento massivo atleti - TUTTE LE SOCIETA</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span>&times;</span>
                    </button>
                </div>
                <div class="modal-body">   
                    <Upload @onerror="errorCaricamento($event)" @onsuccess="successCaricamentoAtletiAll($event)" :args="args"></Upload>
                </div>
     
            </div>
        </div>
    </div>


</div>
</template>
<script>


import Pagine from '@/components/utils/Pagine.vue'
import Select2 from '@/components/utils/Select2.vue'
import DatePicker from '@/components/utils/DatePicker.vue'
import Upload from '@/components/utils/Upload.vue'
import PersonaFisica from '@/components/common/PersonaFisica.vue'


export default {
    name:"Societa",
    components:{
        Pagine, 
        DatePicker,
        PersonaFisica,
        Upload,
        Select2
    },
    data:function(){
        return{
            filters:global.filters,
            args:{
                modalita:'CONFSPORT_uploadFlussoTesserati'
            },
            argsSocieta:{
                modalita:'CONFSPORT_uploadFlussoSocieta'
            },
            search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:50},
                ID_SOCIETA:{TYPE:'TEXT',VALUE:''}, 
                COD_AFF:{TYPE:'TEXT',VALUE:''}, 
                DENOMINAZIONE:{TYPE:'TEXT',VALUE:''}, 
                FK_ID_REGIONE:{TYPE:'COMBO',VALUE:''}, 
                FK_ID_PROVINCIA:{TYPE:'COMBO',VALUE:''}, 
                FK_ID_COMUNE:{TYPE:'COMBO',VALUE:''}, 
            },
            searchAtleti:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:12},
                COGNOME:{TYPE:'TEXT',VALUE:''}, 
                NOME:{TYPE:'TEXT',VALUE:''}, 
            },
            arraySocieta:new Array(),
            arrayAtleti:new Array(),
            societaSelezionata:{personaGiuridica:{}},
            atletaSelezionato:{personaFisica:{}}
        }
    },
    methods: {        
        nuovaSocieta : function(){
            this.societaSelezionata = {ID_SOCIETA:0,personaGiuridica:{}};
            utils.openModal("popUpSocieta");
        },
        modificaSocieta : function(societa){
            utils.get('/ge/societa',{ID_SOCIETA:societa.ID_SOCIETA}, (response) => {
                this.societaSelezionata = utils.clone(response.data);
                utils.openModal("popUpSocieta");
            }); 
        },
        salvaSocieta : function(){
            const metodo = this.societaSelezionata.ID_SOCIETA > 0 ? utils.put : utils.post;
            metodo("ge/societa",this.societaSelezionata,(response)=>{
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    utils.get('/ge/societa/list',{}, (response) => {
                        this.arraySocieta = response.data;
                    }); 
                }
            });
        },
        getAtleti : function(societa){
            utils.get('/ge/societa',{ID_SOCIETA:societa.ID_SOCIETA}, (response) => {
                this.societaSelezionata = response.data;
                utils.get('/ge/societa/atleti',{ID_SOCIETA:societa.ID_SOCIETA}, (response) => {
                    this.arrayAtleti = response.data;
                    utils.openModal("popUpAtleti");
                }); 
            }); 
        },
        nuovoAtleta : function(){
            this.atletaSelezionato = {ID_ATLETA:0,FK_ID_SOCIETA:this.societaSelezionata.ID_SOCIETA,personaFisica:{}};
            utils.openModal("popUpAtleta");
        },
        modificaAtleta : function(atleta){            
            utils.get('/ge/atleta',{ID_ATLETA:atleta.ID_ATLETA}, (response) => {
                this.atletaSelezionato = utils.clone(response.data);
                utils.openModal("popUpAtleta");
            });             
        },
        salvaAtleta : function(){
            const metodo = this.atletaSelezionato.ID_ATLETA > 0 ? utils.put : utils.post;
            metodo("ge/atleta",this.atletaSelezionato,(response)=>{
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    utils.closeModal("popUpAtleta");
                    utils.get('/ge/societa/atleti',{ID_SOCIETA:this.societaSelezionata.ID_SOCIETA}, (response) => {
                        this.arrayAtleti = response.data;
                    }); 
                }
            });
        },
        caricaFlusso : function(){
            this.args.FK_ID_SOCIETA = this.societaSelezionata.ID_SOCIETA;
            var modal = $('#popUpUploadTesserati');
            modal.modal('show');  
        },
        caricaFlussoSocieta : function(){
            var modal = $('#popUpUploadSocieta');
            modal.modal('show'); 
        },
        caricaFlussoAtletiAll : function(){
            this.args.FK_ID_SOCIETA = 0;
            var modal = $('#popUpUploadTesseratiAll');
            modal.modal('show');  
        },
        successCaricamento : function(response){
            var modal = $('#popUpUploadTesserati');
            modal.modal('hide'); 
            utils.alert.success(response.messaggio);
            
            //utils.get('/ge/societa/atleti',{ID_SOCIETA:this.societaSelezionata.ID_SOCIETA}, (response) => {
                this.arrayAtleti = response.data;
            //}); 
        },
        successCaricamentoSocieta : function(response){
            var modal = $('#popUpUploadSocieta');
            modal.modal('hide'); 
            utils.alert.success(response.messaggio);
            
            //utils.get('/ge/societa/atleti',{ID_SOCIETA:this.societaSelezionata.ID_SOCIETA}, (response) => {
            this.arraySocieta = response.data;
            //}); 
        },
        successCaricamentoAtletiAll : function(response){
            var modal = $('#popUpUploadTesseratiAll');
            modal.modal('hide'); 
            utils.alert.success(response.messaggio);
            utils.get('/ge/societa/list',{}, (response) => {
                this.arraySocieta = response.data;
            });  
        },
        errorCaricamento : function(response){
            utils.alert.warning(response.messaggio);
        },
        deleteAtleti : function(societa){
            utils.alert.confirm("<strong>ATTENZIONE</strong><br/>Sei sicuro di voler proseguire?<br/>L'operazione <strong>NON È REVERSIBILE</strong>",()=>{
                utils.delete('ge/societa/deleteatleti',{ID_SOCIETA:societa.ID_SOCIETA}, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        this.arraySocieta = response.data;
                    }
                });               
            },this.$root);
        }
    },
    computed : {
        paginatedList(){
            var arr = utils.inGrid(this.arraySocieta,this.search);
            return arr;
        },
        paginatedListAtleti(){
            var arr = utils.inGrid(this.arrayAtleti,this.searchAtleti);
            return arr;
        },
    },
    created : function(){
        utils.get('/ge/societa/list',{}, (response) => {
            this.arraySocieta = response.data;
        });        
    },
}
</script>