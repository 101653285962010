<template>
    <div>
        <ul class="nav nav-tabs" id="tabs" role="tablist" style="margin-top:25px">
           
            <li class="nav-item"  >
                <a class="nav-link isprint-tab active evento" data-toggle="tab" data-tab="generale" href="#generale"  role="tab" aria-controls="generale" aria-selected="false">
                    <i class="fal fa-cogs"></i> Generale
                </a>
            </li>
           
            <li class="nav-item">
                <a class="nav-link isprint-tab evento"  data-toggle="tab"  data-tab="individuali" href="#individuali"  role="tab" aria-controls="generale" aria-selected="true">
                    <i class="fal fa-user"></i> Individuali
                </a>
            </li>
            <li class="nav-item" >
                <a class="nav-link isprint-tab evento" data-toggle="tab" data-tab="squadre" href="#squadre"  role="tab" aria-controls="squadre" aria-selected="false">
                    <i class="fal fa-users"></i> Squadre
                </a>
            </li>

            <li class="nav-item" >
                <a class="nav-link isprint-tab evento" data-toggle="tab" data-tab="accompagnatori" href="#accompagnatori"  role="tab" aria-controls="accompagnatori" aria-selected="false">
                    <i class="fal fa-car"></i> Accompagnatori
                    <span class="badge badge-pill badge-primary" style="font-size: 12px;">{{ getCountAccompagnatori }}</span>
                </a>
            </li>

           
        </ul>
        <div class="tab-content card-tab" style="margin-top:-20px" >
            <div class="tab-pane active" id="generale" style="min-height:600px;height:100%;padding-top:30px;padding-left:10px;padding-right:10px" role="tabpanel">
                <h3 class="mt-4">Riepilogo iscrizioni e pagamenti</h3>
                <ul class="list-group">
                    <li class="list-group-item" style="font-size:16px;padding:8px">
                        TOTALE PRESENZE INDIVIDUALI <div class="float-right">{{filters.formattaImportoInt(riepilogo.TOT_IND)}}</div>
                    </li>
                    <li class="list-group-item" style="font-size:16px;padding:8px">
                        TOTALE PRESENZE SQUADRA <div class="float-right">{{filters.formattaImportoInt(riepilogo.TOT_SQU)}}</div>
                    </li>                               
                    <li class="list-group-item" style="font-size:16px;padding:8px">
                        PAGATO IN PRECEDENZA: QUOTE <div class="float-right">€ {{filters.formattaImporto(riepilogo.IMPORTO_PAGATO_QUOTE)}}</div>
                    </li>
                    <li class="list-group-item" style="font-size:16px;padding:8px">
                        PAGATO IN PRECEDENZA: MORA <div class="float-right">€ {{filters.formattaImporto(riepilogo.IMPORTO_PAGATO_MORA)}}</div>
                    </li>
                    <li class="list-group-item" style="font-size:16px;padding:8px">
                        PAGATO IN PRECEDENZA: PENALI <div class="float-right">€ {{filters.formattaImporto(riepilogo.IMPORTO_PAGATO_PENALI)}}</div>
                    </li>
                    <li class="list-group-item" style="font-size:16px;font-weight: bold;padding-left:8px;background:#ddd">
                        PAGATO IN PRECEDENZA: TOTALE  <div class="float-right">€ {{filters.formattaImporto(riepilogo.IMPORTO_PAGATO)}}</div>
                    </li>         
                    <li class="list-group-item" style="font-size:16px;padding:8px">
                        DA PAGARE: QUOTE<div class="float-right">€ {{filters.formattaImporto(riepilogo.IMPORTO_QUOTE_GARA)}}</div>
                    </li>
                    <li class="list-group-item" style="font-size:16px;padding:8px">
                        DA PAGARE: PENALI <div class="float-right">€ {{filters.formattaImporto(0)}}</div>
                    </li>
                    <li class="list-group-item" style="font-size:16px;padding:8px">
                        DA PAGARE: MORA <div class="float-right">€ {{filters.formattaImporto(riepilogo.IMPORTO_MORA)}}</div>
                    </li>
                    <li class="list-group-item" style="font-size:16px;font-weight:bold;padding-left:8px;background:#ddd">
                        DA PAGARE: TOTALE
                        <button v-show="$root.utente.FK_ID_LIVELLO != 1" class="btn btn-success"  v-on:click="generaOrdineIscrizioni()"><i class="fal fa-euro"></i> Genera ordine ed effettua il pagamento</button>
                        <!-- 
                        <span class="badge badge-danger">A causa di un problema tecnico col provider che gestisce le transazioni con carta di credito, vi preghiamo di voler effettuare il pagamento delle quote gara con bonifico </span>-->
                        <div class="float-right">€ {{filters.formattaImporto(riepilogo.IMPORTO_DA_PAGARE)}}</div>
                    </li>
                    <li class="list-group-item" style="font-size:16px;font-weight:bold">
                        <b>TOTALE </b>
                        <div class="float-right">€ {{filters.formattaImporto(riepilogo.IMPORTO_TOTALE)}}</div>
                    </li>
                    
                    
                </ul>
            </div>
            <div class="tab-pane" id="individuali" style="min-height:600px;height:100%;padding-top:30px;padding-left:10px;padding-right:10px" role="tabpanel">          
                <div class="kt-portlet__head">
                    <form class="isprint-filter" autocomplete="off">                
                        <div class="form-group ">
                            <label>Cognome</label><br/>
                            <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Cognome" type="text" style="width:180px" v-model="searchIndividuale.COGNOME.VALUE">
                        </div>
                        <div class="form-group " >
                            <label>Nome</label><br/>
                            <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Nome" type="text" style="width:180px" v-model="searchIndividuale.NOME.VALUE">
                        </div> 
                        <div class="form-group " v-show="$root.utente.FK_ID_LIVELLO == 1" >
                            <label>Società</label><br/>
                            <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Denominazione" type="text" style="width:180px" v-model="searchIndividuale.DENOMINAZIONE.VALUE">
                        </div> 
                        <!-- 
                        <button class="btn btn-success float-right btn-sm"  style="margin-top:26px"><i class="fal fa-plus"></i> Nuovo evento</button>
                        -->
                    </form>
                </div>
                <div class="isprint-grid-container" >    
                    <table class="isprint-grid shadow" >
                        <thead class="isprint-grid-thead" >
                            <tr style="left: 0px;background:#f4b30d;color:#fff">
                                <th style="width:30%"><span>Atleta</span></th>
                                <th style="width:20%"><span>Societa</span></th>
                                <th style="width:10%"><span >Anno nascita</span></th>
                                <th style="width:20%"><span >Categoria</span></th>
                                <th style="width:20%"><span >Gare</span></th>
                            </tr>
                        </thead>
                        <tbody class="isprint-grid-tbody" style="font-size:14px !important">
                            <tr  style="left: 0px;height:50px;cursor:pointer" v-on:click="selezionaAtleta(atleta)"  v-for="atleta in paginatedListInd.arr"  v-bind:key="atleta.ID_ATLETA" v-bind:class="{'td-ns':atleta.CURRENT == 1}" >
                                <td  style="width:30%" >
                                    <span>
                                    <i style="font-size:24px" v-bind:class="{'color-maschio':atleta.SESSO == 1,'color-femmina':atleta.SESSO == 2}" class="fa-fw fal fa-user"></i>                                        
                                    {{ atleta.COGNOME }} {{ atleta.NOME }}</span>
                                </td>
                                <td  style="width:20%">
                                    <span v-show="$root.utente.FK_ID_LIVELLO == 1" ><strong>{{ atleta.DENOMINAZIONE }}</strong></span>
                                </td>                                
                                <td  style="width:10%">
                                    {{ atleta.ANNO_NASCITA }} 
                                </td>
                                <td  style="width:20%">
                                    {{ atleta.CATEGORIA }}
                                </td>
                                <td  style="width:20%">
                                    <div v-for="gara in atleta.PRESENZE_IND" v-bind:key="gara.ID_PRESENZA">
                                        <span class="badge" style="margin-bottom: 1px;" v-bind:class="{'badge-primary':gara.FLG_ASSENTE == 0,'badge-danger':gara.FLG_ASSENTE == 1}">{{ gara.DESC_GARA }} - <small v-show="evento.FK_ID_SPORT == 1">{{ formattaTempo(gara.TEMPO_ISCRIZIONE) }}</small></span>
                                    </div>
                                </td>                    
                            </tr>                            
                        </tbody>
                    </table>
                    <Pagine v-model="searchIndividuale.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedListInd.count"  :numpagine="searchIndividuale.PAGINATION.LENGTH"></Pagine>
                </div>                     
            </div>
            <div class="tab-pane" id="squadre" style="min-height:600px;height:100%;padding-top:30px;padding-left:10px;padding-right:10px" role="tabpanel">                
                <div class="kt-portlet__head">
                    <div class="isprint-filter" autocomplete="off">   
                        <div class="form-group " v-show="$root.utente.FK_ID_LIVELLO == 1" >
                            <label>Società</label><br/>
                            <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Denominazione" type="text" style="width:180px" v-model="searchSquadre.DENOMINAZIONE.VALUE">
                        </div> 
                        <!-- <Tempo v-model="tempoProva"></Tempo>-->
                        <button class="btn btn-success float-right btn-sm" v-on:click="nuovaSquadra()" style="margin-top:26px"><i class="fal fa-plus"></i> Nuova squadra</button>
                    </div>
                </div>
                <div class="isprint-grid-container" >    
                    <table class="isprint-grid shadow" >
                        <thead class="isprint-grid-thead" >
                            <tr style="left: 0px;background:#f4b30d;color:#fff">
                                <th style="width:20%"><span>Squadra</span></th>
                                <th style="width:20%"><span >Gara</span></th>
                                <th style="width:20%"><span >Categoria</span></th>
                                <th style="width:20%"><span >Stato</span></th>
                                <th style="width:20%"><span >Azioni</span></th>
                            </tr>
                        </thead>
                        <tbody class="isprint-grid-tbody" style="font-size:14px !important">
                            <tr  style="left: 0px;height:50px;cursor:pointer" v-on:click="selezionaSquadra($event,squadra)"  v-for="squadra in paginatedListSqu.arr"  v-bind:key="squadra.ID_PRESENZA" v-bind:class="{'td-ns':squadra.CURRENT == 1}">
                                <td  style="width:20%" >
                                    <span>
                                        <i style="font-size:24px"  class="fa-fw fal fa-users"></i>                                        
                                        {{ squadra.DENOMINAZIONE }}  - {{ filters.getProgressivo(squadra.PROGRESSIVO) }} <small v-show="evento.FK_ID_SPORT == 1"> | TEMPO: {{ formattaTempo(squadra.TEMPO_ISCRIZIONE)  }}</small>
                                    </span>
                                </td>
                                <td  style="width:20%">
                                    {{ squadra.DESC_GARA }} 
                                </td>
                                <td  style="width:20%">
                                    {{ squadra.DESC_CATEGORIA }} 
                                </td>
                                <td  style="width:20%">
                                    
                                </td>   
                                <td  style="width:20%">
                                    <button v-on:click="rimuoviPresenza(squadra)" v-show="squadra.FLG_PAGATO != 1" class="btn btn-danger btn-sm float-right"><i class="fal fa-trash"></i> Elimina</button>
                                    
                                    <button v-show="$root.utente.FK_ID_LIVELLO == 1 " class="btn btn-info float-right btn-sm" v-on:click="gestionePagamento(squadra)"><i class="fal fa-euro"></i> {{ squadra.FLG_PAGATO == 1 ? 'RIMUOVI PAGAMENTO' : 'SEGNA PAGATA' }}</button>
                                </td>                    
                            </tr>                            
                        </tbody>
                    </table>
                    <Pagine v-model="searchSquadre.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedListSqu.count"  :numpagine="searchSquadre.PAGINATION.LENGTH"></Pagine>
                </div>   
            </div>
            <div class="tab-pane" id="accompagnatori" style="min-height:600px;height:100%;padding-top:30px;padding-left:10px;padding-right:10px" role="tabpanel">
                <h3 class="mt-4">Accompagnatori</h3>
                <ul class="list-group">
                    <li class="list-group-item" style="font-size:16px;padding:8px" v-for="accompagnatore in arrayAccompagnatori" v-bind:key="accompagnatore.ID_ATLETA">
                        <div class="col-8">
                            {{ accompagnatore.COGNOME }} {{ accompagnatore.NOME }}
                        </div>
                        <div class="col-4 text-right" >
                            <ISwitch @toggle="setAccompagnatore(accompagnatore)" style="float:right" v-model="accompagnatore.SELEZIONATO"></ISwitch>
                        </div>                        
                    </li>
                </ul>
            </div>
        </div>

        <div class="modal fade" id="popUpSquadra" data-backdrop="true" role="dialog"  aria-modal="true" >
            <div class="modal-dialog" style="min-width:400px;"  >
                <div class="modal-content" >
                    <div class="modal-header modal-header-info" >
                        <h4 style="color:#fff" class="modal-title">Squadra</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span>&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">   
                        <div class="form-group col-md-12" style="margin-bottom:.50rem" v-show="$root.utente.FK_ID_LIVELLO == 1">
                            <label >Società</label>
                            <Select2  v-model="squadraSelezionata.FK_ID_SOCIETA" :w="189" :api="'ge/societa/select2?a=1'" ></Select2>
                        </div>
                        <div class="form-group col-md-12" style="margin-bottom:.50rem">
                            <label >Categoria</label>
                            <select class="form-control " v-model="squadraSelezionata.FK_ID_CATEGORIA">
                                <option v-for="categoria in evento.categorie" v-show="categoria.FK_ID_TIPO == 2" v-bind:key="categoria.ID_CATEGORIA" v-bind:value="categoria.ID_CATEGORIA">{{ categoria.DESCRIZIONE }}</option>
                            </select>
                        </div>
                        <div class="form-group col-md-12" style="margin-bottom:.50rem">
                            <label >Gara</label>
                            <select class="form-control " :disabled="!squadraSelezionata.FK_ID_CATEGORIA > 0" v-model="squadraSelezionata.FK_ID_GARA">
                                <option v-for="gara in getGareByCategoria"  v-bind:key="gara.ID_GARA" v-bind:value="gara.ID_GARA">{{ gara.DESCRIZIONE }}</option>
                            </select>
                        </div>
                        <div class="form-group col-md-12">
                            <label>Tempo</label><br>
                            <Tempo v-model="squadraSelezionata.TEMPO_ISCRIZIONE"></Tempo>

                            <!-- 
                            <input style="width:40px;float:left;margin-right:4px" maxlength="2" type="text" class="form-control" v-model="squadraSelezionata.CENTESIMI" placeholder="cc">
                            <input style="width:40px;float:left;margin-right:4px" maxlength="2" type="text" class="form-control" v-model="squadraSelezionata.SECONDI" placeholder="s">
                            <input style="width:40px;float:left;margin-right:4px" maxlength="2" type="text" class="form-control" v-model="squadraSelezionata.MINUTI" placeholder="m"> 
                        --> 
                        </div>
                        <br/>
                        <div class="form-group col-md-12" style="margin-bottom:.50rem" v-show="noteObbligatorie == 1">
                            <label >Note</label>
                            <textarea class="form-control" v-model="squadraSelezionata.NOTE_ISCR"></textarea>
                        </div>
                    </div>
                    <div class="modal-footer">   
                        <button data-dismiss="modal" class="btn btn-danger float-right"><i class="fal fa-times"></i> Annulla</button>
                        <button class="btn btn-success float-right" v-on:click="salvaSquadra()"><i class="fal fa-check"></i> Salva</button>
                    </div>       
                </div>
            </div>
        </div>

        <div class="sidenav"  id="mySidenav"  >
            <div class="modal-header modal-header-info" style="height:61px">    
                <h4 class="modal-title"><i class="fal fa-check-square"></i> 
                    <span v-show="currentPage == 1">Iscrizioni {{ atletaSelezionato.COGNOME }} {{ atletaSelezionato.NOME }}</span>
                    <span v-show="currentPage == 2">Composizione squadra</span>
                <button onclick="utils.closeNav()" type="button" class="close float-right" ><span aria-hidden="true">&times;</span></button></h4>
            </div>
            <div class="modal-body" >
                <ul class="list-group" style="margin-top:20px" v-if="currentPage == 1">
                    <li class="list-group-item" v-for="gara in arrayGareAtleta" v-bind:key="gara.ID_GARA" style="padding:6px">
                        <div class="float-left">{{ gara.DESCRIZIONE }} 
                            <small v-show="evento.FK_ID_SPORT == 1 && gara.SELEZIONATO == 1">
                                {{ gara.TEMPO_FORMATTATO }}
                            </small>
                        </div>
                        <button v-show="gara.SELEZIONATO == 0 && arrayPresenzeAtleta.length < maxGare" class="btn btn-success float-right btn-sm" v-on:click="iscriviAtleta(gara)">ISCRIVI</button>
                        <button v-show="(gara.SELEZIONATO == 1 && gara.FLG_PAGATO == 0) || (gara.SELEZIONATO == 1 && $root.utente.FK_ID_LIVELLO == 1)" class="btn btn-danger float-right btn-sm" v-on:click="rimuoviPresenza(gara)">RIMUOVI</button>
                        <button v-show="gara.SELEZIONATO == 1 && gara.FLG_PAGATO == 1 && gara.FLG_ASSENTE == 0 && $root.utente.FK_ID_LIVELLO != 1" class="btn btn-danger float-right btn-sm" v-on:click="segnalaAssenza(gara)">COMUNICA ASSENZA</button>
                        <button v-show="$root.utente.FK_ID_LIVELLO == 1 && gara.SELEZIONATO == 1" class="btn btn-info float-right btn-sm" v-on:click="gestionePagamento(gara)"><i class="fal fa-euro"></i> {{ gara.FLG_PAGATO == 1 ? 'RIMUOVI PAGAMENTO' : 'SEGNA PAGATA' }}</button>
                        <span v-show="gara.SELEZIONATO == 1 && gara.FLG_PAGATO == 1 && gara.FLG_ASSENTE == 1" class="badge badge-danger float-right"> Assenza comunicata</span>
                        <Tempo class="float-right" :dim="'sm'" v-model="gara.TEMPO_ISCRIZIONE"  v-show="gara.SELEZIONATO == 0 && evento.FK_ID_SPORT == 1 && arrayPresenzeAtleta.length < maxGare"></Tempo>
                        
                        <!-- 
                            <input style="width:40px;float:right" v-show="gara.SELEZIONATO == 0 && evento.FK_ID_SPORT == 1 && arrayPresenzeAtleta.length < maxGare" maxlength="2" type="text" class="form-control form-control-sm" v-model="gara.CENTESIMI" placeholder="cc">
                            <input style="width:40px;float:right" v-show="gara.SELEZIONATO == 0 && evento.FK_ID_SPORT == 1 && arrayPresenzeAtleta.length < maxGare" maxlength="2" type="text" class="form-control form-control-sm" v-model="gara.SECONDI" placeholder="s">
                            <input style="width:40px;float:right" v-show="gara.SELEZIONATO == 0 && evento.FK_ID_SPORT == 1 && arrayPresenzeAtleta.length < maxGare" maxlength="2" type="text" class="form-control form-control-sm" v-model="gara.MINUTI" placeholder="m">                                                                
                        -->
                    </li>
                </ul>
                <div v-if="currentPage == 2">
                    <h3>Aggiunti</h3>
                    <ul class="list-group" style="margin-top:20px" >
                        <li class="list-group-item list-group-item-success" v-for="atleta in squadraSelezionata.componenti" v-bind:key="atleta.ID_ATLETA" style="padding:6px;cursor:pointer" >
                            <span><i style="font-size:24px" v-bind:class="{'color-maschio':atleta.SESSO == 1,'color-femmina':atleta.SESSO == 2}" class="fa-fw fal fa-user"></i> {{ atleta.COGNOME }} {{ atleta.NOME }} <small>{{ filters.formatDateYear(atleta.DATA_NASCITA) }}</small></span>
                            <button class="btn btn-sm btn-danger float-right" v-on:click="rimuoviComponente(atleta)"><i class="fal fa-trash"></i></button>
                        </li>
                    </ul>
                    <h3 style="margin-top:20px">Seleziona</h3>
                    <div class="row">
                        <div class="col-12">
                            <form class="isprint-filter" autocomplete="off">                
                                <div class="form-group ">
                                    <label>Cognome</label><br/>
                                    <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Cognome" type="text" style="width:180px" v-model="searchCompo.COGNOME.VALUE">
                                </div>
                                <div class="form-group " >
                                    <label>Nome</label><br/>
                                    <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Nome" type="text" style="width:180px" v-model="searchCompo.NOME.VALUE">
                                </div> 
                            </form>                            
                        </div>
                    </div>
                    <div class="col-12">
                        <ul class="list-group" style="margin-top:20px" >
                            <li class="list-group-item" v-for="atleta in paginatedListCompo.arr" v-bind:key="atleta.ID_ATLETA" style="padding:6px;cursor:pointer" v-on:click="addComponente(atleta)">
                                <span><i style="font-size:24px" v-bind:class="{'color-maschio':atleta.SESSO == 1,'color-femmina':atleta.SESSO == 2}" class="fa-fw fal fa-user"></i> {{ atleta.COGNOME }} {{ atleta.NOME }} <small>{{ filters.formatDateYear(atleta.DATA_NASCITA) }}</small></span>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12">
                        <Pagine v-model="searchCompo.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedListCompo.count"  :numpagine="searchCompo.PAGINATION.LENGTH"></Pagine>
                    </div>
                </div>
            </div>
        </div>       
    </div>

    
    <div class="modal fade" id="popUpNote" data-backdrop="true" role="dialog"  aria-modal="true" >
        <div class="modal-dialog" style="min-width:300px;"  >
            <div class="modal-content" >
                <div class="modal-header modal-header-info" >
                    <h4 style="color:#fff" class="modal-title">Inserisci una nota</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span>&times;</span>
                    </button>
                </div>
                <div class="modal-body">                    
                    <div class="form-group ">
                        <label>Note</label>
                        <textarea type="text" class="form-control" v-model="presenzaSelezionata.NOTE_ISCR"></textarea>
                    </div>                    
                </div>
                <div class="modal-footer">
                    <button data-dismiss="modal" class="btn btn-danger float-right"><i class="fal fa-times"></i> Annulla</button>
                    <button class="btn btn-success float-right" v-on:click="doIscriviAtleta()"><i class="fal fa-check"></i> Iscrivi</button>
                </div>
            </div>
        </div>
    </div>


</template>
<script>


import Pagine from '@/components/utils/Pagine.vue'
import ISwitch from '@/components/utils/ISwitch.vue'
import Tempo from '@/components/ns/Tempo.vue'
import Select2 from '@/components/utils/Select2.vue'



export default {
    name:"Iscrizioni",
    components:{
        Pagine, 
        Tempo,
        Select2,
        ISwitch
    },
    data:function(){
        return{
            filters:global.filters,
            searchIndividuale:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:50},
                NOME:{TYPE:'TEXT',VALUE:''}, 
                COGNOME:{TYPE:'TEXT',VALUE:''}, 
                DENOMINAZIONE:{TYPE:'TEXT',VALUE:''}, 
            },
            searchCompo:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:10},
                NOME:{TYPE:'TEXT',VALUE:''}, 
                COGNOME:{TYPE:'TEXT',VALUE:''}, 
            },
            searchSquadre:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:50},
                DENOMINAZIONE:{TYPE:'TEXT',VALUE:''}, 
            },
            noteObbligatorie: 0,
            presenzaSelezionata:{},
            arrayPresenzeInd : new Array(),
            arrayPresenzeSqu : new Array(),
            arrayGareAtleta : new Array(),
            arrayPresenzeAtleta : new Array(),
            squadraSelezionata : {atletiIscrivibili:new Array()},
            atletaSelezionato:{},
            evento:{gareSqu:new Array()},
            maxGare:0,
            tempoProva:2450,
            riepilogo:{},
            currentPage:0,
            arrayAccompagnatori : new Array(),
        }
    },
    methods: {
        getPresenze : function(){
            
            utils.get('/ge/evento/presenze',{ID_EVENTO:this.evento.ID_EVENTO}, (response) => {
                this.arrayPresenzeInd = response.data.ATLETI_ISCRIVIBILI;
                this.arrayAccompagnatori = response.data.ACCOMPAGNATORI;
                if (this.$root.utente.FK_ID_LIVELLO == 1){
                    this.arrayPresenzeSqu = new Array();
                    this.riepilogo = response.data.RIEPILOGO[0];
                    for (var i = 0 ; i < response.data.RIEPILOGO.length ; i++){
                        for (var j = 0 ; j < response.data.RIEPILOGO[i].ARRAY_PRESENZE_SQU.length ; j++){
                            let squ = response.data.RIEPILOGO[i].ARRAY_PRESENZE_SQU[j];
                            this.arrayPresenzeSqu.push(squ);
                        }
                        if (i > 0){
                            this.riepilogo.TOT_IND += parseFloat(response.data.RIEPILOGO[i].TOT_IND);
                            this.riepilogo.TOT_SQU += parseFloat(response.data.RIEPILOGO[i].TOT_SQU);
                            this.riepilogo.IMPORTI_CON_ORDINE_PAGATI += parseFloat(response.data.RIEPILOGO[i].IMPORTI_CON_ORDINE_PAGATI);
                            this.riepilogo.IMPORTI_MORA_PAGATI += parseFloat(response.data.RIEPILOGO[i].IMPORTI_MORA_PAGATI);
                            this.riepilogo.IMPORTO_QUOTE_GARA += parseFloat(response.data.RIEPILOGO[i].IMPORTO_QUOTE_GARA);
                            this.riepilogo.IMPORTO_MORA += parseFloat(response.data.RIEPILOGO[i].IMPORTO_MORA);
                            this.riepilogo.IMPORTO_TOTALE += parseFloat(response.data.RIEPILOGO[i].IMPORTO_TOTALE);
                        }
                    }     
                }
                else{
                    this.arrayPresenzeSqu = response.data.RIEPILOGO[0].ARRAY_PRESENZE_SQU;
                    this.riepilogo = response.data.RIEPILOGO[0];
                }
                if (this.riepilogo != undefined){
                    for (var i = 0 ; i < this.arrayPresenzeInd.length ; i++) {
                        this.arrayPresenzeInd[i].ISCRITTO = 0;
                        this.arrayPresenzeInd[i].PRESENZE_IND = utils.array.select(this.riepilogo.ARRAY_PRESENZE_IND,"FK_ID_ATLETA",this.arrayPresenzeInd[i].ID_ATLETA);
                        if (this.arrayPresenzeInd[i].PRESENZE_IND.length > 0){
                            this.arrayPresenzeInd[i].ISCRITTO = 1;
                        }
                    }   
                }                 
            });
        },
       
        selezionaAtleta : function(atleta){
            for (var i = 0 ; i < this.arrayPresenzeInd.length ; i++) {
                this.arrayPresenzeInd[i].CURRENT = 0;
            }
            this.atletaSelezionato = utils.clone(atleta);
            utils.get('/ge/evento/atleta/gare',{ID_EVENTO:this.evento.ID_EVENTO,ID_ATLETA:atleta.ID_ATLETA}, (response) => {
                this.arrayGareAtleta = response.data.GARE;
                this.arrayPresenzeAtleta = response.data.RIEPILOGO;
                this.setPresenzeAtleta();
                atleta.CURRENT = 1;
            });
            global.utils.openNav();
        },
        setPresenzeAtleta:function(){
            for (var i = 0 ; i < this.arrayGareAtleta.length ; i++){
                this.arrayGareAtleta[i].SELEZIONATO = 0;
                this.arrayGareAtleta[i].MINUTI = 0;
                this.arrayGareAtleta[i].SECONDI = 0;
                this.arrayGareAtleta[i].CENTESIMI = 0;
                var check = utils.array.select(this.arrayPresenzeAtleta,"FK_ID_GARA",this.arrayGareAtleta[i].ID_GARA,1)[0];
                if (check != undefined){
                    this.arrayGareAtleta[i].SELEZIONATO = 1;
                    this.arrayGareAtleta[i].ID_PRESENZA = check.ID_PRESENZA;
                    this.arrayGareAtleta[i].FLG_PAGATO = check.FLG_PAGATO;
                    this.arrayGareAtleta[i].FLG_ASSENTE = check.FLG_ASSENTE;
                    this.arrayGareAtleta[i].TEMPO_FORMATTATO = this.formattaTempo(check.TEMPO_ISCRIZIONE);
                }
            }
        },
        formattaTempo : function(tempo){
            var minuti = parseInt(tempo/6000);
            var secondi = Math.floor((tempo - (minuti *  6000))/100);
            var centesimi = (tempo - (minuti * 6000) - (secondi * 100));
            var tempoFormattato = (minuti > 0 ? minuti  + "'" : '') + (secondi < 10 ? '0'+secondi : secondi) + "''" + (centesimi < 10 ? '0'+centesimi : centesimi);
            return tempoFormattato;
        },
        iscriviAtleta : function(gara){
            this.presenzaSelezionata = {FK_ID_EVENTO:this.evento.ID_EVENTO,FK_ID_GARA:gara.ID_GARA,FK_ID_ATLETA:this.atletaSelezionato.ID_ATLETA,FK_ID_TIPO:1,TEMPO_ISCRIZIONE:gara.TEMPO_ISCRIZIONE,NOTE_ISCR:""};
            if (this.noteObbligatorie == 1){
                //MOSTRA POPUP NOTE
                utils.openModal("popUpNote");
            }
            else{
               this.doIscriviAtleta();
            }
        },
        doIscriviAtleta(){
            //if (this.noteObbligatorie == 0 || (this.noteObbligatorie == 1 && this.presenzaSelezionata.NOTE_ISCR != "")){
                utils.post('/ge/presenza',this.presenzaSelezionata, (response) => {
                    if (response.esito == 0){
                        if (this.noteObbligatorie == 1){
                            utils.closeModal("popUpNote");
                        }
                        utils.alert.success(response.messaggio);
                        this.arrayGareAtleta = response.data.GARE;
                        this.arrayPresenzeAtleta = response.data.RIEPILOGO;
                        this.setPresenzeAtleta();
                        this.getPresenze();
                    }
                });               
          //  }
           // else{
           //     utils.alert.warning("Attenzione, è obbligatorio valorizzare il campo NOTE");
          //  }
        },
        rimuoviPresenza : function(presenza){
            utils.alert.confirm("Sei sicuro di voler proseguire?",()=>{
                utils.delete('/ge/presenza',{ID_PRESENZA:presenza.ID_PRESENZA}, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        if (presenza.FK_ID_TIPO == 1){
                            this.arrayGareAtleta = response.data.GARE;
                            this.arrayPresenzeAtleta = response.data.RIEPILOGO;
                            this.setPresenzeAtleta();
                            this.getPresenze();
                        }
                        else{
                            this.arrayPresenzeSqu = response.data.RIEPILOGO[0].ARRAY_PRESENZE_SQU;
                        }                    
                    }
                });                
            },this.$root);
        },
        segnalaAssenza : function(presenza){
            utils.put('/ge/presenza/assenza',{ID_PRESENZA:presenza.ID_PRESENZA}, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    presenza.FLG_ASSENTE = 1;                  
                }
            });
        },
        nuovaSquadra : function(){
            this.squadraSelezionata = {ID_PRESENZA:0,FK_ID_EVENTO:this.evento.ID_EVENTO,TEMPO_ISCRIZIONE:0,atletiIscrivibili:new Array(),NOTE_ISCR:''};
            utils.openModal("popUpSquadra");
        },
        salvaSquadra : function(){
           // if (this.noteObbligatorie == 0 || (this.noteObbligatorie == 1 && this.squadraSelezionata.NOTE_ISCR != "")){
                utils.post('/ge/presenza',this.squadraSelezionata, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        this.arrayPresenzeSqu = response.data.RIEPILOGO[0].ARRAY_PRESENZE_SQU;
                        utils.closeModal("popUpSquadra");
                    }
                });
        //    }
        //    else{
        //        utils.alert.warning("Attenzione, è obbligatorio valorizzare il campo NOTE");
        //    }
        },
        generaOrdineIscrizioni : function(){
            utils.post('/ge/ordine/iscrizione',{ID_EVENTO:this.evento.ID_EVENTO}, (response) => {
                if (response.esito == 0){                    
                    if (response.data.IMPORTO > 0){
                        utils.alert.success(response.messaggio);
                        this.$root.ordine = {ID_ORDINE:response.data.ID_ORDINE};
                        global.router.push("Stripe");
                    }
                    else{
                        utils.alert.success(response.messaggio + "\nL'ordine generato risulta pagato in quanto erano presenti dei crediti");
                        global.router.push("Dashboard");
                    }                    
                }
            });  
        },
        selezionaSquadra : function(event,squadra){      
            if ($(event.target).is("button") || $(event.target).is("i")) {
                return;
            }     
            for (var i = 0 ; i < this.arrayPresenzeSqu.length ; i++) {
                this.arrayPresenzeSqu[i].CURRENT = 0;
            }
            squadra.CURRENT = 1;
            utils.get('/ge/presenza/squ',{ID_PRESENZA:squadra.ID_PRESENZA}, (response) => {
                if (response.esito == 0){
                    this.squadraSelezionata = utils.clone(response.data);
                    global.utils.openNav();
                }
            });              
        },
        gestionePagamento : function(presenza){
            presenza.FLG_PAGATO = presenza.FLG_PAGATO == 1 ? 0 : 1;
            utils.put('/ge/presenza/pagamento',presenza, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                }
            });
        },
        addComponente : function(atleta){
            utils.post('/ge/presenza/squ',{ID_PRESENZA:this.squadraSelezionata.ID_PRESENZA,ID_ATLETA:atleta.ID_ATLETA}, (response) => {
                if (response.esito == 0){
                    this.squadraSelezionata = utils.clone(response.data);
                }
            }); 
        },
        rimuoviComponente : function(atleta){
            utils.delete('/ge/presenza/squ',{ID_PRESENZA:this.squadraSelezionata.ID_PRESENZA,ID_ATLETA:atleta.ID_ATLETA}, (response) => {
                if (response.esito == 0){
                    this.squadraSelezionata = utils.clone(response.data);
                }
            });
        },
        getAccompagnatori : function(){
            utils.get('/ge/evento/accompagnatori',{ID_EVENTO:this.evento.ID_EVENTO}, (response) => {
                if (response.esito == 0){
                    this.arrayAccompagnatori = response.data;
                }
            }); 
        },
        setAccompagnatore : function(accompagnatore){
            utils.wait(()=>{
                console.log(accompagnatore.SELEZIONATO);
                const metodo = accompagnatore.SELEZIONATO == 1 ? utils.post : utils.delete;
                metodo('/ge/evento/accompagnatore',{FK_ID_EVENTO:this.evento.ID_EVENTO,FK_ID_ATLETA: accompagnatore.ID_ATLETA}, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                    }
                });
            });
        }
    },
    computed : {
        paginatedListInd(){
            var arr = utils.inGrid(this.arrayPresenzeInd,this.searchIndividuale,"-ISCRITTO");
            return arr;
        },
        paginatedListSqu(){
            var arr = utils.inGrid(this.arrayPresenzeSqu,this.searchSquadre);
            return arr;
        },
        paginatedListCompo(){
            var arr = utils.inGrid(this.squadraSelezionata.atletiIscrivibili,this.searchCompo);
            return arr;
        },
        getGareByCategoria(){
            var arr = utils.array.select(this.evento.gareSqu,"FK_ID_CATEGORIA",this.squadraSelezionata.FK_ID_CATEGORIA);
            return arr;
        },
        getCountAccompagnatori(){
            var arr = utils.array.select(this.arrayAccompagnatori,"SELEZIONATO",1);
            return arr.length;
        }
    },
    created : function(){
        setTimeout(() => {
            $('a[data-toggle="tab"]').on('shown.bs.tab', (e) => {
                var tab = $(e.target).data("tab");
                utils.closeNav();
                switch(tab){
                    case 'generale':
                        this.currentPage = 0;
                        this.getPresenze();
                        break;
                    case 'individuali':
                        this.currentPage = 1;
                        this.getPresenze();
                        break;
                    case 'squadre':
                        this.currentPage = 2;
                        this.getPresenze();
                        break;  
                    case 'accompagnatori':
                        this.currentPage = 3;
                        this.getAccompagnatori();
                        break;  
                  }
            });            
        },300);
        var evento = utils.clone(this.$root.eventoSelezionato);
        utils.get('/ge/evento',{ID_EVENTO:evento.ID_EVENTO}, (response) => {
            this.evento = response.data;
            this.maxGare = parseInt(utils.array.select(this.evento.opzioni,"FK_ID_OPZIONE",1,1)[0]['VALORE']);
            this.noteObbligatorie = parseInt(utils.array.select(this.evento.opzioni,"FK_ID_OPZIONE",36,1)[0]['VALORE']);
            this.getPresenze();
        });
    },
    beforeCreate : function(){
        setTimeout(() => {
            global.utils.deltaGriglia = 100;   
            global.utils.resizeContent();     
        }, 300);
    },
    unmounted : function(){
       global.utils.deltaGriglia = 0;
    },
}
</script>