<template>
    <div>
        <div class="row" style="margin-top:40px">
            <div class="col-3 text-center">
                <button class="btn btn-warning btn-lg fa-4x" style="width:340px;font-size: 3em;" v-on:click="openModulo('Ordini')">Ordini</button>        
            </div>
            <div class="col-3 text-center">
                <button class="btn btn-info btn-lg fa-4x" style="width:340px;font-size: 3em;" v-on:click="openModulo('Societa')">Societa</button>
            </div>
            <div class="col-3 text-center">
                <button class="btn btn-primary btn-lg fa-4x" style="width:340px;font-size: 3em;" v-on:click="openModulo('Gare')">Gare</button>
            </div>
            <div class="col-3 text-center">
                <button class="btn btn-danger btn-lg fa-4x" style="width:340px;font-size: 3em;" v-on:click="openModulo('Categorie')">Categorie</button>        
            </div>
            <div class="col-3 text-center mt-4">
                <button class="btn btn-success btn-lg fa-4x" style="width:340px;font-size: 3em;" v-on:click="openModulo('Portafoglio')">Portafoglio</button>        
            </div>
            <div class="col-3 text-center mt-4">
                <button class="btn btn-secondary btn-lg fa-4x" style="width:340px;font-size: 3em;" v-on:click="openModulo('Campionati')">Campionati</button>        
            </div>
            

            

            
        </div>
        
        
        

    </div>
</template>

<script>
    export default {
        name:"Amministrazione",
        components: {
        
        },
        data:function(){
        return{
            filters:global.filters
        }
        },
        methods:{
            openModulo : function(modulo){
                global.router.push(modulo);
            },
            hasFunzionalita : function(id){
                return true;
                for (var i = 0 ; i < this.$root.utente.funzionalita.length ; i++){
                    if (this.$root.utente.funzionalita[i].ID_FUNZIONALITA == id){
                        return true;
                    }
                }
                return false;
            }
        },
        computed:{
            
        },
        created: function () {
           
        },
    }
    </script>