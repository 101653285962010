<template>
    <div>
        <div class="row" style="margin-top:40px">
            <!-- 
            <div class="col-md-3">
                <div v-on:click="clickSport(1)" style="width:140px;height:140px;border-radius:24px;background:#69d0ff;margin:0 auto" class="showbox scale">
                    <img style="margin-left:5px;margin-top:5px;max-height:110px" class="img-responsive"  src="~@/assets/img/sport/NUOTO.png">
                </div>
                <h2 style="font-size:22px;font-weight:bold;;margin-top:6px;width:100%;text-align:center;">Nuoto</h2>
            </div>-->

            <div class="col-3" style="height: 140px;"   >
                <div class="card shadow lift-panel " v-on:click="clickSport(1)" style="margin-top:20px;background: #69d0ff !important;">
                    <div class="card-body" >
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="font-weight-bold mb-1 h3" style="color:#fff !important">Nuoto</div>  
                                <div class="h6 mb-0  " style="color:#fff" ></div> 
                            </div>
                            <div class="col-auto">
                                <img style="margin-left:5px;margin-top:5px;max-height:110px" class="img-responsive"  src="~@/assets/img/sport/NUOTO.png">
                            </div>
                        </div>
                    </div>
                </div>        
            </div>

            
            <div class="col-3" style="height: 140px;"   >
                <div class="card shadow lift-panel " v-on:click="clickSport(2)" style="margin-top:20px;background: #D44C48 !important;">
                    <div class="card-body" >
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="font-weight-bold mb-1 h3" style="color:#fff !important">Ginnastica artistica F</div>  
                                <div class="h6 mb-0  " style="color:#fff" ></div> 
                            </div>
                            <div class="col-auto">
                                <img style="margin-left:5px;margin-top:5px;max-height:110px" class="img-responsive"  src="~@/assets/img/sport/ARTISTICA_F.png">
                            </div>
                        </div>
                    </div>
                </div>        
            </div>

            <!-- 
            <div class="col-md-3">
                <div v-on:click="clickSport(2)" style="width:140px;height:140px;border-radius:24px;background:#f4b30d;margin:0 auto" class="showbox scale">
                    <img style="margin-left:5px;margin-top:5px;max-height:110px" class="img-responsive"  src="~@/assets/img/sport/ARTISTICA_F.png">
                </div>
                <h2 style="font-size:22px;font-weight:bold;;margin-top:6px;width:100%;text-align:center;">Ginnastica Artistica F</h2>
            </div>
            -->

            <div class="col-3" style="height: 140px;"   >
                <div class="card shadow lift-panel " v-on:click="clickSport(3)" style="margin-top:20px;background: #286090 !important;">
                    <div class="card-body" >
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="font-weight-bold mb-1 h3" style="color:#fff !important">Ginnastica artistica M</div>  
                                <div class="h6 mb-0  " style="color:#fff" ></div> 
                            </div>
                            <div class="col-auto">
                                <img style="margin-left:5px;margin-top:5px;max-height:110px" class="img-responsive"  src="~@/assets/img/sport/ARTISTICA_M.png">
                            </div>
                        </div>
                    </div>
                </div>        
            </div>

            <!-- 
            <div class="col-md-3">
                <div v-on:click="clickSport(3)" style="width:140px;height:140px;border-radius:24px;background:#286090;margin:0 auto" class="showbox scale">
                    <img style="margin-left:5px;margin-top:5px;max-height:110px" class="img-responsive"  src="~@/assets/img/sport/ARTISTICA_M.png">
                </div>
                <h2 style="font-size:22px;font-weight:bold;;margin-top:6px;width:100%;text-align:center;">Ginnastica Artistica M</h2>
            </div>-->

            
            <div class="col-3" style="height: 140px;"   >
                <div class="card shadow lift-panel " v-on:click="clickSport(5)" style="margin-top:20px;background: #f4b30d !important;">
                    <div class="card-body" >
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="font-weight-bold mb-1 h3" style="color:#fff !important">Ginnastica Ritmica</div>  
                                <div class="h6 mb-0  " style="color:#fff" ></div> 
                            </div>
                            <div class="col-auto">
                                <img style="margin-left:5px;margin-top:5px;max-height:110px" class="img-responsive"  src="~@/assets/img/sport/RITMICA.png">
                            </div>
                        </div>
                    </div>
                </div>        
            </div>

<!-- 
            <div class="col-md-3">
                <div v-on:click="clickSport(5)" style="width:140px;height:140px;border-radius:24px;background:#D44C48;margin:0 auto" class="showbox scale">
                    <img style="margin-left:5px;margin-top:5px;max-height:110px" class="img-responsive"  src="~@/assets/img/sport/RITMICA.png">
                </div>
                <h2 style="font-size:22px;font-weight:bold;;margin-top:6px;width:100%;text-align:center;">Ginnastica Ritmica</h2>
            </div>-->


            <div class="col-3" style="height: 140px;margin-top:80px"   >
                <div class="card shadow lift-panel " v-on:click="clickSport(4)" style="margin-top:20px;background: #5cb85c !important;">
                    <div class="card-body" >
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="font-weight-bold mb-1 h3" style="color:#fff !important">Nuoto Artistico</div>  
                                <div class="h6 mb-0  " style="color:#fff" ></div> 
                            </div>
                            <div class="col-auto">
                                <img style="margin-left:5px;margin-top:5px;max-height:110px" class="img-responsive"  src="~@/assets/img/sport/SINCRO.png">
                            </div>
                        </div>
                    </div>
                </div>        
            </div>

            <!--

            <div class="col-md-3" style="margin-top:40px">
                <div v-on:click="clickSport(4)" style="width:140px;height:140px;border-radius:24px;background:#5cb85c;margin:0 auto" class="showbox scale">
                    <img style="margin-left:5px;margin-top:5px;max-height:110px" class="img-responsive"  src="~@/assets/img/sport/SINCRO.png">
                </div>
                <h2 style="font-size:22px;font-weight:bold;;margin-top:6px;width:100%;text-align:center;">Nuoto Sincronizzato</h2>
            </div>-->



            <div class="col-3" style="height: 140px;margin-top:80px"   >
                <div class="card shadow lift-panel " v-on:click="clickSport(7)" style="margin-top:20px;background: #ff5e00 !important;">
                    <div class="card-body" >
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="font-weight-bold mb-1 h3" style="color:#fff !important">Basket</div>  
                                <div class="h6 mb-0  " style="color:#fff" ></div> 
                            </div>
                            <div class="col-auto">
                                <img style="margin-left:5px;margin-top:5px;max-height:110px" class="img-responsive"  src="~@/assets/img/sport/BASKET.png">
                            </div>
                        </div>
                    </div>
                </div>        
            </div>

            <!-- 
            <div class="col-md-3" style="margin-top:40px">
                <div v-on:click="clickSport(7)" style="width:140px;height:140px;border-radius:24px;background:#ff5e00;margin:0 auto" class="showbox scale">
                    <img style="margin-left:5px;margin-top:5px;max-height:110px" class="img-responsive"  src="~@/assets/img/sport/BASKET.png">
                </div>
                <h2 style="font-size:22px;font-weight:bold;;margin-top:6px;width:100%;text-align:center;">Basket</h2>
            </div>
            -->

            <div class="col-3" style="height: 140px;margin-top:80px"   >
                <div class="card shadow lift-panel " v-on:click="clickSport(8)" style="margin-top:20px;background: #4e73df !important;">
                    <div class="card-body" >
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="font-weight-bold mb-1 h3" style="color:#fff !important">Pattinaggio</div>  
                                <div class="h6 mb-0  " style="color:#fff" ></div> 
                            </div>
                            <div class="col-auto">
                                <img style="margin-left:5px;margin-top:5px;max-height:110px" class="img-responsive"  src="~@/assets/img/sport/PATTINAGGIO.png">
                            </div>
                        </div>
                    </div>
                </div>        
            </div>

            <!-- 
            <div class="col-md-3" style="margin-top:40px">
                <div v-on:click="clickSport(8)" style="width:140px;height:140px;border-radius:24px;background:#4e73df;margin:0 auto" class="showbox scale">
                    <img style="margin-left:5px;margin-top:5px;max-height:110px" class="img-responsive"  src="~@/assets/img/sport/PATTINAGGIO.png">
                </div>
                <h2 style="font-size:22px;font-weight:bold;;margin-top:6px;width:100%;text-align:center;">Pattinaggio</h2>
            </div>
            -->

            <div class="col-3" style="height: 140px;margin-top:80px"   >
                <div class="card shadow lift-panel " v-on:click="clickSport(6)" style="margin-top:20px;background: #f0ad4e !important;">
                    <div class="card-body" >
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="font-weight-bold mb-1 h3" style="color:#fff !important">Danza</div>  
                                <div class="h6 mb-0  " style="color:#fff" ></div> 
                            </div>
                            <div class="col-auto">
                                <img style="margin-left:5px;margin-top:5px;max-height:110px" class="img-responsive"  src="~@/assets/img/sport/DANZA.png">
                            </div>
                        </div>
                    </div>
                </div>        
            </div>

            <!-- 
            
            <div class="col-md-3" style="margin-top:40px">
                <div v-on:click="clickSport(6)" style="width:140px;height:140px;border-radius:24px;background:#f0ad4e;margin:0 auto" class="showbox scale">
                    <img style="margin-left:5px;margin-top:5px;max-height:110px" class="img-responsive"  src="~@/assets/img/sport/DANZA.png">
                </div>
                <h2 style="font-size:22px;font-weight:bold;;margin-top:6px;width:100%;text-align:center;">Danza</h2>
            </div>

-->

        </div>
        
        
        

    </div>
</template>

<script>
    export default {
        name:"Sport",
        components: {
        
        },
        data:function(){
        return{
            filters:global.filters
        }
        },
        methods:{
            openModulo : function(modulo){
                global.router.push(modulo);
            },
            clickSport : function(ID_SPORT){
                this.$root.SPORT_SELEZIONATO = ID_SPORT;
                if (this.$root.utente.FK_ID_LIVELLO == 1){
                    global.router.push("Eventi");
                }
                else{
                    global.router.push("Campionati");
                }                
            }
        },
        computed:{
            
        },
        created: function () {
           
        },
    }
    </script>