<template>
    <div class="row">
        <div class="col-md-4 offset-md-4">
            <div class="card o-hidden border-0 shadow-lg my-4">
                <div class="card-body p-0">
                <!-- Nested Row within Card Body -->
                    <div class="row">
                        <div class="col-12" style="padding:0">
                            <div class="container">
                                <div style="padding:2rem; padding-bottom: 1.4rem !important;">
                                    <div class="text-center" style="width:100%">
                                        <img src="~@/assets/img/libertas.png"  v-if="$root.APP == 'CONFSPORT' && $root.TIPO_PORTALE == 1" style="margin: 0 auto; max-width: 400px;" class="img-responsive d-none d-sm-block">
                                        <img src="~@/assets/img/confsport.png" v-if="$root.APP == 'CONFSPORT' && $root.TIPO_PORTALE == 2" style="margin: 0 auto; max-width: 400px;" class="img-responsive d-none d-sm-block">
                                        <img src="~@/assets/img/nuotosprint.png" v-if="$root.APP == 'NUOTOSPRINT'" style="margin: 0 auto; max-width: 400px;" class="img-responsive d-none d-sm-block">                                       
                                       <br/>
                                    </div>
                                    <div class="user">
                                        <div class="form-group">
                                            <label>Username</label>
                                            <input type="email" class="form-control form-control-user"  v-model="utente.USERNAME"  >
                                        </div>
                                        <div class="form-group">
                                            <label>Password</label>
                                            <input type="password" class="form-control form-control-user"  v-on:keyup.enter="login()"  v-model="utente.PASSWORD" >
                                        </div>
                                        <div class="form-group">
                                            <div>
                                                <span class="small slink" href="" v-on:click="passwordDimenticata()">Password dimenticata?</span>
                                            </div>
                                        </div>
                                        <button v-on:click="login()" class="btn btn-success btn-lg btn-user btn-block"><i class="fal fa-sign-in-alt fa-fw"></i> Login</button>
                                        
                                        <hr>
                                        <div class="mt-4 w-100 text-center">
                                            <a href="mailto:info@isprint.it"><small>info@isprint.it</small></a><br/>
                                            <small>versione {{$root.VERSIONE}}</small>
                                        </div>
                                    </div>
                                </div>          
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
        <div class="modal fade" id="popUpEmail"  role="dialog"  aria-modal="true" >
            <div class="modal-dialog" style="max-width:574px"  >
                <div class="modal-content" >
                    <div class="modal-header modal-header-info">
                        <h5 class="modal-title">Password dimenticata</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="col-md-12">
                            <label>Indirizzo email</label>
                            <input type="text"  class="form-control" v-model="emailPasswordDimenticata">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-success" v-on:click="doModificaPassword()"><i class="fa fa-check fa-fw"></i> Invia</button>
                    </div>
                </div>
            </div>
        </div>

        
    </div>

</template>

<script>


export default {
    name: 'Login',
    components:{
        
    },
    data:function(){
        return {
            emailPasswordDimenticata:'',

            utente:{
                //email:'utente@utente.it',
                //password:')7.HRK=L3M!xiyRq,vZ'
            }
        }
    },
    methods : {
        login(){
            global.utils.ajax('core/login',{USERNAME:this.utente.USERNAME,PASSWORD:this.utente.PASSWORD}, (response) => {
                if (response.esito == 0){
                    global.utils.setCookie("session_token",response.data.SESSION_TOKEN);
                    global.utils.TOKEN = response.token;
                    this.$root.startHandler();
                }
                else{
                    global.utils.alert.warning(response.responseText);
                }
            });
        },
        registrazione(){
            var modal = $('#modalRegistrazione');
            modal.modal('show');
        },
        passwordDimenticata : function(){
            var modal = $('#popUpEmail');
            modal.modal('show');
        },
        doModificaPassword : function(){
            utils.ajax('as/utente/password/reset',{EMAIL:this.emailPasswordDimenticata},function(data){
            if (data.esito == 0){
                utils.alert.success(response.messaggio);
            }
            });
        }
    },
    created : function(){
        this.$root.arrayMenu = new Array();
        global.utils.setHeader('fa-sign-in','Login');
    }
}
</script>