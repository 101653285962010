<template>
    <input autocomplete="off" v-on:keyup="digitHandler()" type="text" :placeholder="placeholder" class="form-control" v-bind:class="{'form-control-sm':dim == 'sm'}" />
</template>
<script>

import { computed } from 'vue'


export default {
    name: 'DatePicker',
    emits: ['update:modelValue','dataselezionata'],
    props:['dateAbilitate','dim','modDate','placeholder','modelValue'],
    data:function(){
        return{
            datepicker:null,
            selectedDate: {
                giorno : 0,
                mese : 0,
                anno : 0,
                giornoString : "",
                meseString : "",
                annoString : "",
                englishFormatted : ""            
            }
        }
    },
    methods: {
        digitHandler : function(){
            var date = moment($(this.$el).val(),"DD/MM/YYYY",true);
            if (date.isValid()){
                this.$emit('update:modelValue', global.$(this.$el).val());
                this.scomponiData();
            }
            else if ($(this.$el).val() == ""){
                this.$emit('update:modelValue', global.$(this.$el).val());
            }
        },
        scomponiData : function(){
            var value =  global.$(this.$el).val();
            if (value != undefined && value.toString().length == 10){
                this.selectedDate.annoString = value.substring(6,10);
                this.selectedDate.meseString = value.substring(3,5);
                this.selectedDate.giornoString = value.substring(0,2);
                this.selectedDate.anno = parseInt(this.selectedDate.annoString);
                this.selectedDate.mese = parseInt(this.selectedDate.meseString);
                this.selectedDate.giorno = parseInt(this.selectedDate.giornoString);
                this.selectedDate.englishFormatted = this.selectedDate.annoString + "-" + this.selectedDate.meseString + "-" + this.selectedDate.giornoString;  
                this.$emit("dataselezionata",this.selectedDate);              
            }
        },
        editOptions : function(options){
            global.$(this.$el).datepicker("option", options);
        },
        setBeforeShowDay : function(giorniattivi){
            var f = (datee) =>  {
                /*
                var giornocorrente = this.formatDateReverse(datee);
                if ($.inArray(giornocorrente, giorniattivi) != -1 ) {
                    return this.modDate == 'admin' ? [true, '', 'Available'] :  true;//[true, '', 'Available'];
                } else {
                    return this.modDate == 'admin' ? [false, '', 'unAvailable'] : false;//[false, '', 'unAvailable'];
                }*/
                if (this.dateAbilitate == undefined){
                    return true;
                }
                var dmy = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
                if(this.dateAbilitate.indexOf(dmy) != -1){
                    return true;
                }
                else{
                    return false;
                }
            }
            this.editOptions({beforeShowDay:f});
        },
        formatDate : function (s) {
            var date = new Date(s);
            return this.formatDateFromDate(date);
        },
        formatDateFromDate : function(date){
            if (date == undefined){
                return '';
            }
            var day = date.getDate();
            day = day > 9 ? day : '0' + day;
            var month = date.getMonth() + 1 ;
            month = month > 9 ? month : '0' + month;
            var year = date.getFullYear();
            return day + '/' + month + '/' + year;
        },
        formatDateReverse : function (s) {
            var date = new Date(s);
            //console.log(date);
            var day = date.getDate();
            day = day > 9 ? day : '0' + day;
            var month = date.getMonth() + 1 ;
            month = month > 9 ? month : '0' + month;
            var year = date.getFullYear();
            return year  + '-' + month + '-' + day ;
        },
        formatDateReverseFromString : function (s) {
            if (s == undefined)
                return new Date();
            //28/04/2019
            return s.substr(6,4)  + '-' + s.substr(3,2) + '-' + s.substr(0,2) ;
        },
    },
    computed: {
    
    },
    unmounted: function () {
        global.$(this.$el).off().datepicker('destroy')
    },
    
    updated : function(){
        /*
        console.log("updated");
        console.log(this.$attrs.modelValue);
        
        var v = this.formatDateReverseFromString(this.$attrs.modelValue);
        this.datepicker.datepicker('update', new Date(v));*/
       // global.$(this.$el).val(this.$attrs.modelValue);
    },
    watch:{
         modelValue: {
            handler(val) {
             //   console.log(val);
                var v = this.formatDateReverseFromString(val);
                var d = new Date(v);
                this.datepicker.datepicker('setDate', d);
            },
            deep: true
        },
    },
    mounted: function () {
        //console.log('mounted');
        var vm = this;
        this.datepicker = global.$(this.$el).datepicker({
            todayHighlight: true,
            language: 'it',
            autoclose: true,
            dateFormat: 'dd/mm/yyyy',
            onSelect: () => {
               // console.log("onselect");
             //   console.log(this.$el);
                vm.$emit('update:modelValue', global.$(this.$el).val()); //this.dataCorrente
                this.scomponiData();
            },
            beforeShowDay: (date) =>{
                if (this.dateAbilitate == undefined){
                    return true;
                }
                var dmy = this.formatDateReverse(date);
                if(this.dateAbilitate.indexOf(dmy) != -1){
                    return true;
                }
                else{
                    return false;
                }
            }
        }).on('changeDate',(val) => {
         //   console.log(val);
          //  console.log("changedate");
            var v = this.formatDateFromDate(val.date);   
            vm.$emit('update:modelValue', v); //this.dataCorrente
           // console.log(v); 
            global.$(this.$el).val(v);
            this.scomponiData();
        });
    },
    created : function(){
        if (this.$attrs.modelValue != undefined){
            var v = this.formatDateReverseFromString(this.$attrs.modelValue);
            setTimeout(() => {
                this.datepicker.datepicker('update', new Date(v));
            },300)
        }
    }
}
</script>