<template>
    <div>
        <ul class="nav nav-tabs" id="tabs" role="tablist" style="margin-top:25px">
            <li class="nav-item" >
                <a class="nav-link isprint-tab evento"   role="tab"  aria-selected="false" style="background: rgb(22 36 96 / 18%); font-weight: bold;  color: rgb(23, 37, 95); border: 1px solid#172460 !important;">
                    <kbd style="background-color: #17255f;">{{ evento.ID_EVENTO }}</kbd> {{ evento.DESCRIZIONE }}
                    <button class="btn btn-grid btn-dark ml-4" v-on:click="back()" ><i class="fa fa-arrow-left"></i> Indietro</button>
                </a>                
            </li> 
            <li class="nav-item">
                <a class="nav-link isprint-tab active evento"  data-toggle="tab" data-tab="garaGenerale" href="#garaGenerale"  role="tab" aria-controls="garaGenerale" aria-selected="true">
                    <i class="fal fa-trophy"></i> Generale
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link isprint-tab evento" data-toggle="tab" data-tab="garaInd" href="#garaInd"  role="tab" aria-controls="garaInd" aria-selected="false">
                    <i class="fal fa-user"></i> Gare individuali
                </a>
            </li>

            <li class="nav-item">
                <a class="nav-link isprint-tab evento" data-toggle="tab" data-tab="garaStaff" href="#garaStaff"  role="tab" aria-controls="garaStaff" aria-selected="false">
                    <i class="fal fa-users"></i> <span v-show="evento.FK_ID_SPORT == 1">Staffette</span><span v-show="evento.FK_ID_SPORT == 2 || evento.FK_ID_SPORT == 3">Squadre</span>
                </a>
            </li>

            <li class="nav-item" v-show="false">
                <a class="nav-link isprint-tab evento" data-toggle="tab" data-tab="garaBonus" href="#garaBonus"  role="tab" aria-controls="garaBonus" aria-selected="false">
                    <i class="fal fa-gift"></i> Bonus
                </a>
            </li> 
            
            <div class="dropdown float-right"  style="margin-top:0px">
                <button class="btn  btn-lg dropdown-toggle btn-primary" style="background: #162460;" type="button"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fal fa-link"></i> Link Rapidi
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                    <button class="dropdown-item" v-on:click="openRoute('Evento')" type="button">Gestione</button>
                    <button class="dropdown-item" v-on:click="ricalcolaRisultati()" type="button">Ricalcola risultati</button>
                    <div class="dropdown-divider"></div>
                    <div class="dropdown-item">
                        <strong>Risultati</strong>
                    </div>
                    <button class="dropdown-item" v-on:click="stampaRisultati(1)" type="button">Stampa in PDF</button>
                    <button class="dropdown-item" v-on:click="stampaRisultati(2)" type="button">Stampa in EXCEL</button>

                </div>
            </div>  



            
        </ul>
        <div class="tab-content card-tab" style="margin-top:-20px" >
            <div class="tab-pane active" id="garaGenerale" style="min-height:600px;height:100%" role="tabpanel">
                <div class="card shadow" style="margin-top:20px;min-height:600px;height:100%">
                    <div class="row p-4">
                        <div class="col-4">                            
                            <button v-on:click="ricalcolaRisultati()" class="btn btn-info btn-lg btn-block"><i class="fal fa-sync"></i> Ricalcola risultati</button>
                            <button class="btn btn-danger btn-lg btn-block" v-on:click="stampaRisultati(1)"><i class="fal fa-file-pdf"></i> Risultati PDF</button>
                            <button class="btn btn-success btn-lg btn-block" v-on:click="stampaRisultati(2)"><i class="fal fa-file-excel"></i> Risultati Excel</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane" id="garaInd" style="min-height:600px;height:100%" role="tabpanel">
                <div class="card shadow" style="margin-top:20px;min-height:600px;height:100%;padding:10px">         
                    <div class="kt-portlet__head">
                        <form class="isprint-filter" autocomplete="off">                
                            <div class="form-group ">
                                <label>ID</label><br/>
                                <input class="form-control form-control-sm"  autocomplete="off"  placeholder="ID" type="text" style="width:80px" v-model="searchIndividuale.ID_PRESENZA.VALUE">
                            </div>
                            <div class="form-group ">
                                <label>Cognome</label><br/>
                                <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Cognome" type="text" style="width:180px" v-model="searchIndividuale.COGNOME.VALUE">
                            </div>
                            <div class="form-group " >
                                <label>Nome</label><br/>
                                <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Nome" type="text" style="width:180px" v-model="searchIndividuale.NOME.VALUE">
                            </div> 
                            <div class="form-group " >
                                <label>Gara</label><br/>
                                <select class="form-control form-control-sm"  style="width:100px" v-model="searchIndividuale.FK_ID_GARA.VALUE">
                                    <option value="">Tutte</option>
                                    <option v-for="gara in evento.gareIndDistinct" v-bind:key="gara.ID_GARA" v-bind:value="gara.ID_GARA">{{ gara.DESCRIZIONE }}</option>
                                </select>
                            </div> 
                            <div class="form-group " >
                                <label>Categoria</label><br/>
                                <select class="form-control form-control-sm"  style="width:100px" v-model="searchIndividuale.FK_ID_CATEGORIA.VALUE">
                                    <option value="">Tutte</option>
                                    <option v-for="categoria in evento.categorie" v-bind:key="categoria.ID_CATEGORIA" v-show="categoria.FK_ID_TIPO == 1" v-bind:value="categoria.ID_CATEGORIA">{{ categoria.DESCRIZIONE }}</option>
                                </select>
                            </div> 
                            <div class="form-group " >
                                <label>Sesso</label><br/>
                                <select class="form-control form-control-sm"  style="width:100px" v-model="searchIndividuale.SESSO.VALUE">
                                    <option value="">Tutti</option>
                                    <option value="1">Maschi</option>
                                    <option value="2">Femmine</option>
                                </select>
                            </div> 
                        </form>
                    </div>
                    <div class="isprint-grid-container" >    
                        <table class="isprint-grid shadow" >
                            <thead class="isprint-grid-thead" >
                                <tr style="left: 0px;background:#f4b30d;color:#fff">
                                    <th style="width:8%"><span>ID</span></th>
                                    <th style="width:22%"><span >Anno nascita</span></th>
                                    <th style="width:14%"><span >Gara/Cat</span></th>                                    
                                    <th style="width:16%"><span v-show="evento.FK_ID_SPORT == 1">Tempo</span><span v-show="evento.FK_ID_SPORT == 2 || evento.FK_ID_SPORT == 3">Punteggio</span></th>
                                    <th style="width:10%"><span >Stato</span></th>
                                    <th style="width:9%"><span >Note</span></th>
                                    <th style="width:6%"><span >Pen pt</span></th>
                                    <th style="width:6%"><span >Pen sec</span></th>
                                    <th style="width:9%"><span >Azioni</span></th>
                                </tr>
                            </thead>
                            <tbody class="isprint-grid-tbody" style="font-size:14px !important">
                                <tr  style="left: 0px;height:50px;cursor:pointer"  v-on:click="setCurrentPresenza(presenza)" v-for="presenza in paginatedListInd.arr"  v-bind:key="presenza.ID_PRESENZA" v-bind:class="getClassPresenza(presenza)">
                                    <td  style="width:8%" >
                                        <kbd>{{ presenza.ID_PRESENZA }}</kbd>
                                    </td>                                    
                                    <td  style="width:22%" >
                                        <span>
                                        <i style="font-size:24px" v-bind:class="{'color-maschio':presenza.SESSO == 1,'color-femmina':presenza.SESSO == 2}" class="fa-fw fal fa-user"></i>                                        
                                        <strong>{{ presenza.COGNOME }} {{ presenza.NOME }}</strong></span>
                                    </td>
                                    <td  style="width:14%">
                                        <strong>{{ presenza.DESC_GARA }}</strong><br/>{{presenza.ANNO_NASCITA}} - {{ presenza.DESC_CATEGORIA }} 
                                    </td>
                                    <td  style="width:16%" v-if="evento.FK_ID_SPORT == 1">
                                        <Tempo @onenter="presenzaHasChanged(presenza);salvaPresenza(presenza)" @change="presenzaHasChanged(presenza)" v-model="presenza.TEMPO" :dim="'sm'"></Tempo>
                                    </td>
                                    <td  style="width:16%" v-if="evento.FK_ID_SPORT == 2 || evento.FK_ID_SPORT == 3">
                                        <Punteggio  style="width:150px" v-model="presenza.TEMPO"  @onenter="presenzaHasChanged(presenza);salvaPresenza(presenza)" @change="presenzaHasChanged(presenza)"    ></Punteggio>
                                    </td>
                                    <td  style="width:10%">
                                        <select class="form-control form-control-sm" @change="presenzaHasChanged(presenza)" v-model="presenza.FK_ID_STATO">
                                            <option v-bind:value="0">No time</option>
                                            <option v-bind:value="1">Regolare</option>
                                            <option v-bind:value="3">Assente</option>
                                            <option v-bind:value="2">Squalificato</option>
                                        </select>
                                    </td>   
                                    <td  style="width:9%">
                                        <input class="form-control form-control-sm"  v-on:keyup.enter="salvaPresenza(presenza)"  @change="presenzaHasChanged(presenza)" style=";float:left" type="text" placeholder="Note" v-model="presenza.NOTE">
                                    </td>
                                    <td  style="width:6%">
                                        <input class="form-control form-control-sm"  v-on:keyup.enter="salvaPresenza(presenza)"  @change="presenzaHasChanged(presenza)" style="width:50px;float:left" type="text" placeholder="PENALITÀ PT" v-model="presenza.PENALITA_PT">
                                    </td>
                                    <td  style="width:6%">
                                        <input class="form-control form-control-sm"  v-on:keyup.enter="salvaPresenza(presenza)" style="width:50px;float:left" type="text" placeholder="PENALITÀ SEC" v-model="presenza.PENALITA_SEC">
                                    </td>
                                    <td  style="width:9%">                                                                            
                                        <button class="btn btn-success btn-sm" v-on:click="salvaPresenza(presenza)"><i class="fal fa-check"></i></button>
                                        <button class="btn btn-danger float-right btn-sm" ><i class="fal fa-trash-alt"></i></button>
                                    </td>
                                </tr>                            
                            </tbody>
                        </table>
                        <Pagine v-model="searchIndividuale.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedListInd.count"  :numpagine="searchIndividuale.PAGINATION.LENGTH"></Pagine>
                    </div>  
                </div>
            </div>
            <div class="tab-pane" id="garaStaff" style="min-height:600px;height:100%" role="tabpanel">
                <div class="card shadow" style="margin-top:20px;min-height:600px;height:100%;padding:10px">
                    
                    <div class="kt-portlet__head">
                        <form class="isprint-filter" autocomplete="off">                
                            <div class="form-group ">
                                <label>ID</label><br/>
                                <input class="form-control form-control-sm"  autocomplete="off"  placeholder="ID" type="text" style="width:80px" v-model="searchSquadre.ID_PRESENZA.VALUE">
                            </div>
                            <div class="form-group ">
                                <label>Società</label><br/>
                                <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Cognome" type="text" style="width:180px" v-model="searchSquadre.DENOMINAZIONE.VALUE">
                            </div>
                            <div class="form-group " >
                                <label>Gara</label><br/>
                                <select class="form-control form-control-sm"  style="width:100px" v-model="searchSquadre.FK_ID_GARA.VALUE">
                                    <option value="">Tutte</option>
                                    <option v-for="gara in evento.gareSquDistinct" v-bind:key="gara.ID_GARA" v-bind:value="gara.ID_GARA">{{ gara.DESCRIZIONE }}</option>
                                </select>
                            </div> 
                            <div class="form-group " >
                                <label>Categoria</label><br/>
                                <select class="form-control form-control-sm"  style="width:100px" v-model="searchSquadre.FK_ID_CATEGORIA.VALUE">
                                    <option value="">Tutte</option>
                                    <option v-for="categoria in evento.categorie" v-bind:key="categoria.ID_CATEGORIA" v-show="categoria.FK_ID_TIPO == 2" v-bind:value="categoria.ID_CATEGORIA">{{ categoria.DESCRIZIONE }}</option>
                                </select>
                            </div> 
                            <div class="form-group " >
                                <label>Sesso</label><br/>
                                <select class="form-control form-control-sm"  style="width:100px" v-model="searchSquadre.SESSO.VALUE">
                                    <option value="">Tutti</option>
                                    <option value="1">Maschi</option>
                                    <option value="2">Femmine</option>
                                    <option value="3">Mixed</option>
                                </select>
                            </div> 
                        </form>
                    </div>
                    <div class="isprint-grid-container" >    
                        <table class="isprint-grid shadow" >
                            <thead class="isprint-grid-thead" >
                                <tr style="left: 0px;background:#f4b30d;color:#fff">
                                    <th style="width:8%"><span>ID</span></th>
                                    <th style="width:22%"><span >Anno nascita</span></th>
                                    <th style="width:14%"><span >Gara/Cat</span></th>                                    
                                    <th style="width:16%"><span v-show="evento.FK_ID_SPORT == 1">Tempo</span><span v-show="evento.FK_ID_SPORT == 2 || evento.FK_ID_SPORT == 3">Punteggio</span></th>
                                    <th style="width:10%"><span >Stato</span></th>
                                    <th style="width:9%"><span >Note</span></th>
                                    <th style="width:6%"><span >Pen pt</span></th>
                                    <th style="width:6%"><span >Pen sec</span></th>
                                    <th style="width:9%"><span >Azioni</span></th>
                                </tr>
                            </thead>
                            <tbody class="isprint-grid-tbody" style="font-size:14px !important">
                                <tr  style="left: 0px;height:50px;cursor:pointer"  v-on:click="setCurrentPresenza(presenza)" v-for="presenza in paginatedListSqu.arr"  v-bind:key="presenza.ID_PRESENZA" v-bind:class="getClassPresenza(presenza)">
                                    <td  style="width:8%" >
                                        <kbd>{{ presenza.ID_PRESENZA }}</kbd>
                                    </td>                                    
                                    <td  style="width:22%" >
                                        <span>
                                        <strong>{{ presenza.DENOMINAZIONE }}  - {{ filters.getProgressivo(presenza.PROGRESSIVO) }}</strong></span>
                                    </td>
                                    <td  style="width:14%">
                                        <strong>{{ presenza.DESC_GARA }}</strong><br/>{{ presenza.DESC_CATEGORIA }} 
                                    </td>
                                    <td  style="width:16%" v-if="evento.FK_ID_SPORT == 1">
                                        <Tempo @onenter="presenzaHasChanged(presenza);salvaPresenza(presenza)" @change="presenzaHasChanged(presenza)" v-model="presenza.TEMPO" :dim="'sm'"></Tempo>
                                    </td>
                                    <td  style="width:16%" v-if="evento.FK_ID_SPORT == 2 || evento.FK_ID_SPORT == 3">
                                        <Punteggio  style="width:150px" v-model="presenza.TEMPO"  @onenter="presenzaHasChanged(presenza);salvaPresenza(presenza)" @change="presenzaHasChanged(presenza)"    ></Punteggio>
                                    </td>
                                    <td  style="width:10%">
                                        <select class="form-control form-control-sm" @change="presenzaHasChanged(presenza)" v-model="presenza.FK_ID_STATO">
                                            <option v-bind:value="0">No time</option>
                                            <option v-bind:value="1">Regolare</option>
                                            <option v-bind:value="3">Assente</option>
                                            <option v-bind:value="2">Squalificato</option>
                                        </select>
                                    </td>   
                                    <td  style="width:9%">
                                        <input class="form-control form-control-sm"  v-on:keyup.enter="salvaPresenza(presenza)"  @change="presenzaHasChanged(presenza)" style=";float:left" type="text" placeholder="Note" v-model="presenza.NOTE">
                                    </td>
                                    <td  style="width:6%">
                                        <input class="form-control form-control-sm"  v-on:keyup.enter="salvaPresenza(presenza)"  @change="presenzaHasChanged(presenza)" style="width:50px;float:left" type="text" placeholder="PENALITÀ PT" v-model="presenza.PENALITA_PT">
                                    </td>
                                    <td  style="width:6%">
                                        <input class="form-control form-control-sm"  v-on:keyup.enter="salvaPresenza(presenza)" style="width:50px;float:left" type="text" placeholder="PENALITÀ SEC" v-model="presenza.PENALITA_SEC">
                                    </td>
                                    <td  style="width:10%">                                                                            
                                        <button class="btn btn-success btn-sm" v-on:click="salvaPresenza(presenza)"><i class="fal fa-check"></i></button>
                                        <button class="btn btn-danger float-right btn-sm" ><i class="fal fa-trash-alt"></i></button>
                                    </td>
                                </tr>                            
                            </tbody>
                        </table>
                        <Pagine v-model="searchSquadre.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedListSqu.count"  :numpagine="searchSquadre.PAGINATION.LENGTH"></Pagine>
                    </div>  



                </div>
            </div>
            <div class="tab-pane" id="garaBonus" style="min-height:600px;height:100%" role="tabpanel">
                <div class="card shadow" style="margin-top:20px;min-height:600px;height:100%">
                    bonus
                </div>
            </div>
           
        </div>
    </div>
</template>
<script>


import Pagine from '@/components/utils/Pagine.vue'
import Tempo from '@/components/ns/Tempo.vue'
import Punteggio from '@/components/ns/Punteggio.vue'


export default {
    name:"Gara",
    components:{
        Pagine, 
        Tempo,
        Punteggio
    },
    data:function(){
        return{
            filters:global.filters,
            evento:{},
            searchIndividuale:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:50},
                ID_PRESENZA:{TYPE:'TEXT',VALUE:''}, 
                NOME:{TYPE:'TEXT',VALUE:''}, 
                COGNOME:{TYPE:'TEXT',VALUE:''}, 
                SESSO:{TYPE:'COMBO',VALUE:''}, 
                FK_ID_GARA:{TYPE:'COMBO',VALUE:''}, 
                FK_ID_CATEGORIA:{TYPE:'COMBO',VALUE:''}, 
            },
            searchSquadre:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:50},
                ID_PRESENZA:{TYPE:'TEXT',VALUE:''}, 
                DENOMINAZIONE:{TYPE:'TEXT',VALUE:''}, 
                SESSO:{TYPE:'COMBO',VALUE:''}, 
                FK_ID_GARA:{TYPE:'COMBO',VALUE:''}, 
                FK_ID_CATEGORIA:{TYPE:'COMBO',VALUE:''}, 
            },
            arrayPresenzeInd:new Array(),
            arrayPresenzeSqu:new Array()
        }
    },
    methods: {
        getEvento : function(){
            utils.get('/ge/evento/dettagli',{ID_EVENTO:this.evento.ID_EVENTO}, (response) => {
                this.evento = response.data;    
                this.arrayPresenzeInd = utils.array.select(this.evento.arrayPresenzeAll,"FK_ID_TIPO",1) ;
                this.arrayPresenzeSqu = utils.array.select(this.evento.arrayPresenzeAll,"FK_ID_TIPO",2) ;
            });                         
        },
        setCurrentPresenza : function(presenza){
            var _array = presenza.FK_ID_TIPO == 1 ? this.arrayPresenzeInd : this.arrayPresenzeSqu;
            for (var i = 0 ; i < _array.length ; i++){
                _array[i].CURRENT = 0;
            }
            presenza.CURRENT = 1;
        },
        salvaPresenza : function(presenza){
            utils.put('/ge/evento/presenza',presenza, (response) => {
                if (response.esito == 0){
                    presenza.TEMPO_DB = presenza.TEMPO;
                    presenza.FK_ID_STATO_DB = presenza.FK_ID_STATO;
                    presenza.FLG_MODIFICATO = false;
                    utils.alert.success(response.messaggio)
                }                
            });  
        },
        getClassPresenza : function(presenza){
            if (presenza.ID_PRESENZA == 56){
                console.log("ciaone");
                var pippo = "";
            }
            if (presenza.CURRENT == 1){
                return 'td-ns';
            }
            if (!presenza.FLG_MODIFICATO == true){
                if (presenza.TEMPO > 0 && presenza.TEMPO_DB == presenza.TEMPO){
                    return 'td-ns-success';
                }
                if (presenza.FK_ID_STATO != 0 && presenza.FK_ID_STATO != 1 && presenza.FK_ID_STATO == presenza.FK_ID_STATO_DB){
                    return 'td-ns-success';
                }
            }
            else{
                if (presenza.TEMPO > 0 && presenza.TEMPO_DB == presenza.TEMPO){
                    return 'td-ns-success';
                }
                else if (presenza.FK_ID_STATO != 0 && presenza.FK_ID_STATO != 1 && presenza.FK_ID_STATO == presenza.FK_ID_STATO_DB){
                    return 'td-ns-success';
                }
                else{
                    return 'td-ns-danger';
                }
            }  
        },
        presenzaHasChanged : function(presenza){
            if (presenza.TEMPO > 0 && presenza.FK_ID_STATO == 0){
                presenza.FK_ID_STATO = 1;
            }
            presenza.FLG_MODIFICATO = true;
        },
        stampaStartlist : function(formato){
            window.open(utils.BASE_API + "core/report?ID_REPORT=1001&FORMATO="+formato+"&ID_EVENTO="+this.evento.ID_EVENTO+"&SESSION_TOKEN="+utils.SESSION_TOKEN);
        },
        openRoute : function(route){
            this.$root.eventoSelezionato = utils.clone(this.evento);
            global.router.push(route);
        }, 
        ricalcolaRisultati : function(){
            utils.post('/ns/evento/calcolaClassifiche',{ID_EVENTO:this.evento.ID_EVENTO}, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio)
                }                
            });
        },
        stampaRisultati : function(formato){
            window.open(utils.BASE_API + "core/report?ID_REPORT=1003&FORMATO="+formato+"&ID_EVENTO="+this.evento.ID_EVENTO+"&SESSION_TOKEN="+utils.SESSION_TOKEN);
        },      
        back: function(){
            global.router.push('Eventi');
        }
    },
    computed : {
        paginatedListInd(){
            var arr = utils.inGrid(this.arrayPresenzeInd,this.searchIndividuale,"-ISCRITTO");
            return arr;
        },
        paginatedListSqu(){
            var arr = utils.inGrid(this.arrayPresenzeSqu,this.searchSquadre);
            return arr;
        },
    },
    beforeCreate : function(){
       global.utils.deltaGriglia = 80;
    },
    unmounted : function(){
       global.utils.deltaGriglia = 0;
    },
    beforeCreate : function(){
        setTimeout(() => {
            global.utils.deltaGriglia = 100;   
            global.utils.resizeContent();     
        }, 300);
    },
    unmounted : function(){
       global.utils.deltaGriglia = 0;
    },
    created : function(){
        setTimeout(() => {
            $('a[data-toggle="tab"]').on('shown.bs.tab', (e) => {
                var tab = $(e.target).data("tab");
                switch(tab){
                    case 'generale':
                        this.getEvento();
                        break;
                    case 'iscrizioni':
                        this.getRiepilogo();
                        break;
                    case 'gareCat':
                        this.getEvento();
                        break;
                    case 'ordine':
                        this.getEvento();
                        break;
                    case 'tempiLimite':
                        break;
                    case 'admin':
                        break;
                  }
            });            
        },300);
        this.evento = utils.clone(this.$root.eventoSelezionato);
        if (this.evento.ID_EVENTO > 0){
            this.getEvento();
        }  
        this.getEvento();    
    },
}
</script>