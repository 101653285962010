const filters = {
    root:null,
    upperCase: (val) => {
        return val.toString().toUpperCase();
    },
    formattaImporto:(value) =>{
        if (value) {
            return global.utils.formatNumber(value);
        }
        else{
            return "0,00";
        }
    },
    formattaImportoInt:(value)=>{
        if (value) {
            return global.utils.formatNumberInt(value);
        }
        else{
            return "0";
        }
    },
    formattaTempoNs:(value)=>{
        var MINUTI = parseInt(value/6000);
        var SECONDI = Math.floor((value - (MINUTI *  6000))/100);
        return (MINUTI < 10 ? '0' + MINUTI.toString() : (MINUTI > 60 ? (Math.floor(MINUTI/60) + "h " + (MINUTI % 60) ) : MINUTI.toString())) + "'" + (SECONDI < 10 ? '0' + SECONDI : SECONDI.toString())+"''";
    },
    accorciaTesto : (value,length = 300, mostraPuntini = 1) =>{
        if (value) {
            if (value.toString().length > length){
                return value.toString().substring(0,length) + (mostraPuntini == 1 ? "..." : "");
            }
            else{
                return value.toString();
            }
        }
    },
    formatDate : (value) =>{
        if (value) {
            if (value.toString().search("/") > -1){
                return value;
            }
            return global.moment(String(value)).format('DD/MM/YYYY');
        }
    },
    formatDateNoYear : (value) =>{
        if (value) {
            if (value.toString().search("/") > -1){
                return value;
            }
            return global.moment(String(value)).format('DD/MM');
        }
    },
    formatDateReverse : (value) =>{
        if (value) {
            return value.toString().substr(6,4) + "-" + value.toString().substr(3,2) + "-" + value.toString().substr(0,2);
        }
    },
    formatDateSmart : (value) =>{
        if (value) {
            return global.moment(String(value)).format('DD/MM/YY');
        }
    },
    formatDateTime : (value) =>{
        if (value) {
            return global.moment(String(value)).format('DD/MM/YYYY - HH:mm');
        }
    },
    formatDateTimeSeconds : (value) =>{
        if (value) {
            return global.moment(String(value)).format('DD/MM/YYYY - HH:mm:ss');
        }
    },
    formatTime: (value) => {
        if (value) {
            return moment(String('0000-01-01 '+ value)).format('HH:mm')
        }
    },
    formatTimefromDate: (value) => {
        if (value) {
            return moment(value).format('HH:mm:ss')
        }
    },
    formatDateYear: (value) => {
        if (value) {
            return moment(String(value)).format('YYYY')
        }
    },
    formatMonth: (value) => {
        if (value) {
            var mese = global.utils.array.select(global.utils.arrayMesi,"ID_MESE",value,1)[0];
            return mese.DESCRIZIONE;
        }
    },
    getCurrentDate: (value) => {
        var mese = new Date().getMonth()+1;
        if (mese < 10){
            mese = '0'+mese;
        }
        return new Date().getDate()+"/"+ mese +'/'+new Date().getFullYear()
    },
    getProgressivo:(progressivo) =>{
        const arr = ["A","B","C","D","E","F","G","H","I","J"];
        return arr[progressivo-1];
    },
    nsGetLblRegime:(intensita) => {
        var item = global.utils.array.select(filters.root.arrayIntensita,"ID_INTENSITA",intensita,1)[0];
        if (item != undefined){
            return item.DESCRIZIONE;
        }
        return "";
    },
    nsFormatSerie : (serie) => {
        if (serie.serie.length == 0){
            var stile = global.utils.array.select(filters.root.arrayStili,"ID_STILE",serie.FK_ID_STILE,1)[0];
            if (stile == undefined){
                stile = {DESCRIZIONE : "-"};
            }
            var modalita = global.utils.array.select(filters.root.arrayModalita,"ID_MODALITA",serie.FK_ID_MODALITA,1)[0];
            if (modalita == undefined){
                modalita = {DESCRIZIONE : "-"};
            }
            var intensita = global.utils.array.select(filters.root.arrayIntensita,"ID_INTENSITA",serie.FK_ID_INTENSITA,1)[0];
            if (intensita == undefined){
                intensita = {DESCRIZIONE : "-"};
            }
            var descrizione = global.filters.accorciaTesto(serie.DESCRIZIONE,40,false);
            return serie.NUM_RIPETUTE + "x" + serie.DISTANZA_SINGOLA + " " + stile.DESCRIZIONE + " " + modalita.DESCRIZIONE + " | " + intensita.DESCRIZIONE + " @ " + filters.nsFormatTempoSerie(serie.DURATA_SINGOLA) + ( (descrizione != "" && descrizione != undefined) ? " | " + descrizione : "");
        }
        else{
            var s = "";
            if (serie.NUM_RIPETUTE > 1){
                s += "<div style='width:100%'>" +serie.NUM_RIPETUTE + " x [</div>";
            }            
            s += "<ul style='margin-bottom:0px !important'>";
            for (var i = 0 ; i < serie.serie.length ; i++){
                s += "<li>" + global.filters.nsFormatSerie(serie.serie[i]) +  "</li>";
            }
            s += "</ul>";
            if (serie.NUM_RIPETUTE > 1){
                s += "<div style='width:100%'>]</div>";
            }            
            return s;
        }
        return "";
    },
    nsFormatTempoSerie : (value)=>{
        var MINUTI = parseInt(value/6000);
        var SECONDI = Math.floor((value - (MINUTI *  6000))/100);
        if (MINUTI > 0){
            return (MINUTI < 10 ? '0' + MINUTI.toString() : (MINUTI > 60 ? (Math.floor(MINUTI/60) + "h " + (MINUTI % 60) ) : MINUTI.toString())) + "'" + (SECONDI < 10 ? '0' + SECONDI : SECONDI.toString())+"''";
        }
        else{
            return  (SECONDI < 10 ? '0' + SECONDI : SECONDI.toString())+"''";
        }
    },
    draggableChangeHandler : function(event,_array,_search,chiave){
        var newIndex = event.newIndex;
        var oldIndex = event.oldIndex;
        var arr = utils.inGrid(_array,_search).arr;
        var item = utils.clone(arr[oldIndex]);				
        var itemSostituito = utils.clone(arr[newIndex]);
        //console.log(item,itemSostituito);				
        //STEP 1: ELIMINO L'ELEMENTRO TRASCINATO
        for (var i = 0 ; i < _array.length; i++){
            if (_array[i][chiave] == item[chiave]){
                _array.splice(i,1);
                break;
            }
        }
        //STEP 2: RIAGGIUNGO L'ELEMENTO TRASCINATO ALLA NUOVA POSIZIONE
        for (var i = 0 ; i < _array.length; i++){
            if (_array[i][chiave] == itemSostituito[chiave]){
                var nuovoIndice = newIndex > oldIndex ? (i+1) : (i);
                _array.splice(nuovoIndice,0,item);
                break;
            }
        }
        for (var i = 0 ; i < _array.length; i++){
            _array[i].ORDINE = (i+1);
        }        
        return global.utils.array.ordinaArray(_array,"ORDINE");
    },

};

exports.filters = filters;



