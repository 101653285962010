<template>
  <div class="switch-button-control">
    <div  :class="[classArray()]" @click="toggle" :style="{'--color': color}">
      <div class="button"></div>
    </div>
    <div class="switch-button-label">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
    name: 'ISwitch',
    emits: ['update:modelValue',"toggle"],
    props: {
        modelValue: {
            type: Number
        },
        color: {
            type: String,
            required: false,
            default: "#28a745"
        },
        size: {
            type: String,
            required: false,
            default: "normal"
        },
    },
    methods: {
        toggle: function() {
            this.$emit("toggle", this.modelValue == 1 ? 0 : 1);
            this.$emit('update:modelValue', this.modelValue == 1 ? 0 : 1);
            this.$forceUpdate();
        },
        classArray : function(){
            var arr = new Array();
            if (this.modelValue == 1){
                arr.push("enabled");
            }
            if(this.size == 'normal'){
                arr.push('switch-button');
            }
            else{
                arr.push("switch-button-sm");
            }
        return arr;
        }
    },
    computed:{
    
    }
}
</script>